import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

/*Rx Js*/
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
/*Constant*/
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class CoreService {
  constructor(private http: HttpClient, private router: Router) { }

  /**
  * @method - get
  * @param url - url of API you want to call
  * @param param - optional value if required for GET request
  * @param responseType - optional value if user want response in other format
  * @desc - method for call HTTP GET request
  */
  get(url, param?, responseType?, fromLocal?): Observable<any> {
    let isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    let random = isIE ? `?${Math.random().toString(36).substring(2, 15)}` : '';
    if (fromLocal) {
      return this.http.get<any>(url + random, { params: this.setParam(param), observe: 'response' })
        .pipe(
          tap()
        );
    } else {
      return this.http.get<any>(environment.backend_server_url + url + random, { params: this.setParam(param), observe: 'response' })
        .pipe(
          tap()
        );
    }
  }

  /**
  * @method - post
  * @param url - url of API you want to call
  * @param param - required for POST request
  * @param responseType - optional value if user want response in other format
  * @desc - method for call HTTP POST request
  */
  post(url, param, responseType?): Observable<any> {
    return this.http.post<any>(environment.backend_server_url + url, param, { observe: 'response' }).pipe(
      tap()
    );
  }


  /**
  * @method - put
  * @param url - url of API you want to call
  * @param param - required for POST request
  * @param responseType - optional value if user want response in other format
  * @desc - method for call HTTP PUT request
  */
  put(url, param, responseType?): Observable<any> {
    return this.http.put<any>(environment.backend_server_url + url, param, { observe: 'response' }).pipe(
      tap()
    );
  }

  /**
  * @method - setParam
  * @param - param- data for set HTTP Param
  * @desc - method for set param dynemically
  */
  setParam(params) {
    let httpParams = new HttpParams();
    if (params) {
      for (let param in params) {
        httpParams = httpParams.append(param, params[param]);
      }
    }
    return httpParams;
  }

  /**
   * @method: setLocalStorage
   * @desc: Generic method to set data in local storage
   * @param key 
   * @param data 
   */
  setLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * @method: getLocalstorage
   * @desc: Generic method to get data in local storage
   * @param key 
   * @param data 
   */
  getLocalstorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
  * @method - getApi
  * @param url - url of API you want to call
  * @desc - method for call HTTP DELETE request
  */
  deleteApi(url: any) {
    return this.http.delete(environment.backend_server_url + url);
  }

  /**
  * @method - delete
  * @param url - url of API you want to call
  * @desc - method for call HTTP DELETE request
  */
  delete(url: any) {
    return this.http.delete(environment.backend_server_url + url);
  }

  deleteNotificationData(url, id?) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    }; 
    if(id){
      options['body'] = {ids :  id}
    }
    return this.http.delete<any>(environment.backend_server_url + url, options)
      .pipe(
        tap()
      );
  }
}