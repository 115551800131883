import { Component, OnInit, Input, EventEmitter, Output, ElementRef, HostListener, OnDestroy, ChangeDetectionStrategy, AfterViewInit, OnChanges } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { _ } from 'underscore';

@Component({
  selector: "app-multiselect",
  templateUrl: "./multiselect.component.html",
  styleUrls: ["./multiselect.component.css"]
})
export class MultiselectComponent implements OnInit {
  public dropdownOptions: any;
  public dropdownOptionsMain: any;
  public selectedIdsOfOptions: any = [];
  public selectedIds: any = [];
  public displayNoResultFound: boolean = true;
  public showOptions: boolean = false;
  private _data = new BehaviorSubject([]);
  private _dataId = new BehaviorSubject([]);
  public _selectedItem = new BehaviorSubject([]);

  public filter: any;
  public itemKey: string;

  @Input() multiSelectConf: conf;

  @Input()
  set data(value) {
    this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  @Input()
  set selectedItems(value: any) {
    this._selectedItem.next(value);
  }

  get selectedItem() {
    return this._selectedItem.getValue();
  }

  @Output() onValueChange = new EventEmitter();

  constructor(
    private elementRef: ElementRef
  ) { }

  /**
   * @param targetElement 
   * @desc: Hide options when click outside on the window
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const isInsideClicked = this.elementRef.nativeElement.contains(targetElement);
    if (!isInsideClicked) {
      this.showOptions = false;
    }
  }

  /**
   * @method: ngOnInit
   * @desc: Get the data from parent component and assign to local variables
   */
  ngOnInit() {
    const { itemKey } = this.multiSelectConf;
    this.itemKey = itemKey;
    this._data.subscribe(result => {
      this.dropdownOptions = this.data;
      this.dropdownOptionsMain = this.data;
      if (this.dropdownOptions && this.dropdownOptions.length == 0) this.displayNoResultFound = false;
      this.selectedIdsOfOptions = [];
    });

    this._selectedItem.subscribe(result => {
      this.selectedIds = result;
      setTimeout(() => {
        if (this.dropdownOptions && this.dropdownOptions.length > 0 && result && result.length > 0) this.getTheSelectedOptions();
        if (this.selectedIds && this.selectedIds.length === 0) this.selectedIdsOfOptions = this.selectedIds;
      }, 100);
    });
  }

  /**
   * @method: showDropdownOptions
   * @param : index
   * @desc: Toggle Dropdowm options
   */
  showDropdownOptions(index) {
    this.showOptions = !this.showOptions;
  }

  /**
   * @method: checkOrUncheck
   * @param ev : event
   * @param option : option object
   * @desc: To check and uncheck the options
   */
  checkOrUncheck(ev, option) {
    let val = ev.target.checked;
    if (val) {
      this.addOption(option);
      ev.target.checked = true;
    } else {
      this.removeOption(option);
      ev.target.checked = false;
    }
  }

  /**
   * @method: addOption
   * @param : option
   * @desc: To select the option
   */
  addOption(option) {
    this.selectedIdsOfOptions.push(option);
    this.onValueChanged();
  }

  /**
   * @method: removeOption
   * @param : option
   * @desc: To remove the option
   */
  removeOption(option) {
    for (let i = 0; i < this.selectedIdsOfOptions.length; i++) {
      if (
        this.selectedIdsOfOptions[i].hasOwnProperty("id") &&
        this.selectedIdsOfOptions[i]["id"] === option.id
      ) {
        this.selectedIdsOfOptions.splice(i, 1);
        this.onValueChanged();
        break; // so that it doesn't keep looping, after finding a match
      }
    }
  }

  /**
   * @method: checkValue
   * @param : value
   * @desc: To check if option is already selected.
   */
  checkValue(value, option) {
    var status = false;
    for (var i = 0; i < this.selectedIdsOfOptions.length; i++) {
      var name = this.selectedIdsOfOptions[i].id;
      if (name == value) {
        status = true;
        break;
      }
    }
    return status;
  }

  /**
   * @method: onValueChanged
   * @param :
   * @desc: emit an event when selected value changed
   */
  onValueChanged() {
    this.onValueChange.emit(this.selectedIdsOfOptions);
  }

  /**
   * @method: getTheSelectedOptions
   * @desc: Get the list of matched assets/ segments/ solutions from the dropdown options
   */
  getTheSelectedOptions() {
    this.selectedIdsOfOptions = [];
    if (this.dropdownOptions) {
      this.selectedIds.forEach((elm) => {
        var filteredGoal = _.where(this.dropdownOptions, { id: elm });
        if (filteredGoal && filteredGoal.length > 0) this.selectedIdsOfOptions.push(filteredGoal[0]);
      });
    }
    this.onValueChanged();
  }

  filterOptions() {
    this.dropdownOptions = _.filter(this.dropdownOptionsMain, (item) => {
      return item[this.itemKey].toLocaleLowerCase().indexOf(this.filter.toLocaleLowerCase()) > -1;
    });
    if (this.filter == '') this.dropdownOptions = this.dropdownOptionsMain;
  }
}

interface conf {
  itemKey: string
}
