//Route Constants
export const ROUTES_PATH = {
    LOGIN: "login/",
    DASHBOARD: "cms/dashboard/admin",
    SA_DASHBOARD: 'cms/dashboard/superadmin',
    AFFILIATION: 'cms/affiliation',
    AFFILIATION_EDIT: 'cms/affiliation/mutation/edit/',
    NEWS_ADD: 'cms/content/news/add',
    NEWS_EDIT: 'cms/content/news/edit/',
    EVENTS_ADD: 'cms/content/events/add',
    EVENTS_EDIT: 'cms/content/events/edit/',
    VOLUNTEERING_ADD: 'cms/content/volunteering/add',
    VOLUNTEERING_EDIT: 'cms/content/volunteering/edit/',
    EDIT_USER: 'cms/user/mutation/edit/',
    USER: 'cms/user',
    EDIT_ORG: 'cms/org/mutation/edit/',
    VIEW_ORG: 'cms/your-app/orginazation/view/',
    ORG: 'cms/org',
    PROFILE: 'cms/org/profile',
    NEWS_LIST : '/cms/content/news',
    EVENTS_LIST: '/cms/content/events',
    VOLUNTEERING_LIST: '/cms/content/volunteering',
    MYLIST : '/cms/followers/list/my-lists',
    MYPEOPLELIST : '/cms/followers/list/my-people',
    CONTENTHUBCREATION: '/cms/content/mutation/',
    CONTENT_LIST : '/cms/content/list',
    HERENOW_LIST : '/cms/here-now',
    YOUR_APP : '/cms/your-app'
}