import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, HelperService } from '@common-services';
import { ENDPOINTS, MESSAGES } from '@constants';
import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { _ } from 'underscore';
import { IFeed } from 'src/app/modules/content/content.interface';
import { IPaginationConfig } from '../../interfaces/shared.interface';

@Component({
  selector: 'app-your-app-modal',
  templateUrl: './your-app-modal.component.html',
  styleUrls: ['./your-app-modal.component.css']
})
export class YourAppModalComponent implements OnInit {
  public subscriptionObj: any = {
    modalConfig: {},
    name: '',
    orgId: '',
    status: true,
    isLoaderTrue: false,
    search: '',
    paginationLegend: '',
    page: 1,
    totalPages: '',
    checkedFeedList: [],
    selectedFeeds: [],
    allChecked: false,
    error: false,
    pageAllFeedId: [],
    isSeach: false,
    list: [],
    msg: ''
  };
  public partnerObject: any = {
    partnerPaginationLegend: '',
    page: 1,
    totalPages: ''
  };
  public groupesCheckbox: any = [];
  myInbound: boolean = true;
  myOutbound: boolean = false;
  public selectedYourappPartnerList: any = [];
  public selectedPartner: any;
  $feeds: Array<IFeed>;
  public paginationConfig: IPaginationConfig = {
    "id": 'subscription',
    "totalPages": 0,
    "itemPerPage": 10
  };
  public partnerPaginationConfig: IPaginationConfig = {
    "id": 'partners',
    "totalPages": 0,
    "itemPerPage": 10
  };
  partnerForm: FormGroup;
  public showSpinner: boolean = false;
  public selectedPartnerYourapp: any = {};
  search: string = "";
  public $yourappPartner: any[];
  public submitStatus: boolean = false;
  public id: any;
  btnDisabled = false;
  public direction: any;
  isShown: boolean = false;
  isDirection: boolean = false;
  p: number = 1;
  public sortCon: any = {};
  public orgId: any = [];
  public errorObj: any = {};

  constructor(private bsModalRef: BsModalRef,
    private helperService: HelperService,
    private coreService: CoreService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.orgId = this.helperService.getFieldValueFromStorage('ORG', 'id');
    this.getPartnerList();
    this.initPartnerForm();
  }

  /**
   * @method: initLoginForm
   * @desc: Initialize the login form using Form builder
   */
  initPartnerForm() {
    this.partnerForm = this.formBuilder.group({
      direction: ['', [Validators.required]],
    });
  }

  toggleShow(showOf) {
    this.isShown = showOf === 'partnership' ? false : true;
    this.errorObj.status = false;
    this.subscriptionObj.search = '';
    this.subscriptionObj.page = this.p;
    this.selectedYourappPartnerList = [];
    this.subscriptionObj.checkedFeedList = [];
    this.subscriptionObj.selectedFeeds = [];
    this.partnerObject.page = 1;
    this.$yourappPartner.forEach((element, key) => {
      this.$yourappPartner[key].selected = false;
    });
    if (this.search.trim() != "") {
      this.search = '';
      this.getPartnerList();
    }
    if(this.isShown){
      this.getFMList();
      this.isDirection = false;
    } else{
      this.getOutboundFMList();
    }
  }

  directionToggle(showDir) {
    this.isDirection = showDir === 'in' ? false : true;
    this.errorObj.status = false;
    this.subscriptionObj.search = '';
    this.subscriptionObj.page = this.p;
    this.paginationConfig.totalPages = this.p;
    this.subscriptionObj.checkedFeedList = [];
    this.subscriptionObj.selectedFeeds = [];
    this.sortCon = '';
    if (this.search.trim() != "") {
      this.search = '';
      this.getPartnerList();
    }
    if (this.isDirection) {
      this.getOutboundFMList();
    } else {
      this.getFMList();
    }
  }

  /**
   * @method: filterList
   * @desc: Filter the list
   */
  filterList() {
    this.p = 1;
    this.getPartnerList();
  }


  /**
 * @method: getPartnerList
 * @desc: Get the list of partner list
 */
  getPartnerList() {
    this.helperService.showSpinner(true);
    this.coreService
      .get(
        `${ENDPOINTS.YOURAPP_PARTNER}?search=${encodeURIComponent(
          this.search.trim()
        )}`,
        {
          admin_assigned: true,
          is_ya_admin: true,
          app_id: this.helperService.getFieldValueFromStorage(
            "userinfo",
            "app_id"
          ),
          partner_request: true,
          page: this.partnerObject.page
        }
      )
      .subscribe(
        (res) => {
          this.helperService.showSpinner(false);
          this.handleContentResponse(res.body);
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  /**
* @method: getFMList
* @desc: Get the list of FM list
*/
  getFMList() {
    if (status) this.helperService.showSpinner(true);
    this.subscriptionObj.isLoaderTrue = true;
    this.coreService.get(`${ENDPOINTS.FEED}?search=${encodeURIComponent(this.subscriptionObj.search.trim())}`, {
      other_organization_feed: false,
      my_feed: true,
      partner_feed: false,
      is_ya_admin: true,
      app_id: this.helperService.getFieldValueFromStorage(
        "userinfo",
        "app_id"
      ),
      page: this.subscriptionObj.page,
      ...this.sortCon
    }).subscribe(res => {
      if (status) this.helperService.showSpinner(false);
      this.handleFeedResponse(res.body);
    }, (err) => {
      if (status) this.helperService.showSpinner(false);
      this.subscriptionObj.isLoaderTrue = false;
    });
  }

  /**
* @method: getFMList
* @desc: Get the list of FM list
*/
  getOutboundFMList() {
    if (status) this.helperService.showSpinner(true);
    this.subscriptionObj.isLoaderTrue = true;
    this.coreService.get(`${ENDPOINTS.FEED}?search=${encodeURIComponent(this.subscriptionObj.search.trim())}`, {
      other_organization_feed: true,
      is_ya_admin: true,
      app_id: this.helperService.getFieldValueFromStorage(
        "userinfo",
        "app_id"
      ),
      page: this.subscriptionObj.page,
      ...this.sortCon
    }).subscribe(res => {
      if (status) this.helperService.showSpinner(false);
      this.handleFeedResponse(res.body);
    }, (err) => {
      if (status) this.helperService.showSpinner(false);
      this.subscriptionObj.isLoaderTrue = false;
    });
  }

  /**
  * @method: onSearchBox
  * @desc: using for search feed list.
  */
  onSearchAction() {
    if (this.subscriptionObj.search.trim() === "") return;
    this.subscriptionObj.page = 1;
    this.subscriptionObj.isSeach = true;
    if (this.isDirection) {
      this.getOutboundFMList();
    } else {
      this.getFMList();
    }
  }

  /**
* @method: onPageChange
* @desc: Get the new list as per the pagination
* @param e 
*/
  onPageChange(e: any, pageChangeOf) {
    if (pageChangeOf === 'feeds') {
      this.subscriptionObj.page = e;
      if (this.isDirection) {
        this.getOutboundFMList();
      } else {
        this.getFMList();
      }
    } else if (pageChangeOf === 'partners') {
      this.partnerObject.page = e;
      this.getPartnerList();
    }
  }

  /**
 * @method: resetAllFeedsSelection
 * @desc: using for reset All selected feeds
 */
  resetAllFeedsSelection() {
    const preSelectedIds = [];
    this.subscriptionObj.checkedFeedList = [];
    this.subscriptionObj.selectedFeeds = [];
    this.selectedYourappPartnerList = [];
    this.checkAllFeedsCheckedOnPage();
  }
  /**
* @method: isRemoveSearch
* @desc: Method using for clear search on keyup
* @param search
*/
  isRemoveSearch(e: any) {
    if (this.subscriptionObj.search === '' && e.key !== 'Enter') {
      if (this.isDirection) {
        this.getOutboundFMList();
      } else {
        this.getFMList();
      }
    }
  }
  /**
  * @method: selectAllFeeds
  * @param: event
  * @desc: for selecting all checked on a page
  */
  selectAllFeeds(event) {
    this.subscriptionObj.allChecked = event;
    if (event) {
      this.$feeds.forEach(obj => {
        if (obj.request_status != 'pending' && obj.request_status != 'accept') this.subscriptionObj.checkedFeedList.push(obj.id);
        if (obj.request_status != 'pending' && obj.request_status != 'accept') this.subscriptionObj.selectedFeeds.push(obj);
      });
    } else {
      this.$feeds.forEach(elements => {
        const index = this.subscriptionObj.checkedFeedList.indexOf(elements.id);
        this.subscriptionObj.checkedFeedList.splice(index, 1);
        this.subscriptionObj.selectedFeeds.splice(index, 1);
      });
    }
    this.subscriptionObj.checkedFeedList = _.uniq(this.subscriptionObj.checkedFeedList);
    this.subscriptionObj.selectedFeeds = _.uniq(this.subscriptionObj.selectedFeeds, (item) => {
      return item.id;
    });
  }

  /**
   * @method: checkFeeds
   * @param: content
   * @desc: check a specific id
   */
  checkFeeds(content) {
    const index = this.subscriptionObj.checkedFeedList.indexOf(content['id']);
    if (index > -1 && index !== undefined) {
      this.subscriptionObj.checkedFeedList.splice(index, 1);
      this.subscriptionObj.selectedFeeds.splice(index, 1);
    } else {
      this.subscriptionObj.checkedFeedList.push(content['id']);
      this.subscriptionObj.selectedFeeds.push(content);
    }
    this.checkAllFeedsCheckedOnPage();
  }

  /**
* @method: handleContentResponse
* @desc: Handle the response of GET API
*/
  handleContentResponse(data: any) {
    this.$yourappPartner = data.data;
    this.partnerObject.totalPages = data.count;
    this.partnerPaginationConfig.totalPages = data.count;
    this.markAsSelected();
    this.getLegend('partners');
  }
  /**
   * @method: handleFeedResponse
   * @desc: Handle the response of GET API
   */
  handleFeedResponse(data: any) {
    this.$feeds = data.data;
    if (this.$feeds.length > 0) this.subscriptionObj.isSeach = false;
    this.paginationConfig.totalPages = data.count;
    this.subscriptionObj.totalPages = data.count;
    this.subscriptionObj.isLoaderTrue = false;
    this.getLegend('feeds');
    this.checkAllFeedsCheckedOnPage();
  }
  /**
   * @method: getLegend
   * @desc: Get the legend for pagination.
   */
  getLegend(getOf) {
    if (getOf === 'feeds')
      this.subscriptionObj.paginationLegend = this.helperService.legend(this.subscriptionObj.page, this.subscriptionObj.totalPages);
    else if (getOf === 'partners')
      this.partnerObject.partnerPaginationLegend = this.helperService.legend(this.partnerObject.page, this.partnerObject.totalPages);
  }
  /**
  * @method: checkAllFeedsCheckedOnPage
  * @desc: for checking if all checked on page
  */
  checkAllFeedsCheckedOnPage() {
    this.subscriptionObj.pageAllFeedId = _.pluck(this.$feeds, 'id');
    if ((this.subscriptionObj.checkedFeedList.length >= this.subscriptionObj.pageAllFeedId.length) && this.subscriptionObj.pageAllFeedId.length > 0) {
      const checker = (arr, target) => target.every(v => arr.includes(v));
      this.subscriptionObj.allChecked = checker(this.subscriptionObj.checkedFeedList, this.subscriptionObj.pageAllFeedId);
    } else {
      this.subscriptionObj.allChecked = false;
    }
  }

  /**
   * @method: handleError
   * @desc: Common error handler for all API calls
   * @param
   */
  handleError(err: any) {
    this.helperService.commonMethodForHandleError(err);
    this.helperService.showSpinner(false);
  }
  /**
   * @method: onSearch
   * @desc: Filter the list
   * @param e
   */
  onSearch(e: any) {
    if (this.search.trim() === "") return;
    this.partnerObject.page = 1;
    this.getPartnerList();
  }
  /**
   * @method: isEmptySearch
   * @desc: Method using for clear search on keyup
   * @param search
   */
  isEmptySearch(e: any) {
    if (this.search === "" && e.key !== "Enter") {
      this.getPartnerList();
    }
  }
  /**
 * @method: selectPartners
 * @desc: Method used for select partners
 * @param id
 */
  selectPartners(id) {
    if (id !== undefined && id !== null && id !== '') {
      const index = this.selectedYourappPartnerList.findIndex((x) => x === id);
      if (index > -1 && index !== undefined) {
        this.selectedYourappPartnerList.splice(index, 1);
        this.$yourappPartner.forEach((element, key) => {
          if (element.id === id) {
            this.$yourappPartner[key].selected = false;
          }
        });
      } else {
        this.$yourappPartner.forEach((element, key) => {

          if (element.id === id) {
            this.$yourappPartner[key].selected = true;
          }
        });
        this.selectedYourappPartnerList.push(id);
      }
    }
    /* this.markAsSelected(); */
  }

  /**
   * @method: markAsSelected
   * @desc: Method used mark as selected on modal open
   */
  markAsSelected() {
    if (this.selectedYourappPartnerList.length > 0) {
      this.selectedYourappPartnerList.forEach((element) => {
        const index = this.$yourappPartner.findIndex((x) => x.id === element);
        if (index > -1 && index !== undefined) {
          this.$yourappPartner[index].selected = true;
        }
      });
    }
  }
  /**
 * @method submitPartnershipRequest
 * @description Method using for submit partnership request  
 */
  submitPartnershipRequest() {
    if (this.selectedYourappPartnerList.length === 0) {
      this.errorObj.status = true;
      this.errorObj.msg = MESSAGES.NEW_PARTNERSHIP_SUBMIT_ERROR;
      return false;
    }

    if (this.myInbound && this.myOutbound) {
      this.groupesCheckbox = ['inbound', 'outbound']
    } else if (this.myInbound || !this.myOutbound) {
      this.groupesCheckbox = ['inbound']
    } else if (!this.myInbound || this.myOutbound) {
      this.groupesCheckbox = ['outbound']
    }

    this.coreService.post(`${ENDPOINTS.YOURAPP_CONNECTION_PATNER}/${this.orgId}/partner`, {
      partner_organization: this.selectedYourappPartnerList.length ? this.selectedYourappPartnerList : [],
      is_yourapp: true,
      direction: this.groupesCheckbox
    }).subscribe((res) => {
      if (res.status === 200) {
        if (res.body.hasOwnProperty('message')) {
          this.helperService.showSpinner(false);
          this.toastrService.success(res.body.message);
          this.closeNow();
          this.submitStatus = true;
        } else {
          this.submitStatus = false;
          this.helperService.showSpinner(false);
          this.toastrService.error(res.body.error);
        }
      }
    }, (error) => {
      this.submitStatus = false;
      this.toastrService.error(error.error.detail.message[0]);
      this.helperService.showSpinner(false);
    });
  }

  /**
  * @method submitSubscriptionRequest
  * @description Method using for submit subscription request  
  */
  submitSubscriptionRequest() {
    if (((this.subscriptionObj.checkedFeedList.length === 0 || this.selectedYourappPartnerList.length === 0) &&(!this.isDirection)) ||
    (this.subscriptionObj.checkedFeedList.length === 0 && this.isDirection))  {
      this.errorObj.status = true;
      this.errorObj.msg = MESSAGES.NEW_SUBSCRIPTION_SUBMIT_ERROR;
      return false;
    }
    if (this.subscriptionObj.checkedFeedList.length > 0) {
      this.subscriptionObj.error = false;
      const payloads = {
        feed: this.subscriptionObj.checkedFeedList,
        organization: this.myInbound && this.selectedYourappPartnerList.length ? this.selectedYourappPartnerList : [this.orgId],
        is_yourapp: true,
      };
      this.helperService.showSpinner(true);
      this.coreService.post(ENDPOINTS.SUBSCRIPTION, payloads).subscribe((res) => {
        this.helperService.showSpinner(false);
        if (res.body.status_code === 201 && res.body.message) {
          this.toastrService.success(res.body.message);
          this.bsModalRef.hide();
        } else {
          this.helperService.showSpinner(false);
          this.toastrService.error(res.body.message);
        }
      }, (error) => {
        this.helperService.showSpinner(false);
      });
    } else {
      this.subscriptionObj.error = true;
      this.subscriptionObj.msg = MESSAGES.SUBSCRIPTION_REQUEST_ERROR;
    }
  }

  /**
* @method: sorting
* @desc: Table sorting
* @param e
*/
  sorting(e: any) {
    this.subscriptionObj.page = 1;
    this.sortCon = e;
    if (this.isDirection) {
      this.getOutboundFMList();
    } else {
      this.getFMList();
    }
  }

  /**
   * @method: isSortAsc
   * @desc: Asc sorting
   * @param val
   */
  isSortAsc(val: any) {
    if (!this.sortCon) return;
    return (
      this.sortCon["sort_by"] === val && this.sortCon["order_by"] === "asc"
    );
  }

  /**
   * @method: isSortDesc
   * @desc: Desc sorting
   * @param val
   */
  isSortDesc(val: any) {
    if (!this.sortCon) return;
    return (
      this.sortCon["sort_by"] === val && this.sortCon["order_by"] === "desc"
    );
  }
  /**
  * @method: closeNow
  * @desc: Method using for proceed for cancel info.
  */
  closeNow() {
    this.bsModalRef.hide();
  }

  /**
   * @method: closeErrorMsg
   * @desc: Method using handle errors
   */
  closeErrorMsg() {
    this.errorObj.status = false;
    this.errorObj.msg = '';
  }
}
