import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard/auth.guard';
import { PolicyComponent } from './modules/policies/policy/policy.component';
import { TermsConditionsComponent } from './modules/terms-conditions/terms-conditions.component';

const routes: Routes = [
  { path: 'cms', loadChildren: () => import('./modules/starter/starter.module').then(m => m.StarterModule)},
  { path: '', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)},
  { path: 'policy', component: PolicyComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
