import { Component, OnInit } from '@angular/core';
import { CoreService, HelperService } from '@common-services';
import { ENDPOINTS } from '@constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html'
})
export class PolicyComponent implements OnInit {
  public appId: any = '';
  public pricacyContent: any = [];
  constructor(
    private toastrService: ToastrService,
    private coreService: CoreService,
    public helperService: HelperService)
    { }

  ngOnInit() {
    this.appId = this.helperService.renderAssets().appId;
    this.getPolicyContent();
  }

  /**
   * @method: getPolicyContent
   * @desc: Get policy content data
   */
  getPolicyContent() {
    this.helperService.showSpinner(true);
    this.coreService.get(`${ENDPOINTS.CMS_MASTER_LIST}/${this.appId}`, {}).subscribe((result) => {
      const data = this.helperService.success(result);
      this.pricacyContent = data && data.privacy_policy ? data.privacy_policy : '';
      this.helperService.showSpinner(false);
    }, (err) => {
      this.helperService.showSpinner(false);
    });
  }
}
