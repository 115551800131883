import { Component, OnInit, Input, EventEmitter, Output, ElementRef, HostListener, OnDestroy, ChangeDetectionStrategy, AfterViewInit, OnChanges } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {
  @Input() preLoadFilters: any;
  @Output() applyFilters = new EventEmitter();
  @Output() triggerFilter = new EventEmitter();
  @Input() fieldsConf: Array<object>;
  @Input() prop: Array<any>;
  @Input() type: any;
  _disableFilter = new BehaviorSubject(false);
  @Input()
  set disableFilter(value: any) {
    this._disableFilter.next(value);
  }

  get disableFilter() {
    return this._disableFilter.getValue();
  }

  submitted = false;
  myForm: FormGroup;
  toggleFilter: boolean = true;
  operatorValue: string = "1";
  highlight: boolean = false;
  filtersApplied: any;
  disableFilterButton: boolean = false;

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      items: this.fb.array([
        this.initFields(true)
      ])
    });
    this._disableFilter.subscribe(res => {
      if (res) this.disableFilterButton = res;
    });
    if (this.preLoadFilters && this.preLoadFilters.length > 0 && this.type === 'LIST') {
      this.createFiltersAndSetValues();
      this.highlight = true;
    }
  }

  ngOnChanges() {
    if (this.preLoadFilters && this.preLoadFilters.length > 0 && this.type === 'FILTER') {
      this.createFiltersAndSetValues();
      this.highlight = true;
      this.filtersApplied = this.preLoadFilters;
    }
  }

  /**
   * @method: toggleFilterDropdown
   * @desc: Toggle dropdown 
   */
  toggleFilterDropdown() {
    this.toggleFilter = !this.toggleFilter;
    this.highlight = !this.highlight;
    (this.filtersApplied && this.filtersApplied.length > 0) ? this.highlight = true : this.highlight = false;
    this.triggerFilter.next(this.toggleFilter);
  }

  /**
   * @method: createFiltersAndSetValues
   * @desc: Create rows for pre filled filters
   */
  createFiltersAndSetValues() {
    const control = <FormArray>this.myForm.controls['items'];
    control.removeAt(0);
    for (let i = 0; i <= this.preLoadFilters.length - 1; i++) {
      this.addRowsForPreloadFilters();
      this.setValueOnFields(i, this.preLoadFilters[i]);
    }
    if (this.type === 'LIST') {
      this.myForm.get('items').disable();
    }
  }

  /**
   * @method: addRowsForPreloadFilters
   * @desc: Add formgroup in form array
   */
  addRowsForPreloadFilters() {
    const control = <FormArray>this.myForm.controls['items'];
    control.push(this.initFields(false));
  }

  /**
   * @method: setValueOnFields
   * @desc: Set the filter values
   * @param i 
   * @param data 
   */
  setValueOnFields(i: number, data: object) {
    let group = <FormGroup>this.filters.controls[i];
    setTimeout(() => {
      group.patchValue(data);
    }, 100);
  }

  /**
   * @method: initFields
   * @desc: initialize fields with the default values
   */
  initFields(disabled: boolean) {
    return this.fb.group({
      p: [''],
      r: [{ value: '', disabled: disabled }],
      f: [{ value: '', disabled: disabled }],
      o: [this.operatorValue, Validators.required]
    });
  }

  /**
   * @method: addFilterRows
   * @desc: Add filter row
   */
  addFilterRows(e: any) {
    if (e) e.stopPropagation();
    const control = <FormArray>this.myForm.controls['items'];
    if (control.length === 5) return;
    this.submitted = false;
    control.push(this.initFields(true));
  }

  /**
   * @method: onPropertyChange
   * @desc: Enable/ Disable contains and value section of property change
   * @param e 
   * @param i 
   */
  onPropertyChange(e, i) {
    let fieldVal = e.target.value, control, relation, value, defaultOperator;
    control = <FormArray>this.myForm.controls['items'];
    relation = <FormControl>control.controls[i]['controls'].r;
    value = <FormControl>control.controls[i]['controls'].f;
    relation.setValue("");
    if (fieldVal) {
      relation.enable();
      value.disable();
      defaultOperator = _.find(this.fieldsConf[fieldVal]['relation'], { "name": "Contains" });
      if (defaultOperator) {
        relation.setValue(defaultOperator['code']);
        value.enable();
      }
    } else {
      relation.disable();
      value.disable();
    }
    value.setValue("");
    this.submitted = false;
  }

  /**
   * @method: onContainsChange
   * @desc: render value section of contains change
   * @param e 
   * @param i 
   */
  onContainsChange(e, i) {
    let val = e.target.value, control, value, fieldVal, fieldObj, validation;
    control = <FormArray>this.myForm.controls['items'];
    value = <FormControl>control.controls[i]['controls'].f;
    if (val) {
      setTimeout(() => {
        value.enable();
      }, 100)
    } else {
      value.disable();
    }
    value.setValue("");
    this.submitted = false;
  }

  /**
   * @method: onChangeOperator
   * @desc: Change all the oprator values
   * @param e 
   */
  onChangeOperator(e) {
    let val = e.target.value, operator, controls;
    controls = <FormArray>this.myForm.controls['items']['controls'];
    for (let i = 0; i < controls.length; i++) {
      operator = <FormControl>controls[i]['controls'].o;
      this.operatorValue = val;
      operator.setValue(val);
    }
    this.submitted = true;
    this.submitFilter();
  }

  /**
   * @method: removeFilterRow
   * @desc: Remove filter row
   * @param e 
   * @param i 
   */
  removeFilterRow(e: any, i: number) {
    e.stopPropagation();
    const control = <FormArray>this.myForm.controls['items'];
    if (control.length == 1) return;
    control.removeAt(i);
    this.submitted = true;
    this.submitFilter();
  }

  get f() { return this.myForm.controls; }
  get filters() { return this.f.items as FormArray; }

  /**
   * @method:submitFilterAndErrorHandler
   * @desc: handle error than submit filter
   */
  submitFilterAndErrorHandler(e: any, i?: any) {
    e.stopPropagation();
    this.submitted = true;
    if (this.myForm.invalid) return;
    setTimeout(() => {
      this.submitFilter();
    }, 100);
  }

  /**
   * @method: submitFilter
   * @desc: submit filter
   */
  submitFilter() {
    let filterToApply = [], jsonString;
    this.myForm.value.items.forEach((item, i) => {
      if ('p' in item && 'r' in item && 'f' in item && item.f) {
        this.filters.controls[i]['controls']['f'].setValue(item.f.trim());
        item.f = item.f.trim();
        if (item.f) filterToApply.push(item);
      }
    });
    if (filterToApply && filterToApply.length > 0) {
      jsonString = JSON.stringify(filterToApply);
      this.filtersApplied = filterToApply;
      this.highlight = true;
    } else {
      jsonString = '';
      this.filtersApplied = [];
      this.highlight = false;
    }
    setTimeout(() => {
      this.applyFilters.next(jsonString);
    }, 100);
  }

  /**
   * @method: onReset
   * @desc: Reset the filter
   */
  onReset(e: any) {
    let control, value, relation, operator;
    this.submitted = false;
    while (this.filters.length !== 1) {
      this.filters.removeAt(0)
    }
    this.operatorValue = '1';
    control = <FormArray>this.myForm.controls['items'];
    value = <FormControl>control.controls[0]['controls'].f;
    relation = <FormControl>control.controls[0]['controls'].r;
    operator = <FormControl>control.controls[0]['controls'].o;
    setTimeout(() => {
      operator.setValue("1");
    }, 100);
    value.disable();
    relation.disable();
    this.filtersApplied = [];
    (this.filtersApplied && this.filtersApplied.length > 0) ? this.highlight = true : this.highlight = false;
    setTimeout(() => {
      this.applyFilters.next("");
    }, 100);
  }

  /**
   * @param targetElement 
   * @desc: Hide options when click outside on the window
   */
  @HostListener('document:click', ['$event'])
  public onClick(targetElement) {
    const isInsideClicked = this.elementRef.nativeElement.contains(targetElement.target);
    if (!isInsideClicked) {
      this.toggleFilter = true;
      (this.filtersApplied && this.filtersApplied.length > 0) ? this.highlight = true : this.highlight = false;
    }
  }
}
