import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plusorminus'
})
export class PlusorminusPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (Math.sign(value) == 1 || Math.sign(value) == 0) {
      if(value === 0 || !value) return value ? value : 0;
      return "+" + value;
    } else {
      return value ? value : 0;
    }
  }

}
