import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService, HelperService } from '@common-services';
import { ENDPOINTS, MESSAGES } from '@constants';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import * as _ from 'underscore';
import {
  Address,
  IHereNowOutput,
} from '../../../modules/here-now/here-now.interface';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification-modal.component.html',
  styleUrls: ['./push-notification-modal.component.css'],
})
export class PushNotificationModalComponent implements OnInit {
  @ViewChild('scheduledDatePop', { static: false })
  scheduledDatePop: ElementRef;
  @ViewChild('endDate', { static: false }) endDate: ElementRef;
  @ViewChild('addresstext', { static: false }) addresstext: any;
  pushNotificationform: FormGroup;
  isSubmit: boolean = false;
  time: any = moment().add(15, 'minutes').format('YYYY, MM, DD HH:mm:ss');
  ts: any = new Date(this.time);
  orgInfo: any;
  obj: any = [];
  disabledIsPushNow: any = false;
  tempPushData: any = [];
  ispushNowFor: any = 'yes';
  date: any = moment().format('YYYY, MM, DD');
  occ: number;
  occurance: any = {};
  day: [];
  currentDate: [];
  dayForOcc: [];
  assets: any;
  dateforOcc: [];
  min: any = new Date(this.date);
  currentIndex: any;
  singlePush: any;
  addBtnDisabled: boolean;
  ispushNow: boolean = false;
  month_on_day: boolean = false;
  external_Link: string;
  content: any = {
    dateObj: {},
    AllPushNotification: {},
    actionType: '',
    newsId: '',
    moduleType: '',
    status: '',
    isDirect: false,
    contentName: '',
  };
  onCompleteNotification: Subject<boolean>;
  modalRef: BsModalRef;
  superPushSetting: any = {};
  preSelectedDataForSuperPush: object = {
    preSelectedTagIds: [],
    preSelectedTags: [],
    preSelectedMyListsIds: [],
    preSelectedMyLists: [],
    preSelectedMyPeopleIds: [],
    preSelectedMyPeople: [],
    all_follower: '',
  };
  followersErrors: string;
  hereNowConf = {
    softCreateData: true,
    type: 'push',
    parentId: '',
    hereNow: null,
    parentStatus: null,
  };
  options: any;
  addressObj: Address = {
    address: '',
    longitude: null,
    latitude: null,
    state: null,
    country: null,
  };
  hereNowData: IHereNowOutput;
  mutateHereNow: boolean = false;
  Autocomplete: any;
  MapUrl: string;
  weekNumber: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    public helperService: HelperService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.onCompleteNotification = new Subject();
    this.onCompleteNotification.next(false);
    this.content = this.modalService.config.initialState['content'];
    if (this.content.AllPushNotification.length > 0) {
      this.tempPushData = this.content.AllPushNotification;
      this.currentIndex = undefined;
    }
    this.assets = this.helperService.renderAssets();
    this.obj.scheduledDatePopError = '';
    this.orgInfo = this.coreService.getLocalstorage('ORG');
    this.pushNotificationFormInit();
    this.pushNotificationform.patchValue({
      ispushNow: 'yes',
      month_on_day: true,
      external_Link: '',
      is_recurring: false,
      interval: null,
    });
    this.pushNotificationform.get('scheduledTime').setValidators(null);
    this.pushNotificationform.get('scheduledDatePop').setValidators(null);
    if (
      this.content &&
      this.content.pushDetails &&
      this.content.pushDetails.length > 0
    ) {
      const { index, pushData, type } = this.content.pushDetails[0];
      if (type === 'EDIT' && pushData) {
        this.editExistingPush(index, pushData, type);
      } else if (type === 'ADD') {
        this.createNewPush(index, pushData, type);
      }
    } else {
      this.singlePush = {};
      this.currentIndex = null;
    }
    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD hh:mm');
    const endOfMonth = moment()
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD hh:mm');
    this.occurance = {
      1: 'First',
      2: 'Second',
      3: 'Third',
      4: 'Fourth',
      5: 'Fifth',
    };
  }

  /**
   * @method: ngAfterViewInit
   * @desc: Google maps autocomplete for address field
   */
  ngAfterViewInit() {
    if (
      this.ispushNowFor !== 'hereNow' &&
      this.content.moduleType !== 'CONTENT'
    ) {
      const mapinput = document.getElementById('map') as HTMLElement;
      const input: any = document.getElementById('autocomplete') as HTMLElement;
      const map = new google.maps.Map(mapinput, {
        center: { lat: -33.8688, lng: 151.2195 },
        zoom: 13,
        mapTypeId: 'roadmap',
      });
      // Create the search box and link it to the UI element.
      const searchBox = new google.maps.places.SearchBox(input);

      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      // Bias the SearchBox results towards current map's viewport.
      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds());
      });

      let markers = [];

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        this.setAddressObj(places[0]);

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          const icon = {
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          markers.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
    }
  }

  /**
   * @method: createNewPush
   * @desc: create new push notification
   */
  createNewPush(index, pushData, type) {
    let tempBol = false;
    this.currentIndex = index;
    tempBol = this.preparePushData(pushData);
    if (
      (this.singlePush && this.singlePush.ispushNow === 'yes') ||
      !this.disabledIsPushNow
    ) {
      this.disabledIsPushNow = false;
    }
    tempBol
      ? (this.disabledIsPushNow = true)
      : (this.disabledIsPushNow = false);
    this.ispushNowFor = this.disabledIsPushNow ? 'no' : 'yes';
    this.pushNotificationform.patchValue({
      ispushNow: this.disabledIsPushNow ? 'no' : 'yes',
    });
  }

  /**
   * @method: editExistingPush
   * @desc: Edit the existing push notification
   */
  editExistingPush(index, pushData, type) {
    this.currentIndex = index;
    this.tempPushData = pushData;
    this.singlePush = pushData[index];
    let ispushNowVal = 'yes';
    if (this.content.isDirect) this.getPrefilledDataForPush();
    if (this.singlePush.is_recurring) {
      const endDate = moment
        .utc(this.singlePush.end_date)
        .format('YYYY-MM-DD HH:mm:ss');
      this.pushNotificationform.patchValue({
        end_date: new Date(endDate),
      });
      ispushNowVal = 'recurring';
      this.pushNotificationform.patchValue({
        recurring_type: this.singlePush.recurring_type,
        interval: parseInt(this.singlePush.interval),
        is_recurring: this.singlePush.is_recurring,
        month_on_day: this.singlePush.month_on_day,
        external_Link: this.singlePush.external_Link,
      });
    } else if (this.singlePush.push_type === 'here_now_push') {
      ispushNowVal = 'hereNow';
      this.hereNowConf.parentId = this.singlePush.id;
      this.hereNowConf.hereNow = this.singlePush.herenow_id;
      this.hereNowConf.parentStatus = this.singlePush.status;
      this.mutateHereNow = true;
    } else {
      if (
        (this.singlePush.scheduled_time &&
          this.singlePush.ispushNow === 'no') ||
        this.singlePush.scheduled_time
      ) {
        ispushNowVal = 'no';
      }
      if (this.singlePush.ispushNow === 'yes') {
        ispushNowVal = 'yes';
      }
    }
    this.pushNotificationform.patchValue({
      msg: this.singlePush.msg || '',
      scheduledDatePop: new Date(this.singlePush.scheduled_time),
      scheduledTime: new Date(this.singlePush.scheduled_time),
      ispushNow: ispushNowVal,
    });
    this.ispushNowFor = ispushNowVal;
    if (type === 'ADD') {
      let tempBol = false;
      tempBol = this.preparePushData(pushData);
      tempBol
        ? (this.disabledIsPushNow = true)
        : (this.disabledIsPushNow = false);
      this.ispushNowFor = this.disabledIsPushNow ? 'no' : this.ispushNowFor;
      if (this.singlePush.ispushNow === 'yes') {
        this.disabledIsPushNow = false;
      } else if (this.singlePush.ispushNow === 'no') {
        this.disabledIsPushNow = true;
      }
    }
  }

  /**
   * @method: preparePushData
   * @desc: Prepare push data
   */
  preparePushData(pushData: any) {
    let tempBol = false;
    if (pushData.length > 0) {
      pushData.forEach((p) => {
        if (p.ispushNow === 'yes') {
          this.disabledIsPushNow = true;
          tempBol = true;
        }
      });
    }
    return tempBol;
  }

  /**
   * @method: pushNotificationFormInit
   * @desc: Method using for init the push notification form.
   */
  pushNotificationFormInit() {
    this.pushNotificationform = this.formBuilder.group({
      msg: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(140),
        ],
      ],
      ispushNow: ['', [Validators.required]],
      scheduledDatePop: [''],
      scheduledTime: [''],
      recurring_type: [''],
      is_recurring: [''],
      end_date: [''],
      address: [''],
      interval: ['', [Validators.required]],
      month_on_day: [true],
      external_Link: [
        '',
        [
          Validators.pattern(
            /(http|https)(:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/
          ),
          Validators.maxLength(200),
        ],
      ],
    });
  }

  // convenience getter for easy access to form fields postLiveDate
  get get() {
    return this.pushNotificationform.controls;
  }

  /**
   * @method: scheduledDate
   * @desc: Method using for set notification schedule date time.
   * param : event
   */
  scheduledDate(event) {
    const val = event.target.value;
    if (val === 'no' || val === 'recurring')
      this.setFormFieldValidations(
        ['scheduledTime', 'scheduledDatePop'],
        [Validators.required, Validators.required]
      );
    if (val === 'recurring') {
      this.pushNotificationform.patchValue({
        is_recurring: true,
        scheduledTime: '',
        scheduledDatePop: '',
        recurring_type: '',
        end_date: '',
        month_on_day: true,
      });
      this.setFormFieldValidations(
        [
          'end_date',
          'recurring_type',
          'interval',
          'is_recurring',
          'month_on_day',
        ],
        [
          Validators.required,
          Validators.required,
          Validators.required,
          Validators.required,
          Validators.required,
        ]
      );
    } else {
      this.pushNotificationform.patchValue({
        is_recurring: false,
        scheduledTime: '',
        scheduledDatePop: '',
        recurring_type: '',
        interval: '',
        month_on_day: true,
      });
      this.setFormFieldValidations(
        [
          'end_date',
          'is_recurring',
          'interval',
          'recurring_type',
          'month_on_day',
        ],
        [null, null, null, null, null]
      );
    }
    if (val === 'hereNow') {
      this.addressObj.address =
        this.addressObj.latitude =
        this.addressObj.longitude =
          null;
      this.get.address.setValue('');
    }
    this.ispushNowFor = val;
    this.isSubmit = false;
  }

  /**
   * @method: resetNotification
   * @desc: Method using for reset notification.
   */
  resetNotification() {
    this.bsModalRef.hide();
    this.pushNotificationform.reset();
    this.isSubmit = false;
    this.pushNotificationform.patchValue({
      ispushNow: 'yes',
      month_on_day: true,
      external_Link: '',
    });
    this.ispushNowFor = 'yes';
    this.hereNowConf = {
      softCreateData: true,
      type: 'push',
      parentId: '',
      hereNow: null,
      parentStatus: null,
    };
    this.hereNowData = null;
    this.mutateHereNow = false;
  }

  /**
   * @method: onKeyup
   * @desc: Method using for keyup and make it isSubmit flag true/false
   * param: event
   */
  onKeyup(event) {
    this.isSubmit = false;

    if (
      event &&
      event.target &&
      event.target.value &&
      event.target.value === 'months'
    ) {
      if (
        this.pushNotificationform.controls.scheduledDatePop.value &&
        this.pushNotificationform.controls.scheduledDatePop.value !== '' &&
        this.pushNotificationform.controls.scheduledDatePop.value !== null
      ) {
        this.getWeekFromDateForReoccurence(
          this.pushNotificationform.controls.scheduledDatePop.value
        );
      }
    }
  }

  /**
   * @method: getWeekFromDateForReoccurence
   * @desc: Method to get the week for reoccurence from the date selected
   * param: date
   */
  getWeekFromDateForReoccurence(date) {
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDateDay = firstDate.getDay(); // sun 0, sat 6
    let weekCount = date.getDay() < firstDateDay ? 0 : 1;
    let firstDateDate = firstDate.getDate();
    let numberOfDaysPassed = 0;

    while (firstDateDate <= date.getDate()) {
      let updateDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        firstDateDate
      );
      numberOfDaysPassed += 1;

      if (
        updateDate.getDay() == date.getDay() &&
        (numberOfDaysPassed >= 7 || weekCount === 0)
      ) {
        weekCount += 1;
      }

      ++firstDateDate;
    }

    this.weekNumber =
      weekCount === 0 || weekCount === 1
        ? 'First'
        : weekCount === 2
        ? 'Second'
        : weekCount === 3
        ? 'Third'
        : weekCount === 4
        ? 'Fourth'
        : 'Fifth';
  }

  /**
   * @method: onSubmit
   * @desc: Method using for send push notification and before submit prepare some payload and array element to pushing.
   * param: status
   */
  onSubmit(status?) {
    this.isSubmit = true;
    setTimeout(() => {
      this.submitNotification(status);
    }, 10);
  }

  /**
   * @method: submitNotification
   * @desc: called this method after some timeout so that HereNow form would have time to detect errors
   * @param status
   */
  submitNotification(status?) {
    if (
      this.content.status === 'Draft' &&
      (this.content.moduleType !== 'CONTENT' || this.content.isDirect)
    )
      this.content.status = status ? status : 'Active';
    if (this.pushNotificationform.controls.ispushNow.value === 'yes')
      this.setFormFieldValidations(
        ['scheduledTime', 'scheduledDatePop', 'interval'],
        [null, null, null]
      );
    if (
      this.pushNotificationform.controls.scheduledDatePop.value &&
      this.pushNotificationform.controls.scheduledDatePop.value !=
        'Invalid date'
    )
      this.setFormFieldValidations(['scheduledDatePop'], [null]);
    if (this.pushNotificationform.controls.ispushNow.value === 'no') {
      this.setFormFieldValidations(
        ['scheduledTime', 'scheduledDatePop', 'interval'],
        [Validators.required, Validators.required, null]
      );
    }
    if (
      this.pushNotificationform.controls.ispushNow.value === false ||
      this.pushNotificationform.controls.is_recurring.value === undefined ||
      this.pushNotificationform.controls.is_recurring.value === null
    )
      this.setFormFieldValidations(
        ['is_recurring', 'recurring_type'],
        [null, null]
      );
    if (this.ispushNowFor !== 'recurring')
      this.setFormFieldValidations(['end_date'], [null]);
    if (this.ispushNowFor === 'hereNow') {
      this.setFormFieldValidations(
        ['scheduledTime', 'scheduledDatePop'],
        [null, null]
      );
    }
    if (!this.pushNotificationform.invalid) {
      let finalDateTime = '';
      if (this.pushNotificationform.controls.scheduledDatePop.value) {
        const date = moment(
          this.pushNotificationform.controls.scheduledDatePop.value
        ).format('MM/DD/YYYY');
        const time = moment(
          this.pushNotificationform.controls.scheduledTime.value
        ).format('h:mm A');
        finalDateTime = moment(date + ' ' + time).format('MM/DD/YYYY h:mm A');
        if (this.content.dateObj.postLiveTime) {
          const time1 = moment(finalDateTime).format('YYYY, MM, DD h:mm A');
          const time2 = moment(this.content.dateObj.postLiveTime).format(
            'YYYY, MM, DD h:mm A'
          );
          if (this.pushNotificationform.controls.ispushNow.value !== 'yes') {
            if (
              moment(time2).isAfter(moment(time1)) ||
              moment(time2).isSame(moment(time1))
            ) {
              this.toastr.error(MESSAGES.NOTIFICATION_SCHEDULE_DATE_TIME_ERROR);
              this.isSubmit = false;
              return false;
            }
          }
        }
      } else {
        const date = moment().format('MM/DD/YYYY');
        const time = moment().format('h:mm A');
        finalDateTime = moment(date + ' ' + time).format('MM/DD/YYYY h:mm A');
      }

      if (
        this.content.moduleType !== 'CONTENT' &&
        this.superPushSetting &&
        !this.superPushSetting.all_follower &&
        this.superPushSetting.follower_groups.length == 0 &&
        this.superPushSetting.followers.length == 0 &&
        this.superPushSetting.tags.length == 0 &&
        this.content.status &&
        this.content.status.toLowerCase() !== 'draft'
      ) {
        this.followersErrors = MESSAGES.FOLLOWERS_ERROR;
        this.isSubmit = false;
        return false;
      }

      const hereNowStatus = this.checkHereNowStatus();
      if (this.ispushNowFor === 'hereNow' && !hereNowStatus) return false;
      this.singlePush && this.content.actionType === 'EDIT'
        ? this.prepareTemporaryPushData(finalDateTime)
        : this.prepareDataToCreatePushes(finalDateTime);
      this.completePush();
    }
  }

  /**
   * @method: checkHereNowStatus
   * @desc: check the form status of HereNow
   */
  checkHereNowStatus() {
    if (
      this.ispushNowFor === 'hereNow' &&
      this.hereNowData &&
      this.hereNowData.herenow_form_settings &&
      this.hereNowData.herenow_form_settings.formValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @method: prepareDataToCreatePushes
   * @desc: Prepare data to create pushes
   * @param finalDateTime
   */
  prepareDataToCreatePushes(finalDateTime) {
    let fEndDate = '';
    const newTime = moment(
      this.pushNotificationform.controls.scheduledTime.value
    ).format('h:mm A');
    const finalDate = moment(
      this.pushNotificationform.controls.end_date.value
    ).format('MM/DD/YYYY');
    const finalEndDate = moment(finalDate + ' ' + newTime).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    fEndDate = finalEndDate;
    let tempArray = {
      ispushNow: this.pushNotificationform.controls.ispushNow.value,
      month_on_day: this.pushNotificationform.controls.month_on_day.value,
      external_Link: this.pushNotificationform.controls.external_Link.value,
      scheduled_time: finalDateTime,
      scheduled_date: moment(finalDateTime).format('YYYY, MM, DD'),
      msg: this.pushNotificationform.controls.msg.value,
      recurring_type: this.pushNotificationform.controls.is_recurring.value
        ? this.pushNotificationform.controls.recurring_type.value
        : '',
      interval: this.pushNotificationform.controls.interval.value
        ? parseInt(this.pushNotificationform.controls.interval.value)
        : null,
      is_recurring:
        this.pushNotificationform.controls.is_recurring.value === null
          ? false
          : this.pushNotificationform.controls.is_recurring.value,
      end_date:
        fEndDate !== 'Invalid date' &&
        fEndDate !== undefined &&
        fEndDate !== null
          ? fEndDate
          : null,
    };
    tempArray = this.checkTypeAndSetAddress(tempArray);
    if (
      this.currentIndex !== '' &&
      this.currentIndex !== undefined &&
      this.currentIndex !== null
    ) {
      this.tempPushData[this.currentIndex] = tempArray;
    } else {
      this.tempPushData.push(tempArray);
    }
  }

  /**
   * @method:
   * @desc: prepare data when edit content pushes or direct pushes
   * @param finalDateTime
   */
  prepareTemporaryPushData(finalDateTime) {
    this.singlePush.ispushNow =
      this.pushNotificationform.controls.ispushNow.value;
    this.singlePush.month_on_day =
      this.pushNotificationform.controls.month_on_day.value;
    this.singlePush.external_Link =
      this.pushNotificationform.controls.external_Link.value;
    this.singlePush.scheduled_time =
      moment(finalDateTime).format('MM/DD/YYYY h:mm A');
    this.singlePush.scheduled_date =
      moment(finalDateTime).format('YYYY, MM, DD');
    this.singlePush.msg = this.pushNotificationform.controls.msg.value;
    const newTime = moment(
      this.pushNotificationform.controls.scheduledTime.value
    ).format('h:mm A');
    const finalDate = moment(
      this.pushNotificationform.controls.end_date.value
    ).format('MM/DD/YYYY');
    const finalEndDate = moment(finalDate + ' ' + newTime).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    this.singlePush.end_date =
      finalEndDate !== 'Invalid date' &&
      finalEndDate !== undefined &&
      finalEndDate !== null
        ? finalEndDate
        : null;
    this.singlePush.recurring_type = this.pushNotificationform.controls
      .is_recurring.value
      ? this.pushNotificationform.controls.recurring_type.value
      : '';
    this.singlePush.interval = this.pushNotificationform.controls.interval.value
      ? parseInt(this.pushNotificationform.controls.interval.value)
      : null;
    this.singlePush.is_recurring =
      this.pushNotificationform.controls.is_recurring.value === null
        ? false
        : this.pushNotificationform.controls.is_recurring.value;
    const newNotification = this.tempPushData.findIndex(
      (x) => x.id === this.singlePush.id
    );
    this.singlePush = this.checkTypeAndSetAddress(this.singlePush);
    if (newNotification > -1) {
      this.tempPushData[newNotification] = this.singlePush;
    } else {
      this.tempPushData.push(this.singlePush);
    }
  }

  /**
   * @method: setFormFieldValidations
   * @desc: set the validations over form fields
   * @param fields
   * @param restrictions
   */
  setFormFieldValidations(fields: Array<string>, restrictions: Array<any>) {
    fields.forEach((field: string, i: number) => {
      if (this.ispushNowFor === 'yes' || this.ispushNowFor === 'no') {
        this.pushNotificationform.patchValue({
          interval: null,
        });
      }
      this.pushNotificationform.get(field).setValidators(restrictions[i]);
      this.pushNotificationform.get(field).updateValueAndValidity();
    });
  }

  /**
   * @method: updateDate
   * @desc: Method using as flag to update date on schedule case
   * param: val, type
   */
  updateDate(val, type) {
    this.isSubmit = false;
    this.obj.scheduledDatePopError = '';
    if (type === 'scheduledDatePop') this.set12AMTimeForFutureDate(val.value);
  }

  /**
   * @method: completePush
   * @desc: Method using for filter out the notificaiton and prepare new push object
   */
  completePush() {
    if (
      this.content.actionType === 'EDIT' ||
      this.content.moduleType !== 'CONTENT'
    ) {
      const newNotification = this.tempPushData.find((x) => x.id === undefined);
      const index = this.tempPushData.findIndex((x) => x.id === undefined);
      if (
        newNotification &&
        newNotification !== undefined &&
        newNotification !== null
      )
        this.sendPushNotification(newNotification, index);
      if (this.singlePush && !newNotification) this.updatePushNotification();
    } else {
      this.onCompleteNotification.next(this.tempPushData);
    }
    if (this.ispushNowFor !== 'hereNow') this.resetNotification();
  }

  /**
   * @method: sendPushNotification
   * @desc: Method using of send new notification for exitsting items or contents
   * param: newNotification, index
   */
  sendPushNotification(newNotification, index) {
    if (
      newNotification &&
      newNotification !== undefined &&
      newNotification !== null
    ) {
      this.helperService.showSpinner(true);
      const time: any = new Date(newNotification.scheduled_time);
      newNotification.scheduled_time = moment
        .utc(time)
        .format('YYYY-MM-DD HH:mm:ss');
      const payload = this.getPayloadRequest(newNotification);

      const payloads = {
        push: [payload],
        status: this.content.status,
        organization: this.orgInfo.id,
        ...this.superPushSetting,
      };
      this.coreService.post(ENDPOINTS.NOTIFICATION, payloads).subscribe(
        (res) => {
          if (this.ispushNowFor === 'hereNow') {
            this.mutateHereNow = true;
            if (res.body.data && res.body.data[0] && res.body.data[0].id)
              this.hereNowConf.parentId = res.body.data[0].id;
          } else {
            this.goToPushesList(true, MESSAGES.NOTIFICATION_ADDED_SUCCESS);
          }
          this.tempPushData[index] = res.body.data;
        },
        (error) => {
          this.helperService.showSpinner(false);
          if (error && error.body) this.toastr.error(error.body.data.error);
        }
      );
    }
  }

  /**
   * @method: goToPushesList
   * @desc: go back to the list page
   */
  goToPushesList(showToast: boolean, message: string) {
    this.onCompleteNotification.next(this.tempPushData);
    if (showToast) this.toastr.success(message);
  }

  /**
   * @method: updatePushNotification
   * @desc: Method using for update exitsting notificaiton pushes.
   */
  updatePushNotification() {
    let tempStatus = this.singlePush.status;
    const time: any = new Date(this.singlePush.scheduled_time);
    this.singlePush.scheduled_time = moment
      .utc(time)
      .format('YYYY-MM-DD HH:mm:ss');
    if (
      this.singlePush.push_type === 'here_now_push' &&
      this.ispushNowFor !== 'hereNow'
    ) {
      if (this.content.moduleType === 'DIRECT')
        this.singlePush.push_type = 'direct_push';
      this.singlePush.herenow_id = null;
    } else if (
      this.ispushNowFor == 'hereNow' &&
      this.singlePush.push_type !== 'here_now_push'
    ) {
      if (this.content.moduleType === 'DIRECT')
        this.singlePush.push_type = 'here_now_push';
      this.singlePush.herenow_id = null;
    }

    if (this.singlePush.ispushNow === 'yes')
      this.singlePush = _.omit(this.singlePush, [
        'scheduled_time',
        'scheduled_date',
      ]);
    if (this.singlePush.push_type === 'Content Push')
      this.singlePush = _.omit(this.singlePush, [
        'push_type',
        'push_type_value',
      ]);
    this.singlePush.status = this.content.status;
    this.helperService.showSpinner(true);
    this.singlePush = _.omit(this.singlePush, [
      'all_follower',
      'follower_groups',
      'followers',
      'tags',
      'notification_status',
      'notification_type',
    ]);
    this.singlePush = {
      ...this.singlePush,
      ...this.superPushSetting,
    };

    this.singlePush = this.checkTypeAndSetAddress(this.singlePush);

    this.singlePush.herenow_attached =
      this.ispushNowFor === 'hereNow' ? true : false;
    if (this.ispushNowFor === 'hereNow')
      this.singlePush.scheduled_time = this.singlePush.scheduled_date = null;
    this.coreService
      .put(`${ENDPOINTS.NOTIFICATION}/${this.singlePush.id}`, this.singlePush)
      .subscribe(
        (res) => {
          this.isSubmit = false;
          if (this.ispushNowFor === 'hereNow') {
            res.body.data.status = tempStatus;
            if (
              this.hereNowData &&
              this.hereNowData.herenow_form_fields &&
              this.hereNowData.herenow_form_fields.hereNowId !==
                this.singlePush.herenow_id
            ) {
              this.passDataToHereNowComponent(res);
            } else if (this.hereNowData.herenow_form_settings.formUpdates) {
              this.passDataToHereNowComponent(res);
            } else if (tempStatus !== this.singlePush.status) {
              this.passDataToHereNowComponent(res);
            } else {
              this.helperService.showSpinner(false);
              this.goToPushesList(true, MESSAGES.NOTIFICATION_UPDATE_SUCCESS);
              this.resetNotification();
            }
          } else {
            this.helperService.showSpinner(false);
            this.goToPushesList(true, MESSAGES.NOTIFICATION_UPDATE_SUCCESS);
          }
          this.helperService.showSpinnerText('');
        },
        (error) => {
          this.helperService.showSpinnerText('');
          this.helperService.showSpinner(false);
          this.isSubmit = false;
        }
      );
  }

  /**
   * @method:
   * @desc:
   * @param res
   */
  passDataToHereNowComponent(res: any) {
    this.mutateHereNow = false;
    setTimeout(() => {
      this.mutateHereNow = true;
      this.hereNowConf.parentId = this.singlePush.id;
      this.hereNowConf.hereNow = null;
      if (res.body.data && res.body.data.status)
        this.hereNowConf.parentStatus = res.body.data.status;
    }, 10);
  }

  /**
   * @method: getPayloadRequest
   * @desc: Method using for prepare payload  for add/edit push notification for exitsting content
   * param: newNotification
   */
  getPayloadRequest(newNotification: any) {
    let payload: any = {
      content: this.content.contentId ? this.content.contentId : '',
      organization: this.orgInfo.id,
      status: this.content.status,
      scheduled_time: newNotification.scheduled_time,
      scheduled_date: newNotification.scheduled_date,
      msg: newNotification.msg,
      recurring_type: newNotification.recurring_type,
      is_recurring: newNotification.is_recurring,
      end_date: newNotification.end_date,
      external_Link: newNotification.external_Link,
    };
    if (this.content.moduleType === 'DIRECT') {
      if (this.ispushNowFor === 'hereNow') {
        payload.push_type = 'here_now_push';
        payload.scheduled_time =
          payload.end_date =
          payload.scheduled_date =
            null;
      } else {
        payload.push_type = 'direct_push';
      }
      payload = this.checkTypeAndSetAddress(payload);
    }
    if (newNotification.ispushNow === 'yes') {
      delete payload.scheduled_time;
      delete payload.scheduled_date;
    }
    if (this.pushNotificationform.controls.month_on_day.value == true) {
      payload.month_on_day = true;
    } else if (this.pushNotificationform.controls.month_on_day.value == false) {
      payload.month_on_day = false;
    }

    if (this.ispushNowFor === 'recurring') {
      payload.interval = parseInt(newNotification.interval);
    } else if (this.ispushNowFor === 'yes' || this.ispushNowFor === 'no') {
      payload.interval = null;
    }
    return payload;
  }

  /**
   * @method: validateDate
   * @desc: Validate the date format
   * param: event, type
   */
  validateDate(event, type) {
    if (type === 'scheduledDatePop')
      this.scheduledDatePop.nativeElement.value =
        this.helperService.dateFormate(event.target.value);
    if (type === 'end_date')
      this.endDate.nativeElement.value = this.helperService.dateFormate(
        event.target.value
      );
  }

  /**
   * @method: checkDate
   * @desc: Method using for validation user entered date
   * param: event, type
   */
  checkDate(event, type) {
    if (event.target.value !== '') {
      const date: any = new Date(event.target.value);
      const tDate: any = new Date();
      if (date === 'Invalid Date' || date === undefined) {
        if (type === 'scheduledDatePop')
          this.obj.scheduledDatePopError = MESSAGES.INVAILD_DATE_ERROR;
        if (type === 'end_date')
          this.obj.endDateError = MESSAGES.INVALID_DATE_ERROR_MESSAGE;
      } else if (date.setHours(0, 0, 0, 0) < tDate.setHours(0, 0, 0, 0)) {
        if (type === 'end_date')
          this.obj.endDateError = MESSAGES.END_DATE_INVAILD;
        if (type === 'scheduledDatePop')
          this.obj.scheduledDatePopError = MESSAGES.SCHEDULE_DATE_INVAILD;
      } else {
        this.obj.scheduledDatePopError = this.obj.endDateError = '';
      }
    }
  }

  /**
   * @method: set12AMTimeForFutureDate
   * @desc: Method using for set 12 AM time for future date.
   * param: value
   */
  set12AMTimeForFutureDate(value) {
    const time1 = moment(value).format('YYYY, MM, DD');
    const currentData = moment().format('YYYY, MM, DD');
    if (moment(time1).isAfter(moment(currentData))) {
      const finalDateTime = moment(time1 + ' ' + '12:00 AM').format(
        'MM/DD/YYYY h:mm A'
      );
      this.pushNotificationform.patchValue({
        scheduledTime: new Date(finalDateTime),
      });
    } else {
      const currentDate = moment(value).format('YYYY, MM, DD');
      const time = moment().format('h:mm A');
      const finalDateTime = moment(currentDate + ' ' + time).format(
        'MM/DD/YYYY h:mm A'
      );
      this.pushNotificationform.patchValue({
        scheduledTime: new Date(finalDateTime),
      });
    }

    if (
      this.pushNotificationform.controls.recurring_type.value &&
      this.pushNotificationform.controls.recurring_type.value === 'months'
    ) {
      this.getWeekFromDateForReoccurence(
        this.pushNotificationform.controls.scheduledDatePop.value
      );
    }
  }

  /**
   * @method: updateIsDraft
   * @desc: Method using for update push status save as Draft
   * param: value
   */
  updateIsDraft() {
    this.onSubmit('Draft');
  }

  /**
   * @method: getSuperPushSettings
   * @desc: Method using get super push selected followers/tag/mylist settings
   */
  getSuperPushSettings(event) {
    this.superPushSetting = event;
    if (
      this.superPushSetting &&
      !this.superPushSetting.all_follower &&
      this.superPushSetting.followers.length > 0
    )
      this.followersErrors = '';
  }

  /**
   * @method: getPrefilledDataForPush
   * @desc: Get data to set in Super push session
   */
  getPrefilledDataForPush() {
    this.pushNotificationform.patchValue({
      address: this.singlePush['address'] || '',
      external_Link: this.singlePush['external_Link'] || '',
    });
    this.singlePush.all_follower
      ? (this.preSelectedDataForSuperPush['all_follower'] = 'publishToAll')
      : (this.preSelectedDataForSuperPush['all_follower'] = 'publishToSubset');
    if (this.preSelectedDataForSuperPush['all_follower'] != 'publishToAll') {
      if (this.singlePush.tags.length > 0) {
        this.preSelectedDataForSuperPush['preSelectedTagIds'] = _.pluck(
          this.singlePush.tags,
          'id'
        );
        this.preSelectedDataForSuperPush['preSelectedTags'] =
          this.singlePush.tags;
      }
      if (this.singlePush.follower_groups.length > 0) {
        this.preSelectedDataForSuperPush['preSelectedMyListsIds'] = _.pluck(
          this.singlePush.follower_groups,
          'id'
        );
        this.preSelectedDataForSuperPush['preSelectedMyLists'] =
          this.singlePush.follower_groups;
      }
      if (this.singlePush.followers.length > 0) {
        this.preSelectedDataForSuperPush['preSelectedMyPeopleIds'] = _.pluck(
          this.singlePush.followers,
          'user'
        );
        this.preSelectedDataForSuperPush['preSelectedMyPeople'] =
          this.singlePush.followers;
      }
    }
  }

  /**
   * @method: clearAddress
   * @desc: Method using for clear search objects when user clear the address input fields.
   * @param event
   */
  clearAddress(event) {
    let pc = document.getElementsByClassName('pac-container');
    if (pc && pc.length > 1) {
      pc[0].remove();
    }
    if (event.target.value === '') {
      this.addressObj.address = '';
      this.addressObj.longitude = this.addressObj.latitude = null;
      this.MapUrl = '';
    }
  }

  /**
   * @method:
   * @desc: set the address object from the map api
   */
  setAddressObj(place: any) {
    if (place && place.geometry && place.formatted_address) {
      this.addressObj.address = place.formatted_address;
      this.addressObj.longitude = place.geometry.location.lng();
      this.addressObj.latitude = place.geometry.location.lat();
      let setState: boolean = false;
      this.addressObj.state = '';
      this.MapUrl = place.url;

      for (const address of place.address_components) {
        if (
          address.types[0] === 'country' &&
          (address.short_name == 'US' || address.short_name == 'CA')
        ) {
          setState = true;
        }
        if (address.types[0] === 'country') {
          this.addressObj.country = address.long_name;
        }
      }

      for (const component of place.address_components) {
        const addressType = component.types[0];
        if (addressType == 'administrative_area_level_1' && setState) {
          this.addressObj.state = component['short_name'] || '';
        }
      }
    }
  }

  /**
   * @method: redirectToGoogleMap
   * @desc: Method using for to open Google map with input address .
   * @param event
   */
  redirectToGoogleMap() {
    if (this.MapUrl) {
      window.open(this.MapUrl, '_blank');
    }
  }

  /**
   * @method:
   * @desc: Set address in the payload for Direct Pushes, not herenow
   */
  checkTypeAndSetAddress(obj: any) {
    if (
      this.content.moduleType !== 'CONTENT' &&
      this.ispushNowFor !== 'hereNow' &&
      this.addressObj.latitude
    ) {
      obj = { ...obj, ...this.addressObj };
    } else {
      obj = { ...obj, address: '', latitude: null, longitude: null };
    }
    return obj;
  }

  /**
   * @method: getHereNowFormData
   * @desc: get the here now form data
   * @param data
   */
  getHereNowFormData(data: any) {
    this.hereNowData = data;
  }

  /**
   * @method:
   * @desc:
   */
  mutateResponse(data: any) {
    if (data) {
      this.goToPushesList(false, '');
      this.resetNotification();
    }
  }
  /*
   * @method: showLoaderTextForPushes
   * @desc: show the text for loader
   */
  showLoaderTextForPushes() {
    if (
      this.pushNotificationform.controls.ispushNow.value === 'yes' &&
      this.content.moduleType !== 'CONTENT'
    )
      this.helperService.showSpinnerText('Sending push notifications.');
  }

  /**
   * @method: ngOnDestroy
   */
  ngOnDestroy() {
    if (this.bsModalRef) this.bsModalRef.hide();
  }
}
