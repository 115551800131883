import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generalnumber'
})
export class GeneralnumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value > 1000) {
      let number = value / 1000;
      return number + 'k';
    } else {
      return value;
    }
  }

}
