import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitToAbbrevation'
})
export class UnitToAbbrevationPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value === 'feet') {
      return 'Ft'
    } else if(value === 'yards') {
      return 'Yd'
    } else if(value === 'miles') {
      return 'Mile'
    } else if(value === 'minute') {
      return 'Min'
    } else if(value === 'hour') {
      return 'Hr'
    } else {
      return value;
    }
  }
}
