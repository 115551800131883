import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreService, HelperService } from "@common-services";
import { ENDPOINTS, MESSAGES, ROUTES_PATH } from "@constants";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  errorStatus: boolean = false;
  errorMsg: string = "";
  returnUrl: string;
  rememberMeFlag: boolean;
  assets: object;
  resolverData: any;
  differentUrl: boolean;
  enableOTPPage: boolean = false;
  cookieValue: boolean;
  constructor(
    private route: ActivatedRoute,
    private helperService: HelperService,
    private router: Router,
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private cookiesService: CookieService,
    private ToastrService: ToastrService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.resolverData = data.loggedIn;
      this.resolverData
        ? this.helperService.showSpinner(true)
        : this.helperService.showSpinner(false);
    });
    this.renderAssets();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    this.initLoginForm();
    this.saveUserCredintials();
  }

  /**
   * @method: initLoginForm
   * @desc: Initialize the login form using Form builder
   */
  initLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      isRememberMe: new FormControl(false),
    });
  }

  /**
   * @method: saveUserCredintials
   * @desc: saveUserCredintials if rememberMe is true
   */
  saveUserCredintials() {
    let rememberMeFlag = this.cookiesService.get("isRememberMe");
    if (rememberMeFlag === "true") {
      this.cookieValue = true;
      this.loginForm.patchValue({
        email: this.cookiesService.get("email"),
        password: this.cookiesService.get("password"),
        isRememberMe: this.cookiesService.get("isRememberMe"),
      });
    }
  }

  /**
   * @method: onKey
   * @desc: Method called on keyup on email field.
   * @param event
   */
  onKey(event) {
    this.submitted = false;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * @method: login
   * @desc: Validate form, and call API to login user
   */
  login() {
    this.submitted = true;
    if (!this.loginForm.invalid) {
      this.helperService.showSpinner(true);
      this.coreService
        .post(ENDPOINTS.CMS_LOGIN, {
          username: this.loginForm.controls.email.value,
          password: this.loginForm.controls.password.value,
        })
        .subscribe(
          (res) => {
            const data = res.body.data;
            this.submitted = false;
            const orgType = data.app_id;
            if (data.token)
              this.coreService.setLocalStorage("token", data.token);
            this.cookiesService.set(
              "isRememberMe",
              this.loginForm.controls.isRememberMe.value
            );
            let rememberMeFlag = this.cookiesService.get("isRememberMe");
            if (rememberMeFlag === "true") {
              this.cookiesService.set(
                "email",
                this.loginForm.controls.email.value
              );
              this.cookiesService.set(
                "password",
                this.loginForm.controls.password.value
              );
            }
            this.coreService.setLocalStorage("userinfo", data);
            if (data.token) {
              this.redirectToWhiteLabel(orgType, data);
            } else {
              this.verify2FA(data);
            }
          },
          (error) => {
            this.helperService.showSpinner(false);
            this.ToastrService.error(MESSAGES.LOGIN_ERROR);
            this.submitted = false;
          }
        );
    } else {
      this.helperService.showSpinner(false);
    }
  }

  /**
   * @method: renderAssets
   * @desc: render icon as per the URL
   */
  renderAssets() {
    this.assets = this.helperService.renderAssets();
  }

  /**
   * @method: goToLogin
   * @desc: return back to login screen from OTP screen
   */
  goToLogin() {
    this.enableOTPPage = false;
  }

  /**
   * @method: getOrgInfo
   * @desc: get the organization information
   */
  getOrgInfo(data: any) {
    let entityId = data.organization_id;
    this.coreService.get(`${ENDPOINTS.ORGANIZATION}/${entityId}`).subscribe(
      (result) => {
        const orgData = result.body.data;
        localStorage.setItem("ORG", JSON.stringify(orgData));
        if (orgData.is_ya_admin) this.router.navigate([ROUTES_PATH.YOUR_APP]);
        else this.router.navigate([ROUTES_PATH.DASHBOARD]);
      },
      (err) => {
        this.helperService.showSpinner(false);
      }
    );
  }

  /**
   * @method: redirectToWhiteLabel
   * @desc: check the domain and redirect to white label domain
   * @param orgType
   * @param data
   */
  redirectToWhiteLabel(orgType, data) {
    this.helperService.redirectToDomain(orgType, data.token, (differentUrl) => {
      this.differentUrl = differentUrl;
    });
    if (this.differentUrl) return;
    if (data.role_id === 1) {
      this.router.navigate([ROUTES_PATH.SA_DASHBOARD]);
      this.helperService.setNotificationStatus(false);
    } else {
      this.getOrgInfo(data);
    }
  }

  /**
   * @method: verify2FA
   * @desc: open OTP page for admins
   * @param data
   */
  verify2FA(data: any) {
    if (!data.token) this.enableOTPPage = true;
  }

  /**
   * @method:LoggedInSuccess
   * @desc: event triggered when OTP verified
   * @param e
   */
  LoggedInSuccess(e: any) {
    const token = e["data"]["token"];
    let data;
    this.coreService.setLocalStorage("token", token);
    data = this.helperService.getObjectFromStorage("userinfo");
    data["token"] = token;
    const appId = this.helperService.getFieldValueFromStorage(
      "userinfo",
      "app_id"
    );
    this.redirectToWhiteLabel(appId, data);
  }

  /**
   * @method resetRememeberMe
   * @description using for reset remember after page refresh
   *
   */
  resetRememeberMe() {
    if (!this.cookieValue) {
      this.cookieValue = true;
    } else {
      this.cookieValue = false;
      this.cookiesService.delete("isRememberMe");
      this.cookiesService.delete("email");
      this.cookiesService.delete("password");
    }
  }
}
