import { Component } from '@angular/core';
import { HelperService } from '@common-services';
import { MESSAGES } from '@constants';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;
declare var google: any;
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  onlineStatus = 'ONLINE';
  isConnected = true;
  public showLoader: boolean = false;
  public showSpinner: boolean = false;
  assets: object;
  spinnerText: string = 'Loading';
  loderColor: string = "#34145a";
  constructor(
    private helperService: HelperService,
    private connectionService: ConnectionService,
    private ToastrService: ToastrService,
    private location: Location,
    private router: Router,
    private titleService: Title
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.ToastrService.warning(MESSAGES.ONLINE);
      }
      else {
        this.ToastrService.warning(MESSAGES.INTERNET_CONNECTION);
      }
    });
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.helperService.removeAllCustomLoader();          
          ( (this.assets && this.assets['appId'] === 3) && (this.router.url.indexOf('policy') > -1 || this.router.url.indexOf('terms-conditions') > -1) ) ? this.loderColor = "#951b23" : this.loderColor = "#34145a";
          window['Appcues'] && window['Appcues'].page();
          let userId, fname, lname;
          userId = this.helperService.getFieldValueFromStorage('userinfo', 'id');
          fname = this.helperService.getFieldValueFromStorage('userinfo', 'first_name');
          lname = this.helperService.getFieldValueFromStorage('userinfo', 'last_name');
          if (userId && fname) {
            window['Appcues'] && window['Appcues'].identify(
              userId,
              {
                firstName: fname,
                lastName: lname,
              }
            );
          }
        }
      });
  }

  ngOnInit() {
    this.renderFavicon();
    this.helperService.$spinnerStatus.subscribe((status) => {
      this.showSpinner = status;
    });
    this.helperService.$spinnerText.subscribe((text) => {
      this.spinnerText = text;
    });
    let roleId = this.helperService.getFieldValueFromStorage('userinfo', 'role_id');
    if (roleId == 2) {
      this.location.onUrlChange(x =>
        this.helperService.setNotificationStatus(true)
      );
    } else {
      this.helperService.setNotificationStatus(false);
    }
  }

  /**
   * @method: renderFavicon
   * @desc: render fav icon as per the URL
   */
  renderFavicon() {
    this.assets = this.helperService.renderAssets();
    const fav16: HTMLLinkElement = document.getElementById('fav_16') as HTMLLinkElement;
    const fav32: HTMLLinkElement = document.getElementById('fav_32') as HTMLLinkElement;
    const appleTouchIcon: HTMLLinkElement = document.getElementById('apple_touch_icon') as HTMLLinkElement;
    if (fav16) fav16.href = this.assets['fav_16'];
    if (fav32) fav32.href = this.assets['fav_32'];
    if (appleTouchIcon) fav16.href = this.assets['apple_touch_icon'];
    this.titleService.setTitle(this.assets['title']);
  }
}
