export const environment = {
  production: true,
  isLive: true,
  // Used for backend API end point

  // backend_server_url: "https://cms.letsallbeheard.com/api/",
  backend_server_url: 'https://cms.letsallbeheard.com/',

  // All YourApp Domain URL's
  labhDomain: 'https://hub.letsallbeheard.com', // Used for LABH App Hub server domain url
  unionstrongDomain: 'https://hub.unionstrongapp.com', // Used for Union Strong App Hub server domain url
  ibbDomain: 'https://hub.boilermakersapp.com', // Used for BolierMaker App Hub server domain url
  ladgDomain: 'http://hub.letsalldogood.com', // Used for LADG App Hub server domain url
  nysbctcDomain: 'https://hub.nysbctcapp.com', // Used for NYSBCTC App Hub server domain url
  soaDomain: 'https://hub.soalocal444.com', // Used for SOA App Hub server domain url
  mycharityDomain: 'https://hub.charityhowtoapp.com', // Used for My Discovery App Hub server domain url
  mydiscoveryDomain: 'https://hub.myddapp.com', // Used for My Discovery App Hub server domain url
  fdnyDomain: 'https://hub.fdny2507app.com', // Used for FDNY2507 App Hub server domain url
  ibewDomain: 'https://hub.ibew332app.com', // Used for IBEW332 App Hub server domain url
  ibew441Domain: 'https://hub.ibew441app.com', // Used for IBEW441 App Hub server domain url
  tahraDomain: 'https://hub.tahraapp.com', // Used for Tahra App Hub server domain url
  ibew1049Domain: 'https://hub.ibew1049app.com', // Used for IBEW1049 App Dev server domain url
  jewishdallasDomain: 'https://hub.jewishdallasapp.com', // Used for Jewish Dallas App Dev server domain url
  mebaDomain: 'https://hub.mebaunion.app', // Used for Meba App Dev server domain url
  ripleyhousingDomain: 'https://hub.ripleyha.app', // Used for Ripley Housing App Hub server domain url
  iuecwaDomain: 'https://hub.iuecwa.app', // Used for iuecwa App Hub server domain url
  ibew363Domain: 'https://hub.ibew363.app', // Used for ibew363 App Hub server domain url
  hillsboroughDomain: 'https://hub.hcta.app', // Used for hillsborough App Hub server domain url
  nasrccDomain: 'https://hub.nasrccnys.app', // Used for nasrccnys App Hub server domain url
  seiu284Domain: 'https://hub.seiu284.app', // Used for seiu284 App Hub server domain url
  teamsters577Domain: 'https://hub.teamsters577.app', // Used for  teamsters577 App Hub server domain url
  ibew777Domain: 'https://hub.ibew777.app', // Used for  ibew777 App Hub server domain url
  pcakyDomain: 'https://hub.pcaky.app', // Used for pcak App Hub server domain url
  theokeDomain: 'https://hub.theoke.app', // Used for the Oke App Hub server domain url
  nysptaDomain: 'https://hub.nyspta.app', // Used for nyspta Hub server domain url
  homes4goodDomain: 'https://hub.homes4good.app', // Used for homes4good Hub server domain url
  healthyharvestfbDomain: 'https://hub.hhfb.app', // Used for healthyharvestfb Hub server domain url
  akronmetropolitanhaDomain: 'https://hub.amha.app', // Used for  akronmetropolitanha Hub server domain url
  twu100Domain: 'https://hub.twu100.app', // Used for  twu100 Hub server domain url
  iubacDomain: 'https://hub.iubac.app', // Used for  iubac Hub server domain url
  johnstownhaDomain: 'https://hub.johnstownha.app', // Used for johnstownha Hub server domain url
  allentownhaDomain: 'https://hub.allentownha.app', // Used for allentownha Hub server domain url
  familyofficeinsightsDomain: 'https://hub.familyofficeinsights.app', // Used for familyofficeinsights Hub server domain url
  ibew1249Domain: 'https://hub.ibew1249.app', // Used for ibew1249 Hub server domain url
  ibew125Domain: 'https://hub.ibew125.app', // Used for ibew125 Hub server domain url
  clarionctyhraDomain: 'https://hub.clarionctyhra.app', // Used for clarion Hub server domain url

  // All YourApp Deeplinks
  deepLinkBaseUrl: 'https://heard.app.link/', // Deeplink used for LABH Hub server.
  unionstrongdeepLink: 'https://unionstrong.app.link/', // Deeplink used for UnionStrong Hub server.
  ibbdeepLink: 'https://ibb.app.link/', // Deeplink used for IBB Hub server.
  ladgdeepLink: 'https://good.app.link/', // Deeplink used for LADG Hub server.
  nysbctcdeepLink: 'https://nysbctc.app.link/', // Deeplink used for Nysbctc Hub server.
  soadeepLink: 'https://soa.app.link/', // Deeplink used for SOA Hub server.
  mycharitydeepLink: 'https://charityhowto.app.link/', // Deeplink used for My Charity App Hub server.
  mydiscoverydeepLink: 'https://mydiscovery.app.link/', // Deeplink used for My Discovery Hub server.
  fdnydeepLink: 'https://fdny2507.app.link/', // Deeplink used for FDNY2507 Hub server.
  ibewdeepLink: 'https://ibew332.app.link/', // Deeplink used for IBEW332 Hub server.
  ibew441deepLink: 'https://ibew441.app.link/', // Deeplink used for IBEW441 Hub server.
  tahradeepLink: 'https://tahra.app.link/', // Deeplink used for Tahra Hub server.
  ibew1049deepLink: 'https://ibew1049.app.link/', // Deeplink used for Tahra Hub server.
  jewishdallasdeepLink: 'https://jewishdallas.app.link/', // Deeplink used for Tahra Hub server.
  mebadeepLink: 'https://mebaunion.app.link/', // Deeplink used for Meba Hub server.
  ripleyhousingdeepLink: 'https://ripley.app.link/', // Deeplink used for Ripley Housing Hub server.
  iuecwadeepLink: 'https://iuecwa.app.link/', // Deeplink used for IUE-CWA Hub server.
  ibew363deepLink: 'https://ibew363.app.link/', // Deeplink used for ibew363 Hub server.
  hillsboroughdeepLink: 'https://hillsborough.app.link/', // Deeplink used for hillsborough Hub server.
  nasrccdeepLink: 'https://nasrcc.app.link/', // Deeplink used for Nasrcc Hub server.
  seiu284deepLink: 'https://seiu284.app.link/', // Deeplink used for seiu284 Hub server.
  teamsters577deepLink: 'https://teamsters577.app.link/', // Deeplink used for teamsters577 Hub server.
  ibew777deepLink: 'https://ibew777.app.link/', // Deeplink used for ibew777 Hub server.
  preventcakdeepLink: 'https://preventcak.app.link/', // Deeplink used for ibew777 Hub server.
  theokedeepLink: 'https://theoke.app.link/', // Deeplink used for the oke Hub server.
  nysptadeepLink: 'https://nyspta.app.link/', // Deeplink used for nyspta Hub server.
  homes4gooddeepLink: 'https://homes4good.app.link/', // Deeplink used for homes4good Hub server.
  healthyharvestfbdeepLink: 'https://healthyharvestfb.app.link/', // Deeplink used for healthyharvestfb Hub server.
  akronmetropolitanhadeepLink: 'https://akronmetropolitanha.app.link/', // Deeplink akronmetropolitanha Hub server domain url
  twu100deepLink: 'https://twu100.app.link/', // Deeplink twu100 Hub server domain url
  iubacdeepLink: 'https://iubac.app.link/', // Deeplink iubac Hub server domain url
  johnstownhadeepLink: 'https://johnstownha.app.link/', // Deeplink johnstownha Hub server domain url
  allentownhadeepLink: 'https://allentownha.app.link/', // Deeplink for allentownha Hub server domain url
  familyofficeinsightsdeepLink: 'https://familyofficeinsights.app.link/', // Deeplink for familyofficeinsights Hub server domain url
  ibew1249deepLink: 'https://ibew1249.app.link/', // Deeplink for ibew1249 Hub server domain url
  ibew125deepLink: 'https://ibew125.app.link/', // Deeplink for ibew125 Hub server domain url
  clarionctyhradeepLink: 'https://clarionctyhra.app.link/', // Deeplink for clarion Hub server domain url
};
