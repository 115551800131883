import { Component, OnInit, Input, Output, EventEmitter, DoCheck } from '@angular/core';
import { IPaginationConfig } from '../../interfaces/shared.interface';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.css']
})
export class CustomPaginationComponent implements OnInit, DoCheck {
  @Output() pageChanged = new EventEmitter();
  @Input() config: IPaginationConfig;
  totalPages: number;
  pagesToDraw: Array<number>;

  constructor() { }

  ngOnInit() {       
  }

  ngDoCheck() {
    this.totalPages = Math.ceil(this.config['totalPages'] / this.config['itemPerPage']);
    this.totalPages ? this.pagesToDraw = Array.from(Array(this.totalPages), (_, i) => i + 1) : this.pagesToDraw = [];    
  }


  /**
   * @method: pageChange
   * @desc: emit on page change
   * @param e 
   */
  pageChange(e: any) {    
    this.pageChanged.emit(e);
  }

}
