import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
//Interceptor
import { JwtInterceptor } from '../../../interceptor/app.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModalModule, BsModalRef } from 'ngx-bootstrap';
import { CropperComponent } from '../../components/cropper/cropper.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PushNotificationModalComponent } from '../../components/push-notification-modal/push-notification-modal.component';
import { YourAppModalComponent } from '../../components/your-app-modal/your-app-modal.component';
import { MultiselectComponent } from '../../components/multiselect/multiselect.component';
import { AbsPipe } from '../../filters/abs.pipe';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { OrderByPipe } from '../../filters/orderby.pipe';
import { SoryByFieldDirective } from '../../directives/sory-by-field.directive';
import { MediaLibraryComponent } from '../../components/media-library/media-library.component';
import { Ng2PicaModule } from 'ng2-pica'; // <-- import the module
import { CreateForceMultiplierComponent } from '../../components/create-force-multiplier/create-force-multiplier.component';
import { ForceMultiplierConfirmationComponent } from '../../components/force-multiplier-confirmation/force-multiplier-confirmation.component';
import { MetricsPopupComponent } from '../../components/metrics-popup/metrics-popup.component';
import { MessagePanelComponent } from '../../components/message-panel/message-panel.component';
import { CustomImageResizerComponent } from '../../components/custom-image-resizer/custom-image-resizer.component';
import { FiltersComponent } from '../../../shared/components/filters/filters.component';
import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { PlusorminusPipe } from '../../../shared/filters/plusorminus.pipe';
import { GeneralnumberPipe } from '../../../shared/filters/generalnumber.pipe';
import { PeopleViewComponent } from '../../components/people-view/people-view.component';
import { SuperPushComponent } from '../../components/super-push/super-push.component';
import { CustomPaginationComponent } from '../../../shared/components/custom-pagination/custom-pagination.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
const maskConfig: Partial<IConfig> = {
  validation: false,
};
import { TwoFactorComponent } from '../../components/two-factor/two-factor.component';
import { HereNowFormComponent } from '../../../shared/components/here-now-form/here-now-form.component';
import { UnitToAbbrevationPipe } from '../../../shared/filters/unit-to-abbrevation.pipe';
import { from } from 'rxjs';

@NgModule({
  declarations: [
    CropperComponent,
    PushNotificationModalComponent,
    YourAppModalComponent,
    MultiselectComponent,
    AbsPipe,
    OrderByPipe,
    SoryByFieldDirective,
    MediaLibraryComponent,
    CreateForceMultiplierComponent,
    ForceMultiplierConfirmationComponent,
    MetricsPopupComponent,
    MessagePanelComponent,
    CustomImageResizerComponent,
    FiltersComponent,
    ConfirmationModalComponent,
    PlusorminusPipe,
    GeneralnumberPipe,
    PeopleViewComponent,
    SuperPushComponent,
    CustomPaginationComponent,
    TwoFactorComponent,
    HereNowFormComponent,
    UnitToAbbrevationPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    ImageCropperModule,
    GooglePlaceModule,
    Ng2PicaModule,
    ChartsModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  exports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    ModalModule,
    CropperComponent,
    PushNotificationModalComponent,
    YourAppModalComponent,
    CreateForceMultiplierComponent,
    MultiselectComponent,
    AbsPipe,
    OrderByPipe,
    GooglePlaceModule,
    SoryByFieldDirective,
    MediaLibraryComponent,
    Ng2PicaModule,
    ForceMultiplierConfirmationComponent,
    MetricsPopupComponent,
    MessagePanelComponent,
    CustomImageResizerComponent,
    FiltersComponent,
    ConfirmationModalComponent,
    ChartsModule,
    PlusorminusPipe,
    GeneralnumberPipe,
    SuperPushComponent,
    CustomPaginationComponent,
    NgxMaskModule,
    TwoFactorComponent,
    HereNowFormComponent,
    UnitToAbbrevationPipe
  ],
  providers: [
    BsModalRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ThemeService
  ],
  entryComponents: [TwoFactorComponent, PushNotificationModalComponent,YourAppModalComponent, MessagePanelComponent, CropperComponent, MediaLibraryComponent, CustomImageResizerComponent, ConfirmationModalComponent, PeopleViewComponent, HereNowFormComponent]
})

export class CoreModule {
  constructor() {
  }
}
