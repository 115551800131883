import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ENDPOINTS } from '@constants';
import { CoreService, HelperService } from '@common-services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { _ } from 'underscore';
import { PeopleViewComponent } from '../people-view/people-view.component';
import { IPreFilledData, IFollowersTags, IMyLists, IMyPeople } from './super-push.interface';
import { IPaginationConfig } from '../../interfaces/shared.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-super-push',
  templateUrl: './super-push.component.html',
  styleUrls: ['./super-push.component.css']
})
export class SuperPushComponent implements OnInit {
  orgId: number;
  isGenericOrg: boolean = false;
  @Input() type: any = '';
  @Input() status: any = '';
  @Input() actionType: any = ''
  public preSelectedData: IPreFilledData;
  @Input() followersErrors: string;
  @Output() selectedSuperPushSettings = new EventEmitter();
  searchFollowers: string = '';
  followersPageNumber: number = 1;
  followersSortConf: any;
  followersList: Array<IMyPeople>;
  followersTotalPages: number;
  followersPaginationLegend: string;

  searchMyList: string = '';
  myListPageNumber: number = 1;
  myListSortConf: any;
  myListData: Array<IMyLists>;
  myListTotalPages: number;
  myListPaginationLegend: string;

  searchFollowersTags: string = '';
  followersTagsPageNumber: number = 1;
  followersTagsSortConf: any;
  followersTagsData: Array<IFollowersTags>;
  followersTagsTotalPages: number;
  followersTagsPaginationLegend: string;

  viewMoreData: Array<any> = [];
  modalRef: BsModalRef;
  openViewAllSelectedData: boolean = false;
  selectedDataForModal: Array<any> = [];

  checkedFollowersTags: Array<any> = [];
  pageWiseAllFollowersTags: Array<any> = [];
  allFollowersTagsChecked: boolean = false;
  checkedFollowersTagsObj: Array<any> = [];

  checkedMyLists: Array<any> = [];
  pageWiseAllMyLists: Array<any> = [];
  allMyListsChecked: boolean = false;
  checkedMyListsObj: Array<any> = [];

  checkedMyPeople: Array<any> = [];
  pageWiseAllMyPeople: Array<any> = [];
  allMyPeopleChecked: boolean = false;
  checkedMyPeopleTagsObj: Array<any> = [];
  disableSelectAllForMyPeople: boolean = false;
  preSelectedMyPeople: Array<any> = [];
  preSelectedMyPeopleIds: Array<number> = [];

  totalCountMyPeople: number = 0;

  superPushSelection: string = "publishToAll";

  preSelectedTags: Array<any> = [];
  preSelectedTagIds: Array<number> = [];
  disableSelectAllForTags: boolean = false;

  preSelectedMyLists: Array<any> = [];
  preSelectedMyListsIds: Array<number> = [];
  disableSelectAllForMyLists: boolean = false;

  idOfSelectedUsersFromTagsLists: Array<number> = [];
  myPeopleAction: boolean = false;
  myListAction: boolean = false;
  tagAction: boolean = false;

  tagsOnPageLoad: boolean = false;
  myListsOnPageLoad: boolean = false;
  myPeopleOnPageLoad: boolean = false;

  myPeoplePaginationConfig: IPaginationConfig = {
    "id": 'myPeopleSuperPush',
    "totalPages": 0,
    "itemPerPage": 10
  }

  myListPaginationConfig: IPaginationConfig = {
    "id": 'myListSuperPush',
    "totalPages": 0,
    "itemPerPage": 10
  }

  followTagsPaginationConfig: IPaginationConfig = {
    "id": 'myFollowersTagsSuperPush',
    "totalPages": 0,
    "itemPerPage": 10
  }
  _selectedDataForSuperpush = new BehaviorSubject([]);

  onloadFollowerTagDataLength = 0;
  isFollowerTagLoaded = false;
  onloadFollowerListDataLength = 0;
  isFollowerListLoaded = false;
  onloadListDataLength = 0;
  isListDataLoaded = false;

  @Input()
  set setSelectedDataForSuperpush(value: any) {
    this._selectedDataForSuperpush.next(value);
  }
  constructor(
    private coreService: CoreService,
    private helperService: HelperService,
    private modalService: BsModalService,
  ) {
    this.orgId = this.helperService.getFieldValueFromStorage('ORG', 'id');
  }

  ngOnInit() {
    this.isGenericOrg = this.helperService.getFieldValueFromStorage('ORG', 'is_generic');
    this._selectedDataForSuperpush.subscribe((res) => {
      if (!res) return;
      let data: IPreFilledData = {
        all_follower: res['all_follower'],
        preSelectedMyLists: res['preSelectedMyLists'],
        preSelectedMyListsIds: res['preSelectedMyListsIds'],
        preSelectedMyPeople: res['preSelectedMyPeople'],
        preSelectedMyPeopleIds: res['preSelectedMyPeopleIds'],
        preSelectedTagIds: res['preSelectedTagIds'],
        preSelectedTags: res['preSelectedTags']
      };
      this.preSelectedData = data;
      this.setPreFilledData();
    });
    if (this.type === "ADD") return false;
    if (this.type === 'PUSHNOTIFCATION') this.tagsOnPageLoad = this.myListsOnPageLoad = this.myPeopleOnPageLoad = true;
    this.getFollowersTags();
    this.getMyLists();
    this.getMyPeople();
    this.helperService.$getFollowersDetails.subscribe((data) => {
      if (data) {
        this.preSelectedData = data;
        this.setPreFilledData();
      }
    });
  }


  /**
   * @method: getFollowersTags
   * @desc: Get followers Tags
   */
  getFollowersTags() {
    this.tagAction = true;
    if (this.type === 'PUSHNOTIFCATION' && this.tagsOnPageLoad) this.helperService.showLoaderWithId('loader-superpush-tags');
    this.coreService.get(`${ENDPOINTS.TAGS}?organization_id=${this.orgId}&search=${encodeURIComponent(this.searchFollowersTags.trim())}`, {
      page: this.followersTagsPageNumber,
      ...this.followersTagsSortConf
    }).subscribe(result => {
      if (this.type === 'PUSHNOTIFCATION' && this.tagsOnPageLoad) this.helperService.hideLoaderWithId('loader-superpush-tags');
      this.handleFollowerTagsResponse(result.body);
      this.tagAction = false;
      this.tagsOnPageLoad = false;
    }, (error) => {
      if (this.type === 'PUSHNOTIFCATION' && this.tagsOnPageLoad) this.helperService.hideLoaderWithId('loader-superpush-tags');
      this.tagAction = false;
      this.tagsOnPageLoad = false;
    });
  }

  /**
   * @method: handleFollowerTagsResponse
   * @desc: Handle the data for Followers Tags
   * @param data 
   */
  handleFollowerTagsResponse(data: any) {
    this.followersTagsData = data.data;
    this.followersTagsTotalPages = data.count || 0;
    this.followTagsPaginationConfig.totalPages = data.count || 0;
    this.onloadFollowerTagDataLength = this.isFollowerTagLoaded ? this.onloadFollowerTagDataLength : (data.count || 0);
    this.isFollowerTagLoaded = true;
    this.getLegend('myFollowersTags');
    this.checkAllCheckedOnPage('myFollowersTags');
  }

  /**
   * @method: getMyLists
   * @desc: Get My Lists
   */
  getMyLists() {
    if (this.type === 'PUSHNOTIFCATION' && this.myListsOnPageLoad) this.helperService.showLoaderWithId('loader-superpush-mylists');
    const URL = `${ENDPOINTS.FOLLOWER_GROUP}?organization_id=${this.orgId}&search=${encodeURIComponent(this.searchMyList.trim())}&assigned=true`;
    this.myListAction = true;
    this.coreService.get(URL, {
      page: this.myListPageNumber,
      ...this.myListSortConf
    }).subscribe((result) => {
      if (this.type === 'PUSHNOTIFCATION' && this.myListsOnPageLoad) this.helperService.hideLoaderWithId('loader-superpush-mylists');
      this.myListsOnPageLoad = false;
      this.myListAction = false;
      this.handleMyListResponse(result.body);
    }, (err) => {
      if (this.type === 'PUSHNOTIFCATION' && this.myListsOnPageLoad) this.helperService.hideLoaderWithId('loader-superpush-mylists');
      this.myListAction = false;
      this.myListsOnPageLoad = false;
    });
  }

  /**
   * @method: handleMyListResponse
   * @desc: Handle my list response
   * @param data is list of followers data
   */
  handleMyListResponse(data: any) {
    this.myListData = data.data;
    this.myListTotalPages = data.count || 0;
    this.myListPaginationConfig.totalPages = data.count || 0;
    this.onloadListDataLength = this.isListDataLoaded ? this.onloadListDataLength : (data.count || 0);
    this.isListDataLoaded = true;
    this.getLegend('myList');
    this.checkAllCheckedOnPage('myList');
  }

  /**
   * @method: getMyPeople
   * @desc: Get My people list
   */
  getMyPeople() {
    if (this.type === 'PUSHNOTIFCATION' && this.myPeopleOnPageLoad) this.helperService.showLoaderWithId('loader-superpush-mypeople');
    let URL = `${ENDPOINTS.ORGANIZATION_FOLLOWERS}?organization_id=${this.orgId}&assigned=true&search=${encodeURIComponent(this.searchFollowers.trim())}`;
    this.myPeopleAction = true;
    this.coreService.get(URL, {
      page: this.followersPageNumber,
      ...this.followersSortConf
    }).subscribe((result) => {
      if (this.type === 'PUSHNOTIFCATION' && this.myPeopleOnPageLoad) this.helperService.hideLoaderWithId('loader-superpush-mypeople');
      this.myPeopleAction = false;
      this.myPeopleOnPageLoad = false;
      this.handleMyPeopleResponse(result.body);
    }, (err) => {
      if (this.type === 'PUSHNOTIFCATION' && this.myPeopleOnPageLoad) this.helperService.hideLoaderWithId('loader-superpush-mypeople');
      this.myPeopleAction = false;
      this.myPeopleOnPageLoad = false;
    });
  }

  /**
   * @method: handleMyPeopleResponse
   * @desc: Handle My People list response
   */
  handleMyPeopleResponse(data: any) {
    this.followersList = data.data;
    this.followersTotalPages = data.count || 0;
    this.myPeoplePaginationConfig.totalPages = data.count;
    this.onloadFollowerListDataLength = this.isFollowerListLoaded ? this.onloadFollowerListDataLength : (data.count || 0) ;
    this.isFollowerListLoaded = true;
    this.getLegend('myPeople');
    this.checkAllCheckedOnPage('myPeople');
  }

  /**
   * @method: setPreFilledData
   * @desc: set pre filled data
   */
  setPreFilledData() {
    if(!this.preSelectedData.all_follower) return;
    this.preSelectedData['all_follower'] == '' ? this.superPushSelection = 'publishToAll' : this.superPushSelection = this.preSelectedData['all_follower'];
    this.preSelectedTagIds = [...this.preSelectedData.preSelectedTagIds];
    this.checkedFollowersTags = [...this.preSelectedTagIds];
    this.preSelectedTags = [...this.preSelectedData.preSelectedTags];
    this.checkedFollowersTagsObj = [...this.preSelectedTags];
    this.preSelectedMyListsIds = [...this.preSelectedData.preSelectedMyListsIds];
    this.checkedMyLists = [...this.preSelectedMyListsIds];
    this.preSelectedMyLists = [...this.preSelectedData.preSelectedMyLists];
    this.checkedMyListsObj = [...this.preSelectedMyLists];
    this.preSelectedMyPeopleIds = [...this.preSelectedData.preSelectedMyPeopleIds];
    this.checkedMyPeople = [...this.preSelectedMyPeopleIds];
    this.preSelectedMyPeople = [...this.preSelectedData.preSelectedMyPeople];
    // Filter my people list data for selected state.
    if (this.preSelectedMyPeople.length > 0) {
      this.preSelectedMyPeople.forEach(list => {
        this.handleFollowersObject(list);
      });
      this.checkedMyPeople = _.uniq(this.checkedMyPeople);
      this.checkedMyPeopleTagsObj = _.uniq(this.checkedMyPeopleTagsObj, (list) => {
        return list.id;
      });
    }
    this.checkAllCheckedOnPage('myPeople');
    this.checkAllCheckedOnPage('myList');
    this.checkAllCheckedOnPage('myFollowersTags');
    this.checkMyPeopleAsSelection();
  }

  handleFollowersObject(list) {
    this.checkedMyPeople.push(list.user);
    let newObj = { ...list, id: list['user'] };
    this.checkedMyPeopleTagsObj.push(newObj);
  }

  /**
  * @method: getLegend
  * @desc: Get the legend for pagination.
  */
  getLegend(type: string) {
    if (type === 'myPeople') {
      this.followersPaginationLegend = this.helperService.legend(this.followersPageNumber, this.followersTotalPages, null, 'theme2');
    } else if (type === 'myList') {
      this.myListPaginationLegend = this.helperService.legend(this.myListPageNumber, this.myListTotalPages, null, 'theme2');
    } else if (type === 'myFollowersTags') {
      this.followersTagsPaginationLegend = this.helperService.legend(this.followersTagsPageNumber, this.followersTagsTotalPages, null, 'theme2');
    }
  }

  /**
   * @method: sorting
   * @desc: Apply sorting on followers list
   * @param data
   */
  sorting(e: any, type: string) {
    if (type === 'myPeople') {
      this.followersPageNumber = 1;
      this.followersSortConf = e;
      this.getMyPeople();
    } else if (type === 'myList') {
      this.myListPageNumber = 1;
      this.myListSortConf = e;
      this.getMyLists();
    } else if (type === 'myFollowersTags') {
      this.followersTagsPageNumber = 1;
      this.followersTagsSortConf = e;
      this.getFollowersTags();
    }
  }

  /*
  * @method: isSortAscDesc
  * @desc: sorting the Followers list for ASC/DESC order
  * @param e
  */
  isSortAscDesc(val: any, type: string, order: string) {
    if (type === 'myPeople') {
      if (!this.followersSortConf) return;
      return this.followersSortConf.sort_by === val && this.followersSortConf.order_by === order;
    } else if (type === 'myList') {
      if (!this.myListSortConf) return;
      return this.myListSortConf.sort_by === val && this.myListSortConf.order_by === order;
    } else if (type === 'myFollowersTags') {
      if (!this.followersTagsSortConf) return;
      return this.followersTagsSortConf.sort_by === val && this.followersTagsSortConf.order_by === order;
    }
  }

  /**
   * @method: resetSearch
   * @desc: Reset search filter
   * @param type 
   */
  resetSearch(type: string) {
    if (type === 'myPeople') {
      this.searchFollowers = "";
      this.followersPageNumber = 1;
      this.getMyPeople();
    } else if (type === 'myList') {
      this.searchMyList = "";
      this.myListPageNumber = 1;
      this.getMyLists();
    } else if (type === 'myFollowersTags') {
      this.searchFollowersTags = null;
      this.followersTagsPageNumber = 1;
      this.getFollowersTags();
    }
  }

  /**
   * @method: onSearch
   * @desc: Filter the followers list
   * @param e is event
   */
  onSearch(type: string) {
    if (type === 'myPeople') {
      this.followersPageNumber = 1;
      this.getMyPeople();
    } else if (type === 'myList') {
      this.myListPageNumber = 1;
      this.getMyLists();
    } else if (type === 'myFollowersTags') {
      this.followersTagsPageNumber = 1;
      this.getFollowersTags();
    }
  }

  /**
  * @method: isEmptyFollowersSearch
  * @desc: Method using for clear search on keyup
  * @param search
  */
  isEmptyFollowersSearch(e: any, type: string) {
    if (type === 'myPeople') {
      if (this.searchFollowers === '' && e.key !== 'Enter') this.getMyPeople();
    } else if (type === 'myList') {
      if (this.searchMyList === '' && e.key !== 'Enter') this.getMyLists();
    } else if (type === 'myFollowersTags') {
      if (this.searchFollowersTags === '' && e.key !== 'Enter') this.getFollowersTags();
    }
  }

  /**
   * @method: pageChange
   * @desc: Get the new followers list as per the pagination
   * @param e is event
   */
  pageChange(e: any, type: string) {
    if (type === 'myPeople') {
      this.followersPageNumber = e;
      this.getMyPeople();
    } else if (type === 'myList') {
      this.myListPageNumber = e;
      this.getMyLists();
    } else if (type === 'myFollowersTags') {
      this.followersTagsPageNumber = e;
      this.getFollowersTags();
    }
  }

  /**
   * @method: viewMore
   * @desc: show more data on modal
   */
  viewMore(item, type) {
    if (type === 'myList' || type === 'myFollowersTags') {
      this.viewMoreData = _.pluck(item.members, 'name');
    }
    if (this.viewMoreData && this.viewMoreData.length === 0) return;
    this.openViewAllSelectedData = false;
    const contentData = { name: "Users", nameList: this.viewMoreData }
    this.showModal(contentData);
  }

  /**
   * @method: viewAllSelected
   * @desc: view all selected in the modal
   */
  viewAllSelected(type: string) {
    let modalHeading = "";
    if (type === 'Selected MyPeople') {
      this.selectedDataForModal = _.pluck(this.checkedMyPeopleTagsObj, 'name');
      modalHeading = 'Selected My People';
    } else if (type === 'myList') {
      this.selectedDataForModal = _.pluck(this.checkedMyListsObj, 'name');
      modalHeading = 'Selected My Lists';
    } else if (type === 'myFollowersTags') {
      this.selectedDataForModal = _.pluck(this.checkedFollowersTagsObj, 'name');
      modalHeading = 'Selected Follower Tags';
    }
    if (this.selectedDataForModal && this.selectedDataForModal.length === 0) return;
    this.openViewAllSelectedData = true;
    const contentData = { name: modalHeading, nameList: this.selectedDataForModal }
    this.showModal(contentData);
  }

  /**
   * @method: Show data on modal
   * @desc: showModal
   */
  showModal(modalData: object) {
    this.modalRef = this.modalService.show(PeopleViewComponent,
      {
        initialState: {
          content: modalData
        },
        class: 'custom-dialog w-560-box'
      });
  }

  /**
   * @method: checkFollowerTags
   * @param: tag id
   * @desc: check a specific id
   */
  checkFollowerTags(tag: object) {
    const index = this.checkedFollowersTags.indexOf(tag['id']);
    if (index > -1 && index !== undefined) {
      this.checkedFollowersTags.splice(index, 1);
      this.checkedFollowersTagsObj.splice(index, 1);
    } else {
      this.checkedFollowersTags.push(tag['id']);
      this.checkedFollowersTagsObj.push(tag);
    }
    this.checkAllCheckedOnPage('myFollowersTags');

  }

  /**
   * @method: selectAllFollowerTags
   * @param: event
   * @desc: for selecting all checked on a page
   */
  selectAllFollowerTags(event) {
    this.allFollowersTagsChecked = event;
    if (event) {
      this.followersTagsData.forEach(tag => {
        this.checkedFollowersTags.push(tag.id);
        this.checkedFollowersTagsObj.push(tag);
      });
    } else {
      this.followersTagsData.forEach(tag => {
        const index = this.checkedFollowersTags.indexOf(tag.id);
        this.checkedFollowersTags.splice(index, 1);
        this.checkedFollowersTagsObj.splice(index, 1);
      });
      if (this.actionType == 'EDIT') {
        this.resetFollowerTags();
      }

    }
    this.checkedFollowersTags = _.uniq(this.checkedFollowersTags);
    this.checkedFollowersTagsObj = _.uniq(this.checkedFollowersTagsObj, (tag) => {
      return tag.id;
    });
    this.checkMyPeopleAsSelection();
  }

  /**
  * @method: checkAllCheckedOnPage
  * @desc: for checking if all checked on page
  */
  checkAllCheckedOnPage(type: string) {
    if (type === 'myPeople') {
      this.pageWiseAllMyPeople = _.pluck(this.followersList, 'user');
      if ((this.checkedMyPeople.length >= this.pageWiseAllMyPeople.length) && this.pageWiseAllMyPeople.length > 0) {
        const checker = (arr, target) => target.every(v => arr.includes(v));
        this.allMyPeopleChecked = checker(this.checkedMyPeople, this.pageWiseAllMyPeople);
      } else {
        this.allMyPeopleChecked = false;
      }
    } else if (type === 'myList') {
      this.pageWiseAllMyLists = _.pluck(this.myListData, 'id');
      if ((this.checkedMyLists.length >= this.pageWiseAllMyLists.length) && this.pageWiseAllMyLists.length > 0) {
        const checker = (arr, target) => target.every(v => arr.includes(v));
        this.allMyListsChecked = checker(this.checkedMyLists, this.pageWiseAllMyLists);
      } else {
        this.allMyListsChecked = false;
      }
      this.checkMyPeopleAsSelection();
    } else if (type === 'myFollowersTags') {
      this.pageWiseAllFollowersTags = _.pluck(this.followersTagsData, 'id');
      if ((this.checkedFollowersTags.length >= this.pageWiseAllFollowersTags.length) && this.pageWiseAllFollowersTags.length > 0) {
        const checker = (arr, target) => target.every(v => arr.includes(v));
        this.allFollowersTagsChecked = checker(this.checkedFollowersTags, this.pageWiseAllFollowersTags);
      } else {
        this.allFollowersTagsChecked = false;
      }
      this.checkMyPeopleAsSelection();
    }

  }

  /**
   * @method: checkFollowerTags
   * @param: tag id
   * @desc: check a specific id
   */
  checkMyLists(item: object) {
    const index = this.checkedMyLists.indexOf(item['id']);
    if (index > -1 && index !== undefined) {
      this.checkedMyLists.splice(index, 1);
      this.checkedMyListsObj.splice(index, 1);
    } else {
      this.checkedMyLists.push(item['id']);
      this.checkedMyListsObj.push(item);
    }
    this.checkAllCheckedOnPage('myList');
  }

  /**
   * @method: selectAllMyLists
   * @param: event
   * @desc: for selecting all checked on a page
   */
  selectAllMyLists(event) {
    this.allMyListsChecked = event;
    if (event) {
      this.myListData.forEach(list => {
        this.checkedMyLists.push(list.id);
        this.checkedMyListsObj.push(list);
      });
    } else {
      this.myListData.forEach(list => {
        const index = this.checkedMyLists.indexOf(list.id);
        this.checkedMyLists.splice(index, 1);
        this.checkedMyListsObj.splice(index, 1);
      });
      if (this.actionType == 'EDIT') {
        this.resetMyLists();
      }
    }
    this.checkedMyLists = _.uniq(this.checkedMyLists);
    this.checkedMyListsObj = _.uniq(this.checkedMyListsObj, (item) => {
      return item.id;
    });
    this.checkMyPeopleAsSelection();
  }

  /**
   * @method: checkMyPeople
   * @param: people id
   * @desc: check a specific id
   */
  checkMyPeople(follower: object) {
    const index = this.checkedMyPeople.indexOf(follower['user']);
    if (index > -1 && index !== undefined) {
      this.checkedMyPeople.splice(index, 1);
      const newIndex = this.checkedMyPeople.indexOf(follower['user']);
      this.checkedMyPeopleTagsObj.splice(newIndex, 1);
    } else {
      this.checkedMyPeople.push(follower['user']);
      let newObj = { ...follower, id: follower['user'] };
      this.checkedMyPeopleTagsObj.push(newObj);
    }
    this.checkAllCheckedOnPage('myPeople');
    this.totalCountMyPeople = this.checkedMyPeopleTagsObj.length;
    this.sendSelectedMembers();
  }

  /**
   * @method: selectAllMyPeople
   * @param: event
   * @desc: for selecting all checked on a page
   */
  selectAllMyPeople(event) {
    this.allMyPeopleChecked = event;
    if (event) {
      this.followersList.forEach(list => {
        this.handleFollowersObject(list);
      });
    } else {
      this.followersList.forEach(list => {
        const index = this.checkedMyPeople.indexOf(list.user);
        if (index > -1 && index !== undefined) {
          this.checkedMyPeople.splice(index, 1);
          const newIndex = this.checkedMyPeopleTagsObj.findIndex((x) => x.id == list['user']);
          this.checkedMyPeopleTagsObj.splice(newIndex, 1);
        }
      });

      if (this.actionType == 'EDIT') {
        this.resetMyPeople();
      }
    }
    this.checkedMyPeople = _.uniq(this.checkedMyPeople);
    this.checkedMyPeopleTagsObj = _.uniq(this.checkedMyPeopleTagsObj, (list) => {
      return list.user || list.id;
    });
    this.totalCountMyPeople = this.checkedMyPeopleTagsObj.length;
    this.sendSelectedMembers();
    if (this.checkedFollowersTagsObj.length > 0 || this.checkedMyListsObj.length > 0) {
      this.checkMyPeopleAsSelection();
    }
  }

  /**
   * @method: checkMyPeopleAsSelection
   * @desc: Check the Mypeople as per the selection on My List and Tags
   */
  checkMyPeopleAsSelection() {
    let checkedTagsMembers, checkedMyListMenbers, checkedTagsMembersUnionArray, checkedMyListMenbersUnionArray, memberCollection;
    checkedTagsMembers = _.pluck(this.checkedFollowersTagsObj, 'members');
    checkedTagsMembersUnionArray = _.union.apply(_, checkedTagsMembers);
    checkedMyListMenbers = _.pluck(this.checkedMyListsObj, 'members');
    checkedMyListMenbersUnionArray = _.union.apply(_, checkedMyListMenbers);
    memberCollection = [...checkedTagsMembersUnionArray, ...checkedMyListMenbersUnionArray];
    memberCollection = _.uniq(memberCollection, (list) => {
      return list.id;
    });
    this.mergeMembersWithMyPeople(memberCollection);
  }

  /**
   * @method: mergeMembersWithMyPeople
   * @desc: Merge selected users from tags and my lists into the my people
   * @param collection
   */
  mergeMembersWithMyPeople(collection) {
    let getSelectFollowers;
    this.idOfSelectedUsersFromTagsLists = _.pluck(collection, 'id');
    getSelectFollowers = [...collection, ...this.checkedMyPeopleTagsObj];
    this.checkedMyPeopleTagsObj = _.uniq(getSelectFollowers, (list) => {
      return list.id;
    });

    this.updateAddedBy();
    this.sendSelectedMembers();
  }

  /**
   * @method: updateAddedBy
   * @desc: update my people added by
   */
  updateAddedBy() {
    if (this.checkedMyPeopleTagsObj.length == 0) {
      this.totalCountMyPeople = 0;
      return;
    }

    let copyMypeopleObj = [...this.checkedMyPeopleTagsObj];
    copyMypeopleObj.forEach((element, index) => {
      const tempIndex = this.idOfSelectedUsersFromTagsLists.findIndex((x) => x == element.id);
      const IndexOne = this.checkedMyPeople.findIndex((x) => x == element.id);
      if (tempIndex == -1 && IndexOne == -1) {
        const newIndex = this.checkedMyPeopleTagsObj.findIndex((x) => x.id == element.id);
        this.checkedMyPeopleTagsObj.splice(newIndex, 1);
      }
    });
    this.totalCountMyPeople = this.checkedMyPeopleTagsObj.length;
  }


  /**
   * @method: changeSuperpush
   * @desc: on change the selection
   * @param e 
   */
  changeSuperpush(e: any) {
    this.sendSelectedMembers();
  }

  /**
   * @method: resetFollowerTags
   * @desc: Reset the followers
   */
  resetFollowerTags() {
    if (this.status !== 'Draft') {
      this.checkedFollowersTags = [...this.preSelectedTagIds];
      this.checkedFollowersTagsObj = [...this.preSelectedTags];
    } else {
      this.checkedFollowersTags = [];
      this.checkedFollowersTagsObj = [];
      this.checkMyPeopleAsSelection();
      this.updatePreselectedData();
    }
    this.checkAllCheckedOnPage('myFollowersTags');
  }

  /**
   * @method: resetMyLists
   * @desc: Reset the my lists
   */
  resetMyLists() {
    if (this.status !== 'Draft') {
      this.checkedMyLists = [...this.preSelectedMyListsIds];
      this.checkedMyListsObj = [...this.preSelectedMyLists];
    } else {
      this.checkedMyLists = [];
      this.checkedMyListsObj = [];
      this.checkMyPeopleAsSelection();
      this.updatePreselectedData();
    }
    this.checkAllCheckedOnPage('myList');
  }

  /**
   * @method: resetMyPeople
   * @desc: Reset the my People
   */
  resetMyPeople() {
    if (this.status !== 'Draft') {
      this.checkedMyPeople = [...this.preSelectedMyPeopleIds];
      if (this.preSelectedMyPeople.length > 0) {
        this.preSelectedMyPeople.forEach(list => {
          this.checkedMyPeople.push(list.user);
          let user = { ...list, id: list['user'] };
          this.checkedMyPeopleTagsObj.push(user);
        });
      }
    } else {
      this.checkedMyPeople = [];
      this.checkedMyPeopleTagsObj = [];
    }
    this.checkMyPeopleAsSelection();
    this.checkAllCheckedOnPage('myPeople');
  }


  /**
  * @method: sendSelectedMembers
  * @desc: send selected members, tag, mylist for content creation/Direct push
  */
  sendSelectedMembers() {
    let selectFollowes = [];
    selectFollowes = [...this.idOfSelectedUsersFromTagsLists, ...this.checkedMyPeople];
    selectFollowes = _.uniq(selectFollowes);
    let settings = {
      all_follower: this.superPushSelection == 'publishToAll' ? true : false,
      follower_groups: this.superPushSelection == 'publishToSubset' && this.checkedMyLists && this.checkedMyLists.length > 0 ? this.checkedMyLists : [],
      followers: this.superPushSelection == 'publishToSubset' && selectFollowes && selectFollowes.length > 0 ? selectFollowes : [],
      tags: this.superPushSelection == 'publishToSubset' && this.checkedFollowersTags && this.checkedFollowersTags.length > 0 ? this.checkedFollowersTags : []
    }
    this.selectedSuperPushSettings.emit(settings);
  }

  /**
  * @method: updatePreselectedData
  * @desc: reset and filterd data which is removed tag/mylist
  */
  updatePreselectedData() {
    let checkedTagsMembers = _.pluck(this.preSelectedData.preSelectedTags, 'members');
    let checkedMyListMenbers = _.pluck(this.preSelectedData.preSelectedMyLists, 'members');
    let checkedTagsMembersUnionArray = _.union.apply(_, checkedTagsMembers);
    let checkedMyListMenbersUnionArray = _.union.apply(_, checkedMyListMenbers);

    if (checkedTagsMembersUnionArray.length > 0) {
      checkedTagsMembersUnionArray.forEach(element => {
         this.handleRemovedMyPeople(element);
      });
    }

    if (checkedMyListMenbersUnionArray.length > 0) {
      checkedMyListMenbersUnionArray.forEach(element => {
        this.handleRemovedMyPeople(element);
      });
    }
  }

  /**
   * @method: handleRemovedMyPeople
   * @desc: Method using for removed checked Mylist and Tag peoples.
   */
  handleRemovedMyPeople(element) {
    const tempIndex = this.checkedMyPeople.findIndex((x) => x == element.id);
    if (tempIndex > -1) {
      this.checkedMyPeople.splice(tempIndex, 1);
      const addIndex = this.checkedMyPeopleTagsObj.findIndex((x) => x.id == element.id);
      if(addIndex > -1) this.checkedMyPeopleTagsObj.splice(addIndex, 1);
    }
  }
}
