import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { LoginResolverService } from '../../shared/services/login-resolver.service';

const routes: Routes = [
  {path: '', redirectTo : 'login', pathMatch: 'full'},
  {path: 'login', component : LoginComponent, 
    resolve : {
      loggedIn: LoginResolverService
    }
  },
  {path: 'create-password', component : CreatePasswordComponent},
  {path: 'forgot-password', component : CreatePasswordComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
