import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng2PicaService } from 'ng2-pica';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-image-resizer',
  templateUrl: './custom-image-resizer.component.html',
  styleUrls: ['./custom-image-resizer.component.css']
})
export class CustomImageResizerComponent implements OnInit {
  showWarning: boolean;
  files: any;
  image: any;
  onClose = new Subject<any>();
  form: FormGroup;
  submitted: boolean;
  constructor(
    private FormBuilder: FormBuilder,
    private ng2PicaService: Ng2PicaService,
    private _bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.form = this.FormBuilder.group({
      width: ['', Validators.required],
      height: ['', Validators.required]
    });
    setTimeout(() => {
      this.showWarning = this._bsModalRef.content['showWarning'];
      this.files = this._bsModalRef.content['files'];
      this.image = this.files.target.files;
    }, 1000);
  }

  get f() { return this.form.controls; }

  /**
   * @method: onSubmit
   * @desc: Convert the image resolution.
   */
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) return;
    this.changeResolution(this.form.value.width, this.form.value.height);
  }

  /**
   * @method: cancel
   * @desc: cancel resolution change
   */
  cancel() {
    this.onClose.next("cancel");
    this._bsModalRef.hide();
  }

  /**
   * @method: confirm
   * @desc: convert to 640*360
   */
  confirm() {
    this.changeResolution(640, 360);
  }

  /**
   * @method: custom
   * @desc: Convert the custom image resolution.
   */
  custom() {
    this.showWarning = false;
  }
  /**
   * @method: back
   * @desc: back button reset.
   */
  back() {
    this.showWarning = true;
    this.form.reset();
    this.submitted = false;
}
  /**
   * @method: changeResolution
   * @desc: Convert the image resolution.
   */
  changeResolution(width, height) {
    this.ng2PicaService.resize(this.image, width, height, true).subscribe((result) => {
      var reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onloadend = (e) => {
        var base64data = reader.result;
        this.onClose.next(base64data);
        this._bsModalRef.hide();
      }
    }, error => {
      console.error(error);
    });
  }
}
