import { Component, OnInit} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
  public content: any;
  public onClose: Subject<boolean>;
  public modalRef: BsModalRef;
  constructor( private modalService: BsModalService, private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.onClose.next(false);
    this.content = this.modalService.config.initialState['content'];
  }

  /**
   * @method: proceedNow
   * @desc: Method using for proceed for update the info.
   */
  proceedNow() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  /**
   * @method: closeNow
   * @desc: Method using for proceed for cancel info.
   */
  closeNow() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
