import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoreService, HelperService } from "@common-services";
import { ENDPOINTS, MESSAGES } from "@constants";
import { ToastrService } from "ngx-toastr";
declare var jQuery: any;

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.css"],
})
export class ForgotpasswordComponent implements OnInit {
  @ViewChild("myModal", { static: false }) myModal: ElementRef;
  public fotgotForm: FormGroup;
  public formSubmit: boolean = false;
  assets: object;

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.fotgotForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  /**
   * @method: onKey
   * @desc: Method called on keyup on email field.
   * @param event
   */
  onKey(event) {
    this.formSubmit = false;
  }

  readonly onClose = () => {
    this.formSubmit = false;
    this.fotgotForm.reset();
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.fotgotForm.controls;
  }

  /**
   * @method: forgotSubmit
   * @desc: submit form and call API to send reset password link over email.
   */
  forgotSubmit() {
    this.formSubmit = true;
    const self = this;
    if (!this.fotgotForm.invalid) {
      this.helperService.showSpinner(true);
      this.assets = this.helperService.renderAssets();
      this.coreService
        .post(ENDPOINTS.CMS_FORGOT_PASSWORD, {
          email: this.fotgotForm.controls.email.value,
          domainUrl: window.location.origin,
          app_id: this.assets["appId"],
        })
        .subscribe(
          (res) => {
            this.formSubmit = false;
            this.helperService.showSpinner(false);
            this.toastr.success(MESSAGES.RESET_PASSWORD_EMAIL_SENT);
            jQuery(self.myModal.nativeElement).modal("hide");
          },
          (error) => {
            this.formSubmit = false;
            this.toastr.error(MESSAGES.FORGETPASSWORD_ERROR);
          }
        );
    }
  }
}
