import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';
import { HelperService, CoreService } from '@common-services';
import { MESSAGES, ENDPOINTS, ROUTES_PATH } from '@constants';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';
import * as _ from 'underscore';

declare var jQuery: any;
@Component({
  selector: 'app-force-multiplier-confirmation',
  templateUrl: './force-multiplier-confirmation.component.html',
  styleUrls: ['./force-multiplier-confirmation.component.css']
})

export class ForceMultiplierConfirmationComponent implements OnInit {
  @Input() showPopupArray: any = [];
  @Input() image: string = '';
  @Input() reStartModal: string;
  @Output() cancleFMSPopup = new EventEmitter();
  submitStatus: boolean = false;
  message: string = '';
  followersMessage: string = '';
  popupMsg: string = '';
  errorObj: any = {};
  orgInfo: any = {};
  checkedPushesIds: Array<number> = [];
  modalRef: BsModalRef;
  pushes: Array<any>;

  @ViewChild('forceMultiplierConfirmation', { static: false }) forceMultiplierConfirmation: ElementRef;

  constructor(
    private coreService: CoreService,
    private helperService: HelperService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private routes: Router
  ) { }

  ngOnInit() {
    this.message = '';
    this.followersMessage = '';
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (event.target === this.forceMultiplierConfirmation.nativeElement) {
      this.closePopup();
    }
  }

  ngOnChanges() {
    this.message = '';
    this.followersMessage = '';
    if (this.reStartModal === 'OPEN') {
      this.message = '';
      this.followersMessage = '';
      if (this.showPopupArray.popUpData.suggested_notifications.length > 0) {
        this.pushes = this.showPopupArray.popUpData.suggested_notifications;
      } else {
        this.pushes = this.showPopupArray.popUpData.notification;
      }
      jQuery(this.forceMultiplierConfirmation.nativeElement).modal('show');
      this.addBodyClass();
    }
  }

  /**
   * @method: closePopup
   * @desc: Close pop up
   */
  closePopup(amplified: boolean = false) {
    const contentId = this.showPopupArray.popUpData.preview.id;
    const type = this.showPopupArray.popUpData.preview.type;
    (amplified) ? this.cancleFMSPopup.emit({ status: 'AMPLIFIED', id: contentId, type: type }) : this.cancleFMSPopup.emit('CLOSE');
    this.reStartModal = 'CLOSE';
    jQuery(this.forceMultiplierConfirmation.nativeElement).modal('hide');
    this.submitStatus = false;
  }

  /**
   * @method: submitData
   * @desc: Submit pop up data
   */
  submitData(status: any, amplified: boolean = false) {
    this.showPopupArray.status = status;
    if (this.showPopupArray.length > 0) {
      this.errorObj.status = true;
      this.errorObj.msg = MESSAGES.PARTNER_CONFIMATION_SUBMIT_ERROR;
      return false;
    }
    if (this.showPopupArray.popUpData.preview.status_text === 'Expired') {
      this.toastr.error(MESSAGES.FM_ERROR_ACCEPT);
      return false;
    }
    if (this.showPopupArray.popUpData && !this.submitStatus) {
      if (status === "accept") {
        jQuery(this.forceMultiplierConfirmation.nativeElement).modal('hide');
        this.showConfirmationModal('Publish Now', MESSAGES.PUBLISH_NOW_CONTENT, () => {
          this.acceptOrRejectRequest(status, amplified);
        }, () => {
          jQuery(this.forceMultiplierConfirmation.nativeElement).modal('show');
          this.addBodyClass();
        });
      } else if (status === "reject") {
        this.acceptOrRejectRequest(status, amplified);
      }
    }
  }

  addBodyClass() {
    setTimeout(() =>{
      document.body.classList.add('modal-open');
    }, 1500)
  }

  /**
   * @method: acceptOrRejectRequest
   * @desc: Accept or Decline content request
   */
  acceptOrRejectRequest(status: string, amplified: boolean) {
    const contentId = this.showPopupArray.popUpData.id;
    const allNotificationsid = _.pluck(this.showPopupArray.popUpData.notification, 'id');
    const payload = {
      status: status,
      msg: this.message !== '' ? this.message.trim() : null,
      followers_msg: this.followersMessage !== '' ? this.followersMessage.trim() : null,
      notifications: allNotificationsid,
      accepted_notifications: this.checkedPushesIds,
      amplified_content: amplified
    };
    this.submitStatus = true;
    const URL = `${ENDPOINTS.SHARE_PARTNER_CONTENT}/${contentId}/`;
    this.helperService.showSpinner(true);
    this.coreService.put(URL, payload).subscribe((res: any) => {
      this.helperService.showSpinner(false);
      if (res.status === 200) {
        this.message = '';
        this.followersMessage = '';
        this.checkedPushesIds = [];
        this.toastr.success(res.body.message);
        this.closePopup(amplified);
      }
    }, (error) => {
      this.helperService.showSpinner(false);
      this.submitStatus = false;
    });
  }

  /**
   * @method: getStatus
   * @desc: Get the status
   * @param status 
   */
  getStatus(status) {
    let currentStatus = status;
    if (this.showPopupArray && this.showPopupArray.popUpData.is_copied) {
      return 'Copied';
    }
    if (status === 'accept') {
      currentStatus = 'Accepted';
    } else if (status === 'reject') {
      currentStatus = 'Declined';
    } else if (status === 'pending') {
      currentStatus = 'Pending';
    }
    return currentStatus;
  }

  /**
   * @method: checkUncheckPushes
   * @desc: Check uncheck push notifications
   */
  checkUncheckPushes(e: any, content: object) {
    const id = content['id'];
    const checkExistence = this.checkedPushesIds.includes(content['id']);
    if (checkExistence) {
      const index = this.checkedPushesIds.indexOf(id);
      if (index > -1) {
        this.checkedPushesIds.splice(index, 1);
      }
    } else {
      this.checkedPushesIds.push(id);
    }
  }

  /**
  * @method: acceptAndAmplify
  * @desc: Accept and amplify the content
  */
  acceptAndAmplify(e: any) {
    if (this.showPopupArray.popUpData.is_re_fm) {
      if (this.showPopupArray.popUpData.preview.status_text === 'Expired') {
        this.toastr.error(MESSAGES.FM_ERROR_REAMPLIFY);
        return false;
      }
      jQuery(this.forceMultiplierConfirmation.nativeElement).modal('hide');
      this.showConfirmationModal(MESSAGES.ACCEPT_AND_AMPLIFY_CONTENT_HEADING, MESSAGES.ACCEPT_AND_AMPLIFY_CONTENT, () => {
        const contentFMId = this.showPopupArray.popUpData.id
        const contentId = this.showPopupArray.popUpData.preview.id;
        const contentType = this.showPopupArray.popUpData.preview.type;
        const allNotificationsid = _.pluck(this.showPopupArray.popUpData.notification, 'id');
        this.routes.navigate([`${ROUTES_PATH.CONTENTHUBCREATION}${contentType}/${contentId}/amplify`], { queryParams: { fm_content: contentFMId } })
          .then(() =>
            this.helperService.getFMBotifications.emit({
              selectedNotifications: this.checkedPushesIds,
              allNotifications: allNotificationsid,
              msg: this.message !== '' ? this.message.trim() : null,
              followers_msg: this.followersMessage !== '' ? this.followersMessage.trim() : null,
              amplify: true
            }));
      }, () => {
        jQuery(this.forceMultiplierConfirmation.nativeElement).modal('show');
        this.addBodyClass();
      });
    }
  }

  /**
  * @method: acceptAndAmplify
  * @desc: Accept and amplify the content
  */
  copyContent(e: any) {
    if (this.showPopupArray.popUpData.is_allow_copy) {
      jQuery(this.forceMultiplierConfirmation.nativeElement).modal('hide');
      this.showConfirmationModal(MESSAGES.COPY_CONTENT_MODAL_HEADING, MESSAGES.COPY_CONTENT_MODAL_DESCRIPTION, () => {
        const contentFMId = this.showPopupArray.popUpData.id
        const contentId = this.showPopupArray.popUpData.preview.id;
        const contentType = this.showPopupArray.popUpData.preview.type;
        this.routes.navigate([`${ROUTES_PATH.CONTENTHUBCREATION}${contentType}/${contentId}/copy`], { queryParams: { fm_content: contentFMId } });
      }, () => {
        jQuery(this.forceMultiplierConfirmation.nativeElement).modal('show');
        this.addBodyClass();
      });
    }
  }

  /**
   * @method: showConfirmationModal
   * @desc: Generic method to show confirmation modal
   * @param err 
   * @param subHeading 
   * @param cb 
   */
  showConfirmationModal(heading: string, description: string, cbAccept, cbCancel) {
    const contentData = {
      heading: heading,
      subheading: "",
      dis: description,
      yesBtn: 'Continue',
      noBtn: 'Go back',
      hideCrossIcon: true
    };
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        content: contentData
      },
      class: 'confirmation_modal'
    });
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        if (cbAccept && typeof cbAccept === 'function') cbAccept();
      } else {
        if (cbCancel && typeof cbCancel === 'function') cbCancel();
      }
    });
  }
}