import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { CoreService, HelperService } from '@common-services';
import { ENDPOINTS, MESSAGES } from '@constants';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as countryCodes from './country-codes.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.css']
})
export class TwoFactorComponent implements OnInit {
  @Input() loginViaOTP: boolean = false;
  @Output() goToLogin = new EventEmitter();
  @Output() LoggedInSuccess = new EventEmitter();
  content: any;
  onClose: Subject<any>;
  modalRef: BsModalRef;
  modalHeader: string;
  headingEnable2FA: string = MESSAGES.MODAL_HEADER_PHONE_NUMBER;
  headingOfOTP: string = MESSAGES.MODAL_HEADER_OTP_NUMBER;
  showPhoneNumberModal: boolean = true;
  form: FormGroup;
  otpForm: FormGroup;
  phoneModalSubmitted: boolean = false;
  attemptCount: number;
  timer: number;
  otpError: boolean = false;
  otpSubmitted: boolean = false;
  stopwatch: any;
  reachedMaxAttempts: boolean = false;
  customPatterns = {
    '0': { pattern: new RegExp(/[1-9]/) },
    '1': { pattern: new RegExp(/\d/) }
  };
  countryCodesObject: any = (countryCodes as any).default;
  countryCodes: Array<any> = this.countryCodesObject;
  assets: object;
  phoneFor2Fa: string;
  errorMessage: string = "";
  otpData: any;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private coreService: CoreService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.assets = this.helperService.renderAssets();
    this.onClose = new Subject();
    this.onClose.next(false);
    this.modalHeader = this.headingEnable2FA;
    this.initForm();
    if (this.loginViaOTP) {
      this.phoneFor2Fa = this.helperService.getFieldValueFromStorage('userinfo', 'phone');
      this.sentOtp();
    }
  }

  /**
   * @method: ngOnDestroy
   */
  ngOnDestroy() {
    clearInterval(this.stopwatch);
  }

  /**
 * @method: initForm
 * @desc: Init add org form
 */
  initForm() {
    this.form = this.formBuilder.group({
      code: ['+1', [Validators.required]],
      phone: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]]
    });
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  get f() { return this.form.controls; }
  get of() { return this.otpForm.controls; }

  /**
   * @method: onPhoneNumberSubmit
   * @desc: Submit phone number
   */
  onPhoneNumberSubmit() {
    this.phoneModalSubmitted = true;
    if (this.form.invalid) return;
    this.otpForm.patchValue({
      otp: ''
    });
    this.sentOtp();
  }

  /**
   * @method: onSubmitOtp
   * @desc: Submit otp number
   */
  onSubmitOtp() {
    let phoneNumber, URL, payload = {};
    this.otpSubmitted = true;
    if (this.otpForm.invalid) return;
    this.otpError = false;
    if (this.loginViaOTP) {
      phoneNumber = this.phoneFor2Fa;
      URL = ENDPOINTS.OTP_FOR_LOGIN;
      const email = this.helperService.getFieldValueFromStorage('userinfo', 'username');
      payload = { email: email }
    } else {
      phoneNumber = this.f.code.value + this.f.phone.value;
      URL = ENDPOINTS.OTP;
    }
    const generate_number = this.otpData['generate_number'] || "";
    this.coreService.post(URL, {
      ...payload,
      phone: phoneNumber,
      otp: this.of.otp.value.trim(),
      generate_number: generate_number,
      skip_error_toaster: true
    }).subscribe((res) => {
      if (this.loginViaOTP) {
        this.LoggedInSuccess.next(res.body);
      } else {
        this.toastrService.success(MESSAGES.TWO_FACTOR_AUTH_SUCCESS);
        this.closeNow(true);
      }
    }, (err) => {
      this.otpError = true;
      if (err.error.message && err.error && err.error.message) this.errorMessage = err.error.message
    });
  }

  /**
   * @method: startTimer
   */
  startTimer() {
    this.timer = 59;
    this.stopwatch = setInterval(() => {
      this.timer--;
      if (this.timer == 0) {
        if (this.attemptCount === 0) this.reachedMaxAttempts = true;
        clearInterval(this.stopwatch);
      }
    }, 1000);
  }

  /**
   * @method: closeNow
   * @desc: Method using for proceed for cancel info.
   */
  closeNow(enabled: boolean = false) {
    clearInterval(this.stopwatch);
    const phoneNumber = this.f.code.value + this.f.phone.value;
    this.onClose.next({
      enabled: enabled,
      phone: phoneNumber
    });
    this.bsModalRef.hide();
  }

  /**
   * @method: sentOtp
   * @desc: method to send otp on the phone number
   */
  sentOtp() {
    let phoneNumber, URL, email;
    this.helperService.showSpinner(true);
    if (this.loginViaOTP) {
      email = this.helperService.getFieldValueFromStorage('userinfo', 'username');
      URL = `${ENDPOINTS.OTP_FOR_LOGIN}?email=${encodeURIComponent(email)}`;
    } else {
      phoneNumber = this.f.code.value + this.f.phone.value;
      URL = `${ENDPOINTS.OTP}?phone=${encodeURIComponent(phoneNumber)}&is_two_factor=true`;
    }
    this.coreService.get(URL, {
      skip_error_toaster: true
    }).subscribe((result) => {
      const data = result.body.data;
      this.otpData = data;
      this.reachedMaxAttempts = false;
      this.showPhoneNumberModal = false;
      this.modalHeader = this.headingOfOTP;
      this.attemptCount = Number(data.attempts);
      this.otpError = false;
      this.errorMessage = "";
      this.otpSubmitted = false;
      this.startTimer();
      this.helperService.showSpinner(false);
    }, (err) => {
      const error = err.error;
      if (error) this.attemptCount = Number(error.detail);
      this.showMaxAttemptsError();
      this.helperService.showSpinner(false);
    });
  }

  /**
   * @method: showMaxAttemptsError
   * @desc: show the error for maximum attempts
   */
  showMaxAttemptsError() {
    if (this.attemptCount === 0) {
      this.reachedMaxAttempts = true;
      this.showPhoneNumberModal = false;
      this.modalHeader = this.headingOfOTP;
      this.otpError = false;
      this.errorMessage = "";
      this.otpSubmitted = false;
      this.of.otp.disable();
    } else {
      this.reachedMaxAttempts = false;
    }
  }

  /**
   * @method: goBack
   * @desc: go back to phone number modal
   */
  goBack() {
    this.showPhoneNumberModal = true;
    this.timer = null;
    clearInterval(this.stopwatch);
    this.reachedMaxAttempts = false;
    this.otpSubmitted = false;
    this.otpError = false;
    this.errorMessage = "";
    this.modalHeader = this.headingEnable2FA;
  }

  /**
   * @method: goBackToLogin
   * @desc: Go back to login page
   */
  goBackToLogin() {
    this.otpSubmitted = false;
    this.goToLogin.next(true);
  }

  /**
   * @method: submitOTP
   * @desc: Submit OTP
   */
  submitOTP() {
    this.otpSubmitted = true;
    const generate_number = this.otpData['generate_number'] || "";
    if (this.otpForm.invalid) return;
    this.otpError = false;
    this.coreService.post(ENDPOINTS.OTP, {
      otp: this.of.otp.value.trim(),
      generate_number: generate_number,
      skip_error_toaster: true
    }).subscribe((res) => {
      this.toastrService.success(MESSAGES.TWO_FACTOR_AUTH_SUCCESS);
      this.closeNow(true);
    }, (err) => {
      this.otpError = true;
    });
  }
}
