import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { CoreService, HelperService } from '@common-services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MESSAGES } from '@constants';
import { ToastrService } from 'ngx-toastr';


declare var jQuery: any;
@Component({
  selector: 'app-message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MessagePanelComponent implements OnInit {
  search: string = "";
  @Input() msgData: any;
  data: any;
  @Input() from: any = "";
  public showSpinner = false;
  partnerId: any;
  type: any;
  modalHeading:any;
  filter: string = "all";
  CurrentFilter: string = "all";
  constructor(private helperService: HelperService,
    private coreService: CoreService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public modalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.partnerId = this.modalService.config.initialState['partnerId'];
    this.type = this.modalService.config.initialState['type'];
    this.msgData = this.modalService.config.initialState['msgData'];
    this.from = this.modalService.config.initialState['from'];
    this.modalHeading = this.modalService.config.initialState['modalHeading'];
    this.getMessages();
  }

  /**
   * @method: getMessages
   * @desc: Get all the messages
   * @param filter 
   */
  getMessages(filter?: boolean) {
    let URL;
    if (this.from && this.from === 'partner') {
      URL = `message?request_type=${this.type}&partner_id=${this.partnerId}&search=${encodeURIComponent(this.search)}`;
      if (filter) URL = `message?request_type=${this.type}&partner_id=${this.partnerId}&search=${encodeURIComponent(this.search)}&status=${this.filter}`;
    } else {
      URL = `message?request_type=${this.type}&content_id=${this.partnerId}&search=${encodeURIComponent(this.search)}`;
      if (filter) URL = `message?request_type=${this.type}&content_id=${this.partnerId}&search=${encodeURIComponent(this.search)}&status=${this.filter}`;
    }
    this.helperService.showLoaderWithId('loader_MSG');
    this.coreService.get(URL).subscribe((res: any) => {
      this.helperService.hideLoaderWithId('loader_MSG');
      this.showSpinner = true;
      this.data = res.body.data;
    }, (error) => {
      this.showSpinner = true;
      this.helperService.hideLoaderWithId('loader_MSG');
      if (error === 'Unknown Error') {
        this.toastr.error(MESSAGES.UNKNOWN_ERROR);
      } else {
        this.toastr.error(MESSAGES.INTERNAL_SERVER_ERR);
      }
    });
  }

  /**
   * @method: filterMessage
   * @desc: Filter messages as per the radio button selection.
   */
  filterMessage() {
    this.search = this.search.trim();
    if (this.search.length !== 0) {
      (this.filter === 'all') ? this.getMessages() : this.getMessages(true);
    }
  }

  /**
   * @method: filterByRadioButton
   * @desc: Click event of radio button.
   * @param e 
   */
  filterByRadioButton(e: any) {
    this.filter = e.target.value;
    this.search = '';
    (this.filter === 'all') ? this.getMessages() : this.getMessages(true);
  }

  /**
   * @method: isEmptyMessageListSearch
   * @desc: Method called when field is empty.
   * @param search 
   */
  isEmptyMessageListSearch(search) {
    if (this.search === '' && search.key !== 'Enter') {
      (this.filter === 'all') ? this.getMessages() : this.getMessages(true);
    }
  }
}
