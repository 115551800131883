import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../../shared/helpers/must-match.validator';
import { ToastrService } from 'ngx-toastr';
import { ENDPOINTS, MESSAGES, ROUTES_PATH } from '@constants';
import { HelperService, CoreService } from '@common-services';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html'
})
export class CreatePasswordComponent implements OnInit {
  public createPasswordForm: FormGroup;
  public submitted = false;
  public errorStatus = false;
  public errorMsg = '';
  public token: any = '';
  public isTokenValid = false;
  public type: any = 'create-password';
  assets: object;

  constructor(
    private helperService: HelperService,
    private router: Router,
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.url.subscribe(url => this.type = url[0].path !== undefined && url[0].path !== null && url[0].path !== '' ? url[0].path : 'create-password');
    this.route.queryParams.subscribe(params => {
      const token = params.token;
      if (token !== undefined && token !== null && token !== '') {
        this.token = token;
        this.verifyToken();
      }
    });
    this.assets = this.helperService.renderAssets();
    this.initForm();
  }


  /**
   * @method: initForm
   * @desc: Initialize create password form
   */
  initForm() {
    this.createPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^\S*$/)
      ]],
      cnfPassword: ['', [Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^\S*$/)
      ]],
    }, {
      validator: MustMatch('password', 'cnfPassword')
    });
  }

  /**
   * @method: onKeyupPass
   * @desc: method called on key up
   * @param event 
   */
  onKeyupPass(event) {
    this.submitted = true;
  }

  // convenience getter for easy access to form fields
  get get() { return this.createPasswordForm.controls; }

  /**
   * @method: createNewPassword
   * @desc: method called on submit form to create new password
   */
  createNewPassword() {
    this.submitted = true;
    if (!this.createPasswordForm.invalid) {
      this.helperService.showSpinner(true);
      let URL = `${ENDPOINTS.CMS_SET_PASSWORD}?token=${this.token}`;
      if (this.type === 'forgot-password') {
        URL = `${ENDPOINTS.CMS_SET_PASSWORD}?token=${this.token}&type=forgotpassword`;
      }
      this.coreService.post(URL, {
        password: this.createPasswordForm.controls.password.value,
        confirm_password: this.createPasswordForm.controls.cnfPassword.value,
      }).subscribe((res) => {
        this.submitted = false;
        this.helperService.showSpinner(false);
        if (this.type === 'forgot-password') {
          this.toastr.success(MESSAGES.PASSWORD_RESET_SUCCESS);
        } else {
          this.toastr.success(MESSAGES.PASSWORD_CREATE_SUCCESS);
        }
        this.router.navigate([ROUTES_PATH.LOGIN]);
      }, (error) => {
        this.submitted = false;
        if(error && error.body) this.toastr.error(error.body.data.error);
      });
    }
  }

  /**
   * @method: verifyToken
   * @desc: Check Valid token for authenticate the user
   */
  verifyToken() {
    let URL = `${ENDPOINTS.CMS_SET_PASSWORD}?token=${this.token}`;
    if (this.type === 'forgot-password') {
      URL = `${ENDPOINTS.CMS_SET_PASSWORD}?token=${this.token}&type=forgotpassword`;
    }
    this.helperService.showSpinner(true);
    this.coreService.get(URL, {}).subscribe((result) => {
      this.helperService.showSpinner(false);
      if (result.body.status_code === 200) {
        const { status } = result.body.data;
        if (status === 'False') {
          this.errorStatus = true;
          this.isTokenValid = true;
          this.errorMsg = MESSAGES.INVALID_PASSWORD_LINK;
          setTimeout(() => {
            this.router.navigate([ROUTES_PATH.LOGIN]);
          }, 2000);
        } else {
          this.isTokenValid = false;
        }
      } else {
        this.errorStatus = true;
        this.isTokenValid = true;
        this.errorMsg = MESSAGES.INVALID_TOKEN;
      }
    }, (err) => {
      this.errorStatus = true;
      this.isTokenValid = true;
      this.errorMsg = err.body.error;
      this.helperService.showSpinner(false);
    });
  }

}
