import { Component, OnInit, ViewChild, EventEmitter, Output, Input, ElementRef, HostListener } from '@angular/core';
import { CoreService, HelperService } from '@common-services';
import { ToastrService } from 'ngx-toastr';
import _ from 'underscore';
declare var jQuery: any;
import { ENDPOINTS, MESSAGES } from '@constants';
@Component({
  selector: 'app-create-force-multiplier',
  templateUrl: './create-force-multiplier.component.html',
  styleUrls: ['./create-force-multiplier.component.css']
})
export class CreateForceMultiplierComponent implements OnInit {
  @ViewChild('createForceMultiplier', { static: false }) createForceMultiplier: ElementRef;

  public partnerListObj: any[];
  public search: string = '';
  public selectedPartnerItems: any = [];
  public showSpinner: boolean = false;
  public message: any = '';
  public saveList: any = 'NEW';
  public errorObj: any = {};
  public curretState: any = '';
  public dropdownOptions: Array<any>
  public dropDownSettings = {
    itemKey: 'name'
  }
  public selectSaveList: Array<any>;
  public uniqSaveListArr: Array<any>;
  public selectedSaveListIds: any;
  public selectedList: any;
  @Input() Type: any = '';
  @Input() msg: any = '';
  @Input() contentStatus: any = '';
  @Input() alreadyAddFmList: any = [];
  @Input() isDialogOpen: any;
  @Input() contentType: any;
  @Input() contentId: any;
  @Output() cancleFMSPopup = new EventEmitter();
  @Output() selectedFMPartnersLists = new EventEmitter();
  @Input() alreadySelectedGroup: any;
  constructor(
    private coreService: CoreService,
    private helperService: HelperService,
    private toastrService: ToastrService,
  ) { }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (event.target === this.createForceMultiplier.nativeElement) {
      this.closePopup();
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.isDialogOpen) {
      this.showSpinner = false;
      this.getPartnersOrgList('');
      this.getSaveList();
      if (this.contentId || this.alreadyAddFmList.length > 0) {
        this.message = this.msg ? this.msg : '';
        this.selectedPartnerItems = [];
      }
      this.curretState = 'EXITS';
      this.partnerListObj = [];

      if (this.saveList === 'NEW') {
        this.selectedList = [];
      }
    }
  }

  /**
   * @method: resetTheForceMultiplierPopup
   * @desc: Method used for reset all popup fields when freshly opend
   */
  resetTheForceMultiplierPopup() {
    this.errorObj.status = false;
    this.errorObj.msg = '';
    if (this.contentId || this.saveList === 'NEW') {
      this.selectedPartnerItems = [];
      this.message = '';
      this.selectSaveList = [];
      this.selectedSaveListIds = [];
      this.uniqSaveListArr = [];
      this.selectedList = [];
    }
    this.search = '';
    this.errorObj.searchStatus = false;
  }

  /**
   * @method: get partners list
   * @desc: Get the partners list &request_id=411
   * @param value
   */
  getPartnersOrgList(value) {
    this.showSpinner = false;
    this.partnerListObj = [];
    let payload = `partner/entity/list?request_type=${this.contentType}`;
    if (value !== '') {
      payload = `partner/entity/list?request_type=${this.contentType}&search=${value}`;
    }
    if (this.contentId) {
      payload = `partner/entity/list?request_type=${this.contentType}&request_id=${this.contentId}`;
      if (value !== '') {
        payload = `partner/entity/list?request_type=${this.contentType}&search=${value}&request_id=${this.contentId}`;
      }
    }
    this.coreService.get(payload).subscribe((result) => {
      this.success(result);
    }, (error) => {
      this.showSpinner = true;
      this.helperService.commonMethodForHandleError(error);
    });
  }


  /**
   * @method: success
   * @param res 
   * @param cb 
   * @param showErrorOnToaster 
   * @desc: Verify the error from API as per the status code
   */
  success(res, showErrorOnToaster?) {
    this.showSpinner = true;
    this.handlePartnersResponse(res.body);
  }

  /**
   * @method: handlePartnersResponse
   * @desc: Handle partners lst response
   * @param data
   */
  handlePartnersResponse(data: any) {
    this.partnerListObj = data.data;
    this.partnerListObj.forEach((element, key) => {
      if (this.partnerListObj[key].content_status === 'accept') {
        this.partnerListObj[key].content_status = 'Accepted';
      }
      if (this.partnerListObj[key].content_status === 'reject') {
        this.partnerListObj[key].content_status = 'Declined';
      }
      if (this.partnerListObj[key].content_status === 'pending') {
        this.partnerListObj[key].content_status = 'Pending';
      }
    });
    this.showSpinner = true;
    this.markAsSelected();
  }

  /**
   * @method: onSearch
   * @desc: Filter the organization list
   * @param e
   */
  onSearch(e: any) {
    if (this.search.length > 0) {
      this.showSpinner = false;
      const val = this.search.trim();
      this.errorObj.searchStatus = true;
      this.getPartnersOrgList(val);
    }
  }

  /**
   * @method: isEmptyFMSearch
   * @desc: Method using for clear search on keyup
   * @param search
   */
  isEmptyFMSearch(search) {
    if (this.search === '' && search.key !== 'Enter') {
      this.showSpinner = false;
      this.getPartnersOrgList('');
    }
  }

  /**
   * @method: closePopup
   * @desc: Method for close the popup
   */
  closePopup() {
    this.search = '';
    this.resetTheForceMultiplierPopup();
    this.isDialogOpen = false;
    this.cancleFMSPopup.emit(false);
    jQuery(this.createForceMultiplier.nativeElement).modal('hide');
  }

  /**
   * @method: selectFMPartners
   * @desc: Method used for select partners
   * @param id
   */
  selectFMPartners(id) {
    this.curretState = 'CHECK';
    if (id !== undefined && id !== null && id !== '') {
      const index = this.selectedPartnerItems.findIndex((x) => x == id);
      if (index > -1 && index !== undefined) {
        this.selectedPartnerItems.splice(index, 1);
        this.partnerListObj.forEach((element, key) => {
          if (element.id === id) {
            this.partnerListObj[key].selected = false;
          }
        });
      } else {
        this.partnerListObj.forEach((element, key) => {
          if (element.id === id) {
            this.partnerListObj[key].selected = true;
          }
        });
        this.selectedPartnerItems.push(id);
      }
    }
    this.markAsSelected();
  }

  /**
   * @method: markAsSelected
   * @desc: make mark as selected when edit content and show selected partners
   */
  markAsSelected() {
    if (this.selectedPartnerItems.length > 0) {
      this.partnerListObj.forEach((element, key) => {
        this.partnerListObj[key].selected = false;
      });
      this.selectedPartnerItems.forEach((element) => {
        const index = this.partnerListObj.findIndex((x) => x.id === element);
        if (index > -1 && index !== undefined) {
          this.partnerListObj[index].selected = true;
        }
      });
    }
    if (this.curretState === 'EXITS') {
      // Already Saved partner list items
      if (this.alreadyAddFmList.length > 0) {
        this.alreadyAddFmList.forEach((element) => {
          if (element.status !== 'reject') {
            const index = this.partnerListObj.findIndex((x) => x.id === element.receiver_entity.id && (x.content_status !== 'Declined' || x.content_status == 'Accepted' || x.content_status == 'Pending'));
            if (index > -1 && index !== undefined) {
              const indexs = this.selectedPartnerItems.findIndex((x) => x === element.receiver_entity.id);
              if (indexs === -1 && indexs !== undefined) {
                this.selectedPartnerItems.push(element.receiver_entity.id);
              }
              this.partnerListObj[index].selected = true;
            }
          }
        });
      }
    }
  }

  /**
   * @method: ResetForceMultiplierModal
   * @desc: Reset the force ForceMultiplier modal and reset all stored variables
   */
  ResetForceMultiplierModal() {
    this.search = '';
    this.resetTheForceMultiplierPopup();
    this.isDialogOpen = false;
    this.cancleFMSPopup.emit(false);
    this.selectedList = [];
    this.selectedPartnerItems = this.alreadyAddFmList = this.uniqSaveListArr = [];
    this.message = '';
    this.selectSaveList = [];
    this.selectedFMPartnersLists.emit({ list: [], msg: '', group_id: [] });
    jQuery(this.createForceMultiplier.nativeElement).modal('hide');
  }

  /**
   * @method: submitOutboundRequest
   * @desc: Method using for submit Outbound to the org using org id.
   */
  submitOutboundRequest() {
    this.errorObj.status = false;
    this.errorObj.msg = '';
    if (this.selectedPartnerItems.length === 0 && this.contentStatus !== 'Draft') {
      this.errorObj.status = true;
      this.errorObj.msg = MESSAGES.FORCE_MULTIPLIER_CONNECTION_ERROR;
      return false;
    } else {

      if (this.message.length > 150) {
        this.errorObj.status = true;
        this.errorObj.msg = MESSAGES.FORCE_MULTIPLIER_MESSAGE_ERROR;
        return false;
      }

      let saveEnable: boolean = false;
      _.each(this.selectedPartnerItems, (id) => {
        const tempPartner = _.findWhere(this.partnerListObj, { id: id });
        saveEnable = tempPartner !== undefined && (tempPartner.content_status == null || tempPartner.content_status === 'Declined');
        if (saveEnable)
          return saveEnable;
      });
      const tempArray = [];
      this.partnerListObj.forEach(element => {
        if (element.content_status === 'Pending') {
          tempArray.push(element.id);
        }
      });

      if (tempArray.length > this.selectedPartnerItems.length || tempArray.length < this.selectedPartnerItems.length) {
        saveEnable = true;
      }
      if (saveEnable || this.contentStatus === 'Draft') {
        const selectedPartnerItems = this.selectedPartnerItems;
        this.selectedSaveListIds = _.pluck(this.selectSaveList, 'id')
        this.selectedFMPartnersLists.emit({ list: selectedPartnerItems, msg: this.message, group_id: this.selectedSaveListIds });
        this.search = '';
        this.isDialogOpen = false;
        this.cancleFMSPopup.emit(false);
        this.saveList = 'SAVE';
        jQuery(this.createForceMultiplier.nativeElement).modal('hide');
      } else if (this.contentStatus !== 'Draft') {
        this.errorObj.status = true;
        this.errorObj.msg = MESSAGES.FORCE_MULTIPLIER_CONNECTION_ERROR;
      }
    }
  }

  /**
   * @method: closeErrorMsg
   * @desc: Method using for reset the error code status and clear the message variable.
   */
  closeErrorMsg() {
    this.errorObj.status = false;
    this.errorObj.msg = '';
  }

  /**
   * @method: getSaveList
   * @desc: Method using for get created save list.
   */
  getSaveList() {
    const URL = `${ENDPOINTS.PARTNER_GROUPS}?is_group=true`;
    this.coreService.get(URL).subscribe((res: any) => {
      if (res.body.data) {
        this.dropdownOptions = res.body.data;
        this.passSelectedGroupToMultiselect();
      } else {
        this.helperService.showSpinner(false);
      }
    }, (error) => {
      this.helperService.commonMethodForHandleError(error);
    });
  }

  /**
   * @method: passSelectedGroupToMultiselect
   * @desc: Method using for get pass selected group for multi select.
   */
  passSelectedGroupToMultiselect() {
    if (this.contentStatus === 'Draft' || this.contentStatus === 'Active' || this.contentStatus === 'Expired') {
      this.selectedList = this.alreadySelectedGroup;
    }
  }

  /**
   * @method: getSelectedAffiliation
   * @desc: Method using for selected save list filter out unique save list.
   * param e
   */
  getSelectedAffiliation(e) {
    this.selectSaveList = e;
    let arr = [], selectedArr = [];
    this.selectSaveList.forEach(items => {
      items.member_org_details.forEach(item => {
        item.selected = true;
        arr.push(item);
      });
    });
    this.uniqSaveListArr = _.uniq(arr, 'id');
    this.uniqSaveListArr.forEach((item) => {
      const indx = _.findIndex(this.partnerListObj, { id: item.id });
      if (indx > -1) {
        this.partnerListObj[indx].selected = true;
        this.partnerListObj[indx].isSaveList = true;
        item.selected = true;
        selectedArr.push(item);
      }
    });
    selectedArr = _.pluck(selectedArr, 'id');
    if (selectedArr.length > 0) {
      selectedArr = _.uniq(selectedArr);
      this.selectedPartnerItems.push(...selectedArr);
    }
    this.selectedPartnerItems = _.uniq(this.selectedPartnerItems)
  }

  /**
   * @method: disableSaveList
   * @desc: Method using for disable save list which is already selected.
   * param id
   */
  disableSaveList(id: number) {
    const filteredList = _.where(this.uniqSaveListArr, { id: id });
    if (filteredList.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
