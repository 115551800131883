import { Component, OnInit } from '@angular/core';
import { HelperService, CoreService } from '@common-services';
import { ENDPOINTS } from '@constants';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html'
})
export class TermsConditionsComponent implements OnInit {

  public appId: any = '';
  public termsConditionContent: any = [];
  constructor(
    private coreService: CoreService,
    public helperService: HelperService)
    { }

  ngOnInit() {
    this.appId = this.helperService.renderAssets().appId;
    this.getTermsConditionContent();
  }

  /**
   * @method: getTermsConditionContent
   * @desc: Get terms and condition content data
   */
  getTermsConditionContent() {
    this.helperService.showSpinner(true);
    this.coreService.get(`${ENDPOINTS.CMS_MASTER_LIST}/${this.appId}`, {}).subscribe((result) => {
      const data = this.helperService.success(result);
      this.termsConditionContent = data && data.terms_condition ? data.terms_condition : '';
      this.helperService.showSpinner(false);
    }, (err) => {
      this.helperService.showSpinner(false);
    });
  }
}
