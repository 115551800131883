import { EventEmitter, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MESSAGES } from '@constants';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomImageResizerComponent } from '../components/custom-image-resizer/custom-image-resizer.component';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public resetOrgDetails: any;
  private resetOrgDetail = new BehaviorSubject<any>(false);
  $resetOrgDetails = this.resetOrgDetail.asObservable();

  public notificateStatus: any = false;
  private getNotificationStatus = new BehaviorSubject<any>(
    this.notificateStatus
  );
  $notificateStatus = this.getNotificationStatus.asObservable();

  public feedDetails: any;
  private setFeedDetails = new BehaviorSubject<any>(this.feedDetails);
  $getFeedDetails = this.setFeedDetails.asObservable();

  public FollowersDetails: any = false;
  private updateFollowers = new BehaviorSubject<any>(this.FollowersDetails);
  $getFollowersDetails = this.updateFollowers.asObservable();

  public spinnerStatus: any;
  private messageSourceEcheckSpinner = new BehaviorSubject<any>(
    this.spinnerStatus
  );
  $spinnerStatus = this.messageSourceEcheckSpinner.asObservable();

  spinnerText: any;
  textForSpinner = new BehaviorSubject<any>(this.spinnerText);
  $spinnerText = this.textForSpinner.asObservable();

  public userDetails: any;
  private updateUserDetails = new BehaviorSubject<any>(this.userDetails);
  $userDetails = this.updateUserDetails.asObservable();

  customImageSizer: BsModalRef;

  getFMBotifications: EventEmitter<any> = new EventEmitter();
  modalRefs: BsModalRef[] = [];
  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {}

  /**
   * @method: getToken
   * @desc: get the details of Token
   */
  getToken() {
    let tokenDetailsAuth;
    tokenDetailsAuth = localStorage.getItem('token');
    if (tokenDetailsAuth) {
      return JSON.parse(tokenDetailsAuth);
    } else {
      return false;
    }
  }

  /**
   * @method: setFeedsInfo
   * @param value
   * @desc: Set the value of set feed info
   */
  setFeedsInfo(value: boolean) {
    this.setFeedDetails.next(value);
  }

  /**
   * @method: getNotificationStatus
   * @param value
   * @desc: Set the value of show notification bell icon
   */
  setNotificationStatus(value: boolean) {
    this.getNotificationStatus.next(value);
  }

  /**
   * @method: setFollowersDetails
   * @param value
   * @desc: Set the value of followers settings
   */
  setFollowersDetails(value: any) {
    this.updateFollowers.next(value);
  }

  /**
   * @method: showSpinner
   * @param value
   * @desc: Set the value of loader boolen variable to hide and show the loader on page
   */
  showSpinner(value: boolean) {
    this.messageSourceEcheckSpinner.next(value);
  }

  /**
   * @method: showSpinnerText
   * @param value
   * @desc: Set the value of loader text on page
   */
  showSpinnerText(value: string) {
    this.textForSpinner.next(value);
  }

  /**
   * @method: updateUserDetails
   * @param value
   * @desc: Set the value of loader boolen variable to hide and show the loader on page
   */
  public updateUserInfo(value: boolean) {
    this.updateUserDetails.next(value);
  }

  /**
   * @method: legend
   * @desc: get the legend for pagination
   * @param pages
   * @param count
   */
  legend(pages: number, count: number, pageSize?: number, theme?: string) {
    let legend;
    if (count == 0) {
      theme && theme === 'theme2'
        ? (legend = '0 to 0 of 0')
        : (legend = 'Showing 0 to 0 of 0 entries');
      return legend;
    }
    if (pageSize && count) {
      if (pages * pageSize >= count) {
        theme && theme === 'theme2'
          ? (legend = `${
              pages * pageSize - pageSize + 1
            } to ${count} of ${count}`)
          : (legend = `Showing ${
              pages * pageSize - pageSize + 1
            } to ${count} of ${count} entries`);
        return legend;
      } else {
        theme && theme === 'theme2'
          ? (legend = `${pages * pageSize - pageSize + 1} to ${
              pages * pageSize
            } of ${count}`)
          : (legend = `Showing ${pages * pageSize - pageSize + 1} to ${
              pages * pageSize
            } of ${count} entries`);
        return legend;
      }
    }

    if (count) {
      if (pages * 10 >= count) {
        theme && theme === 'theme2'
          ? (legend = `${pages * 10 - 10 + 1} to ${count} of ${count}`)
          : (legend = `Showing ${
              pages * 10 - 10 + 1
            } to ${count} of ${count} entries`);
        return legend;
      } else {
        theme && theme === 'theme2'
          ? (legend = `${pages * 10 - 10 + 1} to ${pages * 10} of ${count}`)
          : (legend = `Showing ${pages * 10 - 10 + 1} to ${
              pages * 10
            } of ${count} entries`);
        return legend;
      }
    } else {
      theme && theme === 'theme2'
        ? (legend = '0 to 0 of 0')
        : (legend = 'Showing 0 to 0 of 0 entries');
      return legend;
    }
  }

  /**
   * @method: dataURLtoFile
   * @desc: COnvert URL to file object
   * @param dataurl
   * @param fileName
   * @param type
   */
  dataURLtoFile(dataurl, fileName, type?: any) {
    const arr = dataurl.split(',');
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type) {
      return new File([u8arr], fileName, { type: type });
    } else {
      return new File([u8arr], fileName, { type: 'png' });
    }
  }

  /**
   * @method: strLength
   * @desc: Return ellipsis string
   * @param text
   */
  strLength(text) {
    if (text) {
      const count = 10;
      return text.slice(0, count) + (text.length > count ? '...' : '');
    } else {
      return text;
    }
  }

  /**
   * @method: UtcToLocalDt
   * @desc: Convert UTC date to locale date
   * @param utcDate
   */
  UtcToLocalDt(utcDate) {
    return new Date(utcDate).toString();
  }

  /**
   * @method: handleErrorbyFieldName
   * @desc: Generic method to handle error by field name
   * @param error
   */
  handleErrorbyFieldName(error) {
    if (!error) {
      this.toastr.error(MESSAGES.INTERNAL_SERVER_ERROR);
      return false;
    }
    if (error.hasOwnProperty('sender_entity')) {
      this.toastr.error('sender_entity is not valid.');
    }
    if (error.hasOwnProperty('receiver_entity')) {
      this.toastr.error(error.receiver_entity);
    }
    if (error.hasOwnProperty('direction')) {
      this.toastr.error(error.direction);
    }
    if (error.hasOwnProperty('msg')) {
      this.toastr.error(error.msg);
    }
    if (error.hasOwnProperty('button_text')) {
      this.toastr.error(error.button_text);
    }
    if (error.hasOwnProperty('address')) {
      this.toastr.error(MESSAGES.VOLUNTEERING_ADDRESS_ERROR);
    }
    if (error.hasOwnProperty('switch_url')) {
      this.toastr.error(error.switch_url);
    }
    if (error.hasOwnProperty('created_by')) {
      this.toastr.error(error.created_by);
    }
    if (error.hasOwnProperty('internal_name')) {
      this.toastr.error(error.internal_name);
    }
    if (error.hasOwnProperty('title')) {
      this.toastr.error(error.title);
    }
    if (error.hasOwnProperty('desc')) {
      this.toastr.error(error.desc);
    }
    if (error.hasOwnProperty('link_video')) {
      this.toastr.error(error.link_video);
    }
    if (error.hasOwnProperty('publish_end_date')) {
      this.toastr.error(error.publish_end_date);
    }
    if (error.hasOwnProperty('publish_end_time')) {
      this.toastr.error(error.publish_end_time);
    }
    if (error.hasOwnProperty('start_date')) {
      this.toastr.error(error.start_date);
    }
    if (error.hasOwnProperty('event_start_time')) {
      this.toastr.error(error.event_start_time);
    }
    if (error.hasOwnProperty('end_date')) {
      this.toastr.error(error.end_date);
    }
    if (error.hasOwnProperty('event_start_time')) {
      this.toastr.error(error.event_start_time);
    }
    if (error.hasOwnProperty('p_s_time')) {
      this.toastr.error(error.p_s_time);
    }
    if (error.hasOwnProperty('p_e_time')) {
      this.toastr.error(error.p_e_time);
    }
    if (error.hasOwnProperty('e_s_time')) {
      this.toastr.error(error.e_s_time);
    }
    if (error.hasOwnProperty('e_e_time')) {
      this.toastr.error(error.e_e_time);
    }
    if (error.hasOwnProperty('tz')) {
      this.toastr.error(error.tz);
    }
    if (error.hasOwnProperty('latitude')) {
      this.toastr.error(error.latitude);
    }
    if (error.hasOwnProperty('longitude')) {
      this.toastr.error(error.longitude);
    }
    if (error.hasOwnProperty('address')) {
      this.toastr.error(error.address);
    }
    if (error.hasOwnProperty('address')) {
      this.toastr.error(MESSAGES.VOLUNTEERING_ADDRESS_ERROR);
    }
    if (
      error === 'Error while creating the News.' ||
      'Error while creating the news.' ||
      error === 'Error while creating the Events.' ||
      error === 'Error while creating the events.' ||
      error === 'Error while creating the volunteering.' ||
      error === 'Error while creating the Volunteering.'
    ) {
      this.toastr.error(error);
    }
  }

  /**
   * @method: getEventDateTime
   * @desc: Get the event date and time
   * @param date1
   * @param date2
   * @param type
   */
  getEventDateTime(date1, date2, type?) {
    const pt = moment(date2).format('h:mm A');
    const pd = moment(date1).format('MM/DD/YYYY');
    let petime = pd + ' ' + pt;
    const formate = type === 12 ? 'MM/DD/YYYY h:mm A' : 'YYYY/MM/DD HH:mm:ss';
    petime = moment(petime).format(formate);
    return petime;
  }

  /**
   * @method: convertTo24Hour
   * @desc: Convert time to 24 hours time
   * @param time12h
   */
  convertTo24Hour(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes, sec] = time.split(':');
    hours === '12'
      ? (hours = '00')
      : modifier === 'PM' || modifier === 'pm'
      ? (hours = parseInt(hours, 10) + 12)
      : (hours = '00');
    return `${hours}:${minutes}:${sec}`;
  }

  /**
   * @method: convertUrlToBase64
   * @desc: Convert URL to base 64
   * @param url
   * @param callback
   */
  convertUrlToBase64(url, callback) {
    var d = new Date();
    url = url + '?timestamp=' + d.getTime();
    let that = this;
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };

    xhr.onerror = function () {
      that.showSpinner(false);
      that.toastr.error('Some error occured.');
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  /**
   * @method: dateFormate
   * @desc: format date
   * @param value
   */
  dateFormate(value) {
    return value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  /**
   * @method: getImageOrPdf
   * @desc: Check if base64 is image or pdf
   * @param url
   */
  getImageOrPdf(url) {
    let ext;
    var type = url.split(';')[0].split('/')[1];
    if (type == 'pdf') {
      return 'pdf';
    } else {
      ext = url.split('.').pop();
      if (ext == 'pdf') {
        return 'pdf';
      }
      return url;
    }
  }

  /**
   * @method: openPdf
   * @desc: Open pdf in new tab
   * @param url
   */
  openPdf(url: any) {
    let pdfWindow = window.open(encodeURI(url));
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='" +
        encodeURI(url) +
        "'></iframe>"
    );
  }

  /**
   * @method: openPdfFromBase
   * @desc: Open pdf in new tab by converting base64 to Blob value
   * @param base64data
   */
  openPdfFromBase(base64data: any) {
    const binary = atob(
      base64data.replace(/^data:application\/pdf;base64,/, '')
    );
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    var url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  /**
   * @method: resetOrgName
   * @desc: Reset organization name
   * @param val
   */
  resetOrgName(val: any) {
    this.resetOrgDetail.next(val);
  }

  /**
   * @method: getFieldValueFromStorage
   * @desc: get field value from object stored in localstorage in json format
   * @param objName
   */
  getFieldValueFromStorage(jsonObj: string, fieldName: string): any {
    let obj, fieldValue;
    obj = localStorage.getItem(jsonObj);
    obj = JSON.parse(obj);
    if (!obj) return null;
    fieldValue = obj[fieldName];
    if (!fieldValue) return null;
    return fieldValue;
  }

  /**
   * @method: commonMethodForHandleError
   * @desc: Method for handle error case
   * @param value
   */
  commonMethodForHandleError(err) {
    if (err === 'Unknown Error') {
      this.toastr.error(MESSAGES.INTERNAL_SERVER_ERR);
    } else {
      this.toastr.error(err);
    }
  }

  /**
   * @method: getObjectFromStorage
   * @desc: get object from localstorage in json format
   * @param objName
   */
  getObjectFromStorage(objName: string) {
    let obj;
    obj = localStorage.getItem(objName);
    obj = JSON.parse(obj);
    if (!obj) return null;
    return obj;
  }

  /**
   * @method: copyClipBoard
   * @desc: Copy url to the clipboard
   */
  copyClipBoard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /*
   * @method: getPublishDate
   * @desc: Method using get pushlish date time and on content list.
   * @param publiShstartSate, publishEndDate
   */
  getPublishDate(publiShstartSate, publishEndDate) {
    const m = moment(publiShstartSate);
    m.format();
    if (
      publishEndDate !== null &&
      publishEndDate !== undefined &&
      publishEndDate !== ''
    ) {
      const ns = moment(publishEndDate);
      ns.format();
      return m.format('MM/DD/YY h:mm A') + ' - ' + ns.format('MM/DD/YY h:mm A');
    } else {
      return m.format('MM/DD/YY h:mm A');
    }
  }

  /*
   * @method: getLastUpdation
   * @desc: Method using formate last update date.
   * @param LastUpdation
   */
  getLastUpdation(LastUpdation) {
    const m = moment(LastUpdation);
    return m.format('MM/dd/YYYY h:mm a');
  }

  /*
   * @method: getStatus
   * @desc: Method using get status text as per status.
   * @param status
   */
  getStatus(status) {
    if (status === '1') {
      return 'Active';
    } else if (status === '2') {
      return 'Upcoming';
    } else if (status === '3') {
      return 'Expired';
    } else if (status === '4') {
      return 'Draft';
    } else if (status === '5') {
      return 'Inactive';
    }
  }

  /*
   * @method: formatLocalNotiDate
   * @desc: Method using for compare two date for notification table
   * @param scheduledTime
   */
  compareDates(scheduledTime: any) {
    if (new Date(scheduledTime).getTime() > new Date().getTime()) {
      return 'Scheduled';
    } else if (new Date(scheduledTime).getTime() <= new Date().getTime()) {
      return 'Sent';
    } else {
      return '---';
    }
  }

  /**
   * @method: noWhitespaceValidator
   * @desc: Method using check and validate space on input form
   */
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  /**
   * @method: checkImageResolution
   * @desc: Common method to check the resolution of image and convert the resolution
   * @param event
   */
  async checkImageResolution(event, files) {
    let that = this;
    let promise = new Promise((resolve, reject) => {
      var image = new Image();
      image.src = event.target.result;
      image.onload = function () {
        var height = this['height'];
        var width = this['width'];
        if (height < 360 || width < 640) {
          const initialState = { files, showWarning: true };
          that.customImageSizer = that.modalService.show(
            CustomImageResizerComponent,
            {
              class: 'custom-dialog',
              keyboard: false,
              backdrop: true,
              ignoreBackdropClick: true,
              initialState,
            }
          );
          that.customImageSizer.content.onClose.subscribe((base) => {
            resolve(base);
          });
        } else {
          resolve(event.target.result);
        }
      };
    });
    let result = await promise;
    return result;
  }

  /**
   * @method: encodeUrl
   * @desc: Encode the URL
   * @param url
   */
  encodeUrl(url: string) {
    let link = String(encodeURI(url));
    let myStyles = {
      'background-image': `url("${link}")`,
    };
    return myStyles;
  }

  /**
   * @method: focusOn
   * @desc: Method used for focus on input field dynamically.
   * @param fieldName
   */
  focusOn(fieldName) {
    const commmentBoxes = document.querySelectorAll(`.${fieldName}`);
    for (let i in commmentBoxes) {
      const element: any = commmentBoxes[i];
      const attributs = element.attributes;
      for (let j in attributs) {
        const attribute = attributs[j];
        if (
          attribute.nodeName === 'class' &&
          attribute.nodeValue.includes(fieldName)
        ) {
          element.focus();
        }
      }
    }
  }

  /**
   * @method: makeFirstLetterCapital
   * @desc: Method used make first letter capital.
   * @param value
   */
  makeFirstLetterCapital(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  /**
   * @method: downloadTable
   * @desc: Download the list/ table
   */
  downloadTable(data: any) {
    const path = data.data;
    window.open(`${environment.backend_server_url}${path}`, '__blank');
  }

  /**
   * @method: renderAssets
   * @desc: render assets as per the url
   */
  renderAssets() {
    let currentDomain = window.location.origin,
      folderName,
      appId,
      title,
      platformName,
      PortalName;
    if (
      environment.isLive &&
      currentDomain.indexOf('boilermakersapp.com') > -1
    ) {
      folderName = 'ibb';
      appId = 3;
      title = 'IBB Hub';
      platformName = 'Boilermakers';
      PortalName = 'Boilermakers';
    } else if (currentDomain.indexOf('ibb.unionstrongapp.com') > -1) {
      folderName = 'ibb';
      appId = 3;
      title = 'IBB Hub';
      platformName = 'Boilermakers';
      PortalName = 'Boilermakers';
    } else if (currentDomain.indexOf('nysbctcapp') > -1) {
      folderName = 'nysbctc';
      appId = 5;
      title = 'NYS BCTC Hub';
      platformName = 'NYSBCTC';
      PortalName = 'NYSBCTC';
    } else if (
      currentDomain.indexOf('soa') > -1 ||
      currentDomain.indexOf('soalocal444') > -1
    ) {
      folderName = 'soa';
      appId = 6;
      title = 'SOA Hub';
      platformName = 'SOA';
      PortalName = 'SOA';
    } else if (
      currentDomain.indexOf('mydiscoveryapp') > -1 ||
      currentDomain.indexOf('myddapp') > -1
    ) {
      folderName = 'mydiscovery';
      appId = 7;
      title = 'MyDiscovery Hub';
      platformName = 'MyDiscovery';
      PortalName = 'MyDiscovery';
    } else if (
      currentDomain.indexOf('mycharityapp') > -1 ||
      currentDomain.indexOf('charityhowtoapp') > -1
    ) {
      folderName = 'mycharity';
      appId = 8;
      title = 'CharityHowTo Hub';
      platformName = 'CharityHowTo';
      PortalName = 'CharityHowTo';
    } else if (currentDomain.indexOf('fdny2507app') > -1) {
      folderName = 'fdny';
      appId = 9;
      title = 'FDNY Hub';
      platformName = 'FDNY';
      PortalName = 'FDNY';
    } else if (currentDomain.indexOf('ibew332app') > -1) {
      folderName = 'ibew';
      appId = 10;
      title = 'IBEW Hub';
      platformName = 'IBEW';
      PortalName = 'IBEW';
    } else if (currentDomain.indexOf('ibew441app') > -1) {
      folderName = 'ibew441';
      appId = 11;
      title = 'IBEW 441 Hub';
      platformName = 'IBEW 441';
      PortalName = 'IBEW 441';
    } else if (currentDomain.indexOf('tahraapp') > -1) {
      folderName = 'tahra';
      appId = 12;
      title = 'TAHRA Hub';
      platformName = 'TAHRA';
      PortalName = 'TAHRA';
    } else if (currentDomain.indexOf('ibew1049app') > -1) {
      folderName = 'ibew1049';
      appId = 13;
      title = 'IBEW 1049 Hub';
      platformName = 'IBEW 1049';
      PortalName = 'IBEW 1049';
    } else if (currentDomain.indexOf('jewishdallasapp') > -1) {
      folderName = 'jewishdallas';
      appId = 14;
      title = 'Jewish Dallas Hub';
      platformName = 'Jewish Dallas';
      PortalName = 'Jewish Dallas';
    } else if (currentDomain.indexOf('mebaunion') > -1) {
      folderName = 'meba';
      appId = 15;
      title = 'MEBA Hub';
      platformName = 'MEBA';
      PortalName = 'MEBA';
    } else if (currentDomain.indexOf('ripleyha') > -1) {
      folderName = 'ripleyhousing';
      appId = 16;
      title = 'Ripley Housing Hub';
      platformName = 'Ripley Housing';
      PortalName = 'Ripley Housing';
    } else if (currentDomain.indexOf('ibew363') > -1) {
      folderName = 'ibew363';
      appId = 17;
      title = 'IBEW363 Hub';
      platformName = 'IBEW363';
      PortalName = 'IBEW363';
    } else if (currentDomain.indexOf('hcta') > -1) {
      folderName = 'hcta';
      appId = 18;
      title = 'Hillsborough Classroom Teachers Association Hub';
      platformName = 'Hillsborough Classroom Teachers Association';
      PortalName = 'Hillsborough Classroom Teachers Association';
    } else if (currentDomain.indexOf('nasrccnys') > -1) {
      folderName = 'nasrccnys';
      appId = 19;
      title = 'New York State NASRCC Hub';
      platformName = 'New York State NASRCC';
      PortalName = 'New York State NASRCC';
    } else if (currentDomain.indexOf('seiu284') > -1) {
      folderName = 'seiu284';
      appId = 20;
      title = 'SEIU284 Hub';
      platformName = 'SEIU284';
      PortalName = 'SEIU284';
    } else if (currentDomain.indexOf('unionstrongapp.com') > -1) {
      folderName = 'unionstrong';
      appId = 2;
      title = 'Union Strong Hub';
      platformName = 'Union Strong';
      PortalName = 'Union Strong';
    } else if (currentDomain.indexOf('iuecwa') > -1) {
      folderName = 'iuecwa';
      appId = 21;
      title = 'IUE-CWA Hub';
      platformName = 'IUE CWA';
      PortalName = 'IUE CWA';
    } else if (currentDomain.indexOf('teamsters577') > -1) {
      folderName = 'teamsters577';
      appId = 22;
      title = 'Teamsters Local 577 Hub';
      platformName = 'Teamsters Local 577';
      PortalName = 'Teamsters Local 577';
    } else if (currentDomain.indexOf('ibew777') > -1) {
      folderName = 'ibew777';
      appId = 23;
      title = 'IBEW Local 777 Hub';
      platformName = 'IBEW Local 777';
      PortalName = 'IBEW Local 777';
    } else if (currentDomain.indexOf('pcaky') > -1) {
      folderName = 'pcaky';
      appId = 24;
      title = 'Prevent Child Abuse KY Hub';
      platformName = 'Prevent Child Abuse KY';
      PortalName = 'Prevent Child Abuse KY';
    } else if (currentDomain.indexOf('theoke') > -1) {
      folderName = 'theoke';
      appId = 25;
      title = 'The OKE Hub';
      platformName = 'The OKE';
      PortalName = 'The OKE';
    } else if (currentDomain.indexOf('nyspta') > -1) {
      folderName = 'nyspta';
      appId = 26;
      title = 'NYS PTA Hub';
      platformName = 'NYS PTA';
      PortalName = 'NYS PTA';
    } else if (currentDomain.indexOf('homes4good') > -1) {
      folderName = 'homes4good';
      appId = 27;
      title = 'Homes4Good Hub';
      platformName = 'Homes4Good';
      PortalName = 'Homes4Good';
    } else if (currentDomain.indexOf('hhfb') > -1) {
      folderName = 'hhfb';
      appId = 28;
      title = 'Healthy Harvest Food Bank Hub';
      platformName = 'Healthy Harvest Food Bank';
      PortalName = 'Healthy Harvest Food Bank';
    } else if (currentDomain.indexOf('amha') > -1) {
      folderName = 'amha';
      appId = 29;
      title = 'Akron Housing Authority Hub';
      platformName = 'Akron Housing Authority';
      PortalName = 'Akron Housing Authority';
    } else if (currentDomain.indexOf('twu100') > -1) {
      folderName = 'twu100';
      appId = 30;
      title = 'Twu100 Hub';
      platformName = 'Twu100';
      PortalName = 'Twu100';
    } else if (currentDomain.indexOf('iubac') > -1) {
      folderName = 'iubac';
      appId = 31;
      title = 'Bricklayers Hub';
      platformName = 'Brickalayers';
      PortalName = 'Brickalayers';
    } else if (currentDomain.indexOf('johnstownha') > -1) {
      folderName = 'johnstownha';
      appId = 32;
      title = 'Johnstown Housing Authority Hub';
      platformName = 'Johnstown Housing Authority';
      PortalName = 'Johnstown Housing Authority';
    } else if (currentDomain.indexOf('familyofficeinsights') > -1) {
      folderName = 'familyofficeinsights';
      appId = 33;
      title = 'Family Office Insights Hub';
      platformName = 'Family Office Insights';
      PortalName = 'Family Office Insights';
    } else if (currentDomain.indexOf('allentownha') > -1) {
      folderName = 'allentownha';
      appId = 34;
      title = 'Allentown Housing Authority Hub';
      platformName = 'Allentown Housing Authority';
      PortalName = 'Allentown Housing Authority';
    } else if (currentDomain.indexOf('ibew1249') > -1) {
      folderName = 'ibew1249';
      appId = 35;
      title = 'IBEW Local 1249 Hub';
      platformName = 'IBEW Local 1249';
      PortalName = 'IBEW Local 1249';
    } else if (currentDomain.indexOf('ibew125') > -1) {
      folderName = 'ibew125';
      appId = 36;
      title = 'IBEW Local 125 Hub';
      platformName = 'IBEW Local 125';
      PortalName = 'IBEW Local 125';
    } else if (currentDomain.indexOf('clarionctyhra') > -1) {
      folderName = 'clarionctyhra';
      appId = 37;
      title = 'Clarion County Housing Authority Hub';
      platformName = 'Clarion County Housing Authority';
      PortalName = 'Clarion County Housing Authority';
    } else if (currentDomain.indexOf('letsalldogood.com') > -1) {
      folderName = 'ladg';
      appId = 4;
      title = 'LADG Hub';
      platformName = 'LetsAllDoGood';
      PortalName = 'LADG';
    } else {
      folderName = 'labh';
      appId = 1;
      title = 'LABH Hub';
      platformName = 'LetsAllBeHeard';
      PortalName = 'LABH';
    }

    return {
      appId: appId,
      app_name: folderName,
      logo: `/assets/images/${folderName}/logo.png`,
      apple_touch_icon: `/assets/images/${folderName}/apple-touch-icon.png`,
      fav_16: `/assets/images/${folderName}/favicon-16x16.png`,
      fav_32: `/assets/images/${folderName}/favicon-32x32.png`,
      title: title,
      platformName: platformName,
      PortalName: PortalName,
      folderName: folderName,
    };
  }

  /**
   * @method: success
   * @param res
   * @desc: handle success Response
   */
  success(res) {
    const status = res.body.data.responseCode || res.body.status_code;
    if (status !== 200) {
      this.showSpinner(false);
      if (res && res.body) this.toastr.error(res.body.data.error);
      return;
    }
    return res.body.data ? res.body.data : '';
  }

  /**
   * @method: redirectToDomain
   * @desc: redirect to different domain
   * @param organizationType
   * @param token
   * @param cb
   */
  redirectToDomain(organizationType: any, token: string, cb: Function) {
    let redirectTo,
      differentUrl = false;
    const currentOrigin = window.location.origin;
    if (!organizationType) return;
    if (organizationType === 3) {
      redirectTo = environment.ibbDomain;
    } else if (organizationType === 2) {
      redirectTo = environment.unionstrongDomain;
    } else if (organizationType === 4) {
      redirectTo = environment.ladgDomain;
    } else if (organizationType === 5) {
      redirectTo = environment.nysbctcDomain;
    } else if (organizationType === 6) {
      redirectTo = environment.soaDomain;
    } else if (organizationType === 7) {
      redirectTo = environment.mydiscoveryDomain;
    } else if (organizationType === 8) {
      redirectTo = environment.mycharityDomain;
    } else if (organizationType === 9) {
      redirectTo = environment.fdnyDomain;
    } else if (organizationType === 10) {
      redirectTo = environment.ibewDomain;
    } else if (organizationType === 11) {
      redirectTo = environment.ibew441Domain;
    } else if (organizationType === 12) {
      redirectTo = environment.tahraDomain;
    } else if (organizationType === 13) {
      redirectTo = environment.ibew1049Domain;
    } else if (organizationType === 14) {
      redirectTo = environment.jewishdallasDomain;
    } else if (organizationType === 15) {
      redirectTo = environment.mebaDomain;
    } else if (organizationType === 16) {
      redirectTo = environment.ripleyhousingDomain;
    } else if (organizationType === 17) {
      redirectTo = environment.ibew363Domain;
    } else if (organizationType === 18) {
      redirectTo = environment.hillsboroughDomain;
    } else if (organizationType === 19) {
      redirectTo = environment.nasrccDomain;
    } else if (organizationType === 20) {
      redirectTo = environment.seiu284Domain;
    } else if (organizationType === 21) {
      redirectTo = environment.iuecwaDomain;
    } else if (organizationType === 22) {
      redirectTo = environment.teamsters577Domain;
    } else if (organizationType === 23) {
      redirectTo = environment.ibew777Domain;
    } else if (organizationType === 24) {
      redirectTo = environment.pcakyDomain;
    } else if (organizationType === 25) {
      redirectTo = environment.theokeDomain;
    } else if (organizationType === 26) {
      redirectTo = environment.nysptaDomain;
    } else if (organizationType === 27) {
      redirectTo = environment.homes4goodDomain;
    } else if (organizationType === 28) {
      redirectTo = environment.healthyharvestfbDomain;
    } else if (organizationType === 29) {
      redirectTo = environment.akronmetropolitanhaDomain;
    } else if (organizationType === 30) {
      redirectTo = environment.twu100Domain;
    } else if (organizationType === 31) {
      redirectTo = environment.iubacDomain;
    } else if (organizationType === 32) {
      redirectTo = environment.johnstownhaDomain;
    } else if (organizationType === 33) {
      redirectTo = environment.familyofficeinsightsDomain;
    } else if (organizationType === 34) {
      redirectTo = environment.allentownhaDomain;
    } else if (organizationType === 35) {
      redirectTo = environment.ibew1249Domain;
    } else if (organizationType === 36) {
      redirectTo = environment.ibew125Domain;
    } else if (organizationType === 37) {
      redirectTo = environment.clarionctyhraDomain;
    } else {
      redirectTo = environment.labhDomain;
    }
    if (currentOrigin !== redirectTo) {
      localStorage.clear();
      differentUrl = true;
      window.open(`${redirectTo}?token=${token}`, '_self');
      cb(differentUrl);
    }
    {
      cb(differentUrl);
    }
  }

  /**
   * @method: validateNotificationDates
   * @desc: Method using for validte notification date
   * @param publishStartDate
   * @param publishEndDate
   */
  validateNotificationDates(push, publishStartDate, publishEndDate) {
    return (
      new Date(push.scheduled_time) >= new Date(publishStartDate) &&
      new Date(push.scheduled_time) <= new Date(publishEndDate)
    );
  }

  /**
   * @method: formateMomentToNewDate
   * @desc: Method using for formate date in to moment and then new Date function
   * @param date, format
   */
  formateMomentToNewDate(date, format) {
    let newDate = moment(new Date(date)).format(format);
    const _dt: any = new Date(newDate);
    if (isNaN(_dt)) {
      const formatTo =
        format.indexOf('mm') > -1 ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
      newDate = moment(new Date(date)).format(formatTo);
      return new Date(newDate);
    } else {
      return _dt;
    }
  }

  /**
   * @method: prepareContentPayloadDate
   * @desc: Method using for formate date and time using moment and combine date time
   * @param date, time, format
   */
  prepareContentPayloadDate(date, time, format) {
    const pd = moment(date).format('MM/DD/YYYY');
    const pt = moment(time).format('h:mm A');
    let fdate = pd + ' ' + pt;
    return moment(fdate).format(format);
  }

  /**
   * @method: getChartSettings
   * @desc: Get chart settings
   */
  getChartSettings(type: string) {
    let borderColor, backgroundColor;
    if (type == 'followers') {
      backgroundColor = '#e8f3fa';
      borderColor = '#3a99da';
    } else if (
      type == 'posts' ||
      type == 'views' ||
      type == 'share' ||
      type == 'calendar'
    ) {
      backgroundColor = '#f4ecf7';
      borderColor = '#9a5bb4';
    } else if (type == 'send' || type == 'opens') {
      backgroundColor = '#eaf7f5';
      borderColor = '#62ba9c';
    }
    return {
      label: '',
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      pointBackgroundColor: backgroundColor,
      pointBorderColor: borderColor,
    };
  }

  /**
   * @method: getGraphConf
   * @desc: return the settings for chart JS
   */
  getGraphConf() {
    return {
      tooltips: {
        custom: (tooltip) => {
          if (!tooltip) return;
          tooltip.displayColors = false;
        },
        callbacks: {
          label: (tooltipItem) => {
            return `Count: ${tooltipItem.value}`;
          },
        },
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '# of people',
              fontSize: 20,
              fontStyle: 'bold',
            },
            gridLines: {
              drawOnChartArea: false,
            },
            ticks: {
              padding: 10,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontSize: 20,
              fontStyle: 'bold',
            },
            gridLines: {
              drawOnChartArea: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
              padding: 10,
            },
          },
        ],
      },
    };
  }

  /**
   * @method: showLoaderWithId
   * @desc: show loader with API id
   * @param show
   * @param id
   */
  showLoaderWithId(id: string) {
    var loader = document.getElementsByClassName('showLodings')[0];
    var loader1: HTMLElement;
    loader1 = loader.cloneNode(true) as HTMLElement;
    if (loader1) {
      loader1.setAttribute('id', id);
      loader1.setAttribute('class', 'showLodings custom-loader');
      loader1.removeAttribute('hidden');
      document.body.appendChild(loader1);
    }
  }

  /**
   * @method: hideLoaderWithId
   * @desc: Hide loader with provided ID
   * @param id
   */
  hideLoaderWithId(id: string) {
    var loader: HTMLElement;
    loader = document.getElementById(id);
    if (loader) loader.remove();
  }

  /**
   * @method: removeAllCustomLoader
   * @desc: Remove all the custom loaders
   */
  removeAllCustomLoader() {
    var loaders: HTMLCollection;
    loaders = document.getElementsByClassName('custom-loader');
    if (loaders) {
      for (let i = 0; i < loaders.length; i++) {
        loaders[0].remove();
      }
    }
  }

  /**
   * @method: getTimeDifferenceInMinutes
   * @desc: method to get the time difference in minutes
   */
  getTimeDifferenceInMinutes(d1: Date, d2: Date) {
    d1.setSeconds(0);
    d2.setSeconds(0);
    const msec = d1.getTime() - d2.getTime();
    return Math.floor(msec / 60000);
  }

  /**
   * @method: pushModalRefs
   * @desc: push all the modal refs in one array
   * @param ref
   */
  pushModalRefs(ref: BsModalRef) {
    this.modalRefs.push(ref);
  }

  /**
   * @method: hideAllModals
   * @desc: check and hide all the modals opened while session log out
   */
  hideAllModals() {
    this.modalRefs.forEach((modal) => {
      if (modal) modal.hide();
    });
  }
}
