import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { CoreModule } from '../../shared/modules/core/core.module';
import { CookieService } from 'ngx-cookie-service';
@NgModule({
  declarations: [
    LoginComponent,
    ForgotpasswordComponent,
    CreatePasswordComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule
  ],
  providers: [CookieService]
})
export class AuthenticationModule { }
