export const MESSAGES = {
    RESET_PASSWORD_EMAIL_SENT: 'We have e-mailed your password reset link!',
    PASSWORD_RESET_SUCCESS: 'Password reset successfully.',
    PASSWORD_CREATE_SUCCESS: 'Password created successfully.',
    INVALID_PASSWORD_LINK: 'The password create link is no longer valid.',
    INVALID_TOKEN: 'Token not associated with any user.',
    UNKNOWN_ERROR: 'Internet not available, please check your internet connectivity and try again.',
    AFFILIATION_ADD: 'Affiliation created successfully.',
    AFFILIATION_UPDATE: 'Affiliation updated successfully.',
    NORMAL_ERROR: 'Some error occured..',
    INTERNET_CONNECTION: 'Internet not available, please check your internet connectivity and try again.',
    CONVERT_USER_SUCCESS: 'User converted to Admin successfully.',
    CONVERT_USER_ERR: 'Error while converting user to Admin.',
    USER_DISABLED: 'User disabled successfully.',
    USER_ENABLED: 'User enabled successfully.',
    ERR_CONNECTING_SERVER: 'Some problem occure to connect to the server.',
    CREATE_USER_SUCCESS: 'User created successfully.',
    UPDATE_USER_SUCCESS: 'User updated successfully.',
    UPDATE_PROFILE_SUCCESS: 'Profile updated successfully.',
    PASSWORD_CHANGE_SUCCESS: 'Password changed successfully.',
    ARE_YOU_SURE: 'Are you sure?',
    LIBRARY_REMOVE_WARN: 'By removing image from SA library, Org Admins will no longer have access to the image',
    IMG_DEL_SUCCESS: 'Image deleted successfully.',
    IMG_TYPE_ALERT: 'Please upload JPEG, PNG file.',
    IMG_Error_MSG:'Allowed size is Max 10MB.',
    IMG_UPLOAD_SUCCESS: 'Image uploaded successfully.',
    ONLINE: 'You are online now.',
    DEACTIVE_ORG_MSG: 'You want to deactivate the organization?',
    ACTIVE_ORG_MSG: 'You want to activate the organization?',
    LOGIN_AS_ADMIN: 'You want to login as organization admin?',
    LOGIN_ERROR: 'No account associated with this username or password',
    FORGETPASSWORD_ERROR: 'No user associated',
    INTERNAL_SERVER_ERROR: 'Sorry, there was a problem connecting to the server.',
    DELETE_CONFIMATION: 'Deleting this cannot be undone',
    VOLUNTEERING_DELETE: 'Volunteering deleted successfully',
    EVENTS_DELETE: 'Event deleted successfully',
    NEWS_DELETE: 'News deleted successfully',
    VOLUNTEERING_DELETED_SUCCESS: 'Volunteering deleted successfully.',
    NEWS_DELETED_SUCCESS: 'News deleted successfully.',
    EVENT_DELETED_SUCCESS: 'Event deleted successfully.',
    POST_DELETE_WARN: 'Deleting this cannot be undone.',
    PIN_DONE: 'Content has been pinned.',
    PIN_UNDONE: 'Content has been unpinned.',
    REQUEST_NOT_FOUND: 'Request not found.',
    ERROR_CONNECTING_SERVER: 'Sorry, there was a problem connecting to the server.',
    ORG_CREATE_ERR: 'Error occuring while creating organization.',
    ORG_EDIT_ERR: 'Error occuring while creating organization.',
    ORG_CREATE_SUCCESS: 'Organization created successfully.',
    ORG_UPDATE_SUCCESS: 'Organization updated successfully',
    MEDIA_UPLOAD_ERR: 'Error while uploading image.',
    ORG_ADD_ERR: 'Error while creating Entity.',
    ORG_UPDATE_ERR: 'Error while updating Entity.',
    INVALID_MEDIA_TYPE: 'Please upload JPEG, PNG or PDF file.',
    PARTNER_REQUEST_ACCEPT_ERR: 'Please select at least one organization to accept request.',
    PARTNER_REQUEST_CANCEL_ERR: 'Please select at least one organization to cancel request.',
    PARTNER_REQUEST_REJECT_ERR: 'Please select at least one organization to decline request.',
    subscription_accept: 'Please select at least one organization to accept the request.',
    subscription_cancel: 'Please select at least one organization to cancel the request.',
    subscription_reject: 'Please select at least one organization to decline the request.',
    SAVE_LIST_NAME_REQUIRED: 'List name is required.',
    SAVE_LIST_UPDATE: 'List updated successfully.',
    SAVE_LIST_ADD: 'List created successfully.',
    SAVE_LIST_DELETE_ERROR: 'Please select any save list to delete.',
    SAVE_LIST_DELETE: 'Selected list deleted successfully.',
    SAVE_LIST_SELECT: 'Please select a saved list',
    SAVE_LIST_CREATE_ERROR: 'Please select at least one organization to create a list.',
    PARTNER_CONFIMATION_CANCLE: 'Cancel this Connection Request?',
    PARTNER_CONFIMATION_ACCEPT: 'Accept this Connection Request?',
    PARTNER_CONFIMATION_DECLINE: 'Decline this Connection Request?',
    PARTNER_CONFIMATION_DISCONTINUE: 'Disconnect this FM partnership ?',
    PARTNER_CONFIMATION_SUBMIT_ERROR: 'Please approve or decline this content to send to your followers.',
    PARTNER_REQUEST_ACCEPT: 'Partner request accepted successfully.',
    PARTNER_REQUEST_CANCLE: 'Partner request cancelled successfully.',
    PARTNER_REQUEST_DECLINE: 'Partner request declined successfully.',
    NEW_PARTNER_SUBMIT_ERROR: 'Please select at least one organization to send request.',
    NEW_PARTNERSHIP_SUBMIT_ERROR: 'Please select at least one organization for Partnership',
    NEW_SUBSCRIPTION_SUBMIT_ERROR: 'Please select at least one Feed & one Partner for Subscription',
    DELETE_NOTIFICATION_CONFIRM: 'Do you really want to delete this notification?',
    NOTIFICATION_ARCHIVE: 'Push notification archived successfully.',
    NOTIFICATION_UNARCHIVE: 'Push notification unarchived successfully.',
    NOTIFICATION_COPY: 'Push items copied successfully.',
    FORCE_MULTIPLIER_CONNECTION_ERROR: 'Please select at least one organization to send a connection request to.',
    FORCE_MULTIPLIER_MESSAGE_ERROR: 'Maximum message length is 150 characters.',
    NOTIFICATION_SCHEDULE_DATE_TIME_ERROR: 'Notification schedule date/time should be greater than post live date',
    NOTIFICATION_ADDED_SUCCESS: 'Notification added successfully.',
    NOTIFICATION_UPDATE_SUCCESS: 'Notification updated successfully',
    INVAILD_DATE_ERROR: 'Notification updated successfully',
    END_DATE_INVAILD: 'End date should be greater than and equal to current date.',
    SCHEDULE_DATE_INVAILD: 'Schedule date should be greater than and equal to current date.',
    FM_CONFIRMATION: 'Please approve or decline this content to send to your followers.',
    SECURE_URL: 'You are not authorized to use this URL.',
    INTERNAL_SERVER_ERR: 'Internal Server Error!',
    SECURE_SA_URL: 'You are not authorized to use this Super Admin URL.',
    SECURE_ADMIN_URL: 'You are not authorized to use this Admin URL.',
    Active: 'activated successfully.',
    Inactive: 'deactivated successfully.',
    NEWS_COPY_SUCCESS: 'News items copied successfully.',
    INVAILD_DATE: 'Please select valid date.',
    POST_EXPIRATION_ERROR: 'Post expiration date should be greater than or equal to current date.',
    POST_LIVE_ERROR: 'Post live date should be greater than or equal to current date.',
    IMAGE_ADD_ERROR: 'Sorry, you cannot add more than five (5) images.',
    PDFS_ADD_ERROR: 'Sorry, you cannot add more than five (5) Pdfs.',
    IMAGE_EXT_ERROR: 'Please upload JPEG, PNG or PDF file.',
    IMAGE_UPLOAD_ERROR: 'Please upload JPG, JPEG, PNG file.',
    PDF_UPLOAD_ERROR: 'Please upload PDF file.',
    PDF_EXT_ERROR: 'Please upload PDF file.',
    POST_EXPIRATION_TIME_ERROR: 'Post expiration time should be greater than post live time',
    FM_SEND_POST_PARTNER: 'ForceMultiplier: Send post to selected partners?',
    NEWS_ADD_SUCCESSFULLY: 'News added successfully',
    NEWS_UPDATE_SUCCESSFULLY: 'News updated successfully',
    SAVE_LIST_GROUP_ERROR: 'List associated to this post is delete. Do you want to reactivate such a post?',
    NOTIFICATION_DELETE_CONFIMATION: 'Notifications scheduled after the post start & end date will be deleted?',
    EVENTS_ACTIVATE_SUCCESS: 'Event activated successfully.',
    EVENTS_DEACTIVATE_SUCCESS: 'Event deactivated successfully.',
    EVENTS_COPY_SUCCESS: 'Event items copied successfully.',
    FM_REQUEST_SAVED: 'Request saved successfully.',
    EVENT_START_DATE_ERROR: 'Event start date should be greater than or equal to current date.',
    EVENT_END_DATE_ERROR: 'Event end date should be greater than or equal to current date.',
    EVENT_END_GREATER_ERROR: 'Event end time should be greater than Event start time',
    POST_EXPIRATION_GREATER_ERROR: 'Post expiration time should be greater than Event end time',
    EVENT_START_GREATER_ERROR: 'Event start date should be greater than post live time',
    EVENT_END_DATE_GREATER_ERROR: 'Event end date should be greater than post live time',
    EVENT_ADDED_SUCCESSFULLY: 'Event added successfully',
    EVENT_UPDATE_SUCCESSFULLY: 'Event updated successfully',
    VOLUNTEERING_ACTIVATE: 'Volunteering activated successfully.',
    VOLUNTEERING_DEACTIVATE: 'Volunteering deactivated successfully.',
    VOLUNTEERING_COPY_SUCCESS: 'Volunteering items copy successfully.',
    VOLUNTEERING_START_DATE_ERROR: 'Volunteer start date should be greater than post live time',
    VOLUNTEERING_END_DATE_ERROR: 'Volunteer end date should be greater than post live time',
    VOLUNTEERING_ADDRESS_ERROR: 'Please enter a vaild address.',
    VOLUNTEERING_ADD_SUCCESSFULLY: 'Volunteering added successfully',
    VOLUNTEERING_START_DATE_ERROR_DATE: 'Volunteer start date should be greater than or equal to current date.',
    VOLUNTEERING_END_DATE_ERROR_DATE: 'Volunteer end date should be greater than or equal to current date.',
    VOLUNTEERING_END_DATE: 'Volunteer end time should be greater than Volunteer start time',
    VOLUNTEERING_POST_EXPIRATION_ERROR: 'Post expiration time should be greater than Volunteer end time',
    VOLUNTEERING_UPDATE_SUCCESSFULLY: 'Volunteering updated successfully',
    SMALL_IMAGE: 'Image resultion is less than 640*360, Do you want us to change resolution to 640*360.',
    DELETE_ORG_CONFIRM: 'You want to delete the organization ?',
    ORG_COLOR_CHANGE: 'You want to change the org type?',
    FOLLOWERS_TAG_UNIQUE: 'MyPeople tag name should be unique.',
    FOLLOWERS_PUBLISH_CONFIRM: 'Saving Follower Tags will update the Follower registration process.',
    FOLLOWERS_MULTI_TO_SINGLE: 'Follower Tags cannot be changed from Multi Select to Single Select if there are users with 2  or more tags. The following users have 2 or more tags',
    FOLLOWERS_PROCEED: 'Do you want to proceed?',
    FOLLOWERS_DISCARD: 'Discard changes to Follower Tags ?',
    FOLLOWERS_REMOVE: 'Are you sure you want to delete this tag?',
    BLOCK_USER: 'Block this user?',
    UNBLOCK_USER: 'Unblock this user?',
    REMOVE_FOLLOWER: 'Are you sure you want to remove this user?',
    THIS_CANNOT_WARNING: 'This cannot be undone.',
    APPROVAL_ERROR: 'Please select at least one tag for approval.',
    DISCARD_CHANGES: 'Discard changes made to user?',
    SAVE_CHANGES: 'Save changes made to user?',
    INVALID_ZIP_CODE: 'Please enter a valid ZIP/POSTAL Code',
    DELETE_LIST: 'Are you sure you want to delete this list?',
    RENAMED_SUCCESSFULLY: 'List name was updated successfully',
    LIST_COUNT_ERROR: 'List does not contain members to view.',
    postLiveDateError: 'Post live date should be greater than or equal to current date.',
    postExpirationError: 'Post expiration date should be greater than or equal to current date.',
    eventStartDateError: 'Event start date should be greater than or equal to current date.',
    eventEndDateError: 'Event end date should be greater than or equal to current date.',
    CONTENT_CREATION_SUCCESS: "Content created successfully",
    CONTENT_UPDATE_SUCCESS: "Content updated successfully",
    CONTENT_COPY_SUCCESS: 'Content copied successfully.',
    NO_SAVE_LIST_PARTNER: 'No partner associated of this list.',
    ACCEPT_AND_AMPLIFY_CONTENT: "After accepting your partner's content, you will be able to configure push notifications, select MyPeople audience and re-ForceMutiply to your network!",
    ACCEPT_AND_AMPLIFY_CONTENT_HEADING: "Accept & Configure",
    COPY_CONTENT_MODAL_HEADING: 'Copy To My Content',
    COPY_CONTENT_MODAL_DESCRIPTION: "After copying your partner's content, you will be able to configure push notifications, select MyPeople audience and ForceMutiply to your network!",
    FOLLOWERS_ERROR: "Please select atleast one MyPeople.",
    HEADING_SUPURPUSH_CONFIRM: "Publish Content",
    SUBHEADING_SUPURPUSH_CONFIRM: "Are you sure you want to publish content?",
    CONTENT_SUPURPUSH_CONFIRM: "As you have opted FM Only, published content will not be sent to selected MyPeople.",
    PUBLISH_NOW_CONTENT: "If you publish now, this content will be published to all your followers. After publishing, you will not be able to configure push notifications or re-ForceMultiply to your network. <span class='d-block'>This action cannot be undone. Do you wish to continue?</span>",
    FM_ERROR_REAMPLIFY: "Expired content will not be amplified.",
    FM_ERROR_ACCEPT: "Expired content will not be accepted.",
    SUBSCRIPTION_REQUEST_ERROR: "Please select at least one organization to send request.",
    MODAL_HEADER_PHONE_NUMBER: 'Enable Two-Factor Authentication',
    MODAL_HEADER_OTP_NUMBER: 'Enter OTP (One-Time Password)',
    error_accept: 'Subscription request accepted successfully.',
    error_reject: 'Subscription request declined successfully.',
    error_cancel: 'Subscription request cancelled successfully.',
    accept: 'Are you sure you want to accept this request?',
    reject: 'Are you sure you want to decline this request?',
    cancel: 'Are you sure you want to cancel this request?',
    ACCEPT_SUBSCRIPTION_REQUEST: 'Are you sure you want to accept this request?',
    REJECT_SUBSCRIPTION_REQUEST: 'Are you sure you want to decline this request?',
    CANCEL_SUBSCRIPTION_REQUEST: 'Are you sure you want to cancel this request?',
    UNSUBSCRIBE_REQUEST: 'Are you sure you want to unsubscribe this request?',
    TWO_FACTOR_AUTH_SUCCESS: 'Two factor authentication is enabled successfully',
    TWO_FACTOR_AUTH_DISABLED_SUCCESS: 'Two Factor authentication disabled successfully',
    TWO_FACTOR_AUTH_DISABLE_MODAL_HEADER: 'Disabled two factor authentication',
    TWO_FACTOR_AUTH_DISABLE_SUB_HEADER: 'Are you sure you want to Disable two factor authentication?',
    FOLLOWERS_PUBLISH_TAG_CONFRM: 'Saving Follower Tags will update the Follower <br> registration process. Additionally, the following <br> dynamic tag lists will be created:',
    INVALID_DATE_ERROR_MESSAGE: 'Please select valid date.',
    HERENOW_PAST_START_DATE: 'The start date cannot be a past date',
    HERENOW_PAST_END_DATE: 'The end date cannot be a past date',
    HERENOW_PAST_START_TIME: 'The start time cannot be in the past',
    HERENOW_PAST_END_TIME: 'The end time cannot be in the past',
    HERENOW_TIME_DIFF_ERROR: 'The difference between the start and end times must be a minimum of 10 min',
    HERENOW_INVALID_DATE: 'Please enter a valid date',
    HERENOW_END_START_DATE_ERROR: 'The end date must be after start date',
    HERENOW_PAUSE_HEADING: 'Are you sure you want to pause this HereNow?',
    HERENOW_RESUME_HEADING: 'Are you sure you want to resume this HereNow?',
    HERENOW_PAUSE_DESC: 'All content and push notifications associated to this HereNow will no longer be activated as long as it is paused.',
    HERENOW_RESUME_DESC: 'All content and push notifications associated to this HereNow will be activated as it is resumed.',
    HERENOW_DELETE_HEADING: 'Are you sure you want to delete this HereNow?',
    HERENOW_DELETE_DESC: 'All content and push notifications associated to this HereNow will no longer be activated unless they are associated to another HereNow. This cannot be undone.',
    HERENOW_DELETED_SUCCESS: 'HereNow deleted successfully.',
    PDFERRORMESSAGE: 'PDF file should not be more than 10 MB',
    Export_CSV_SUCCESS:'Export list requested will be sent to the users email which is logged currently. This might take a few minutes.',
    Export_CSV_ERROR:'Error in downloading CSV File.'
}
