import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appSoryByField]'
})
export class SoryByFieldDirective {
  @Input() fieldname;
  order: string = 'desc';
  @Output() sortConfig = new EventEmitter();
  constructor() {
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    (this.order == 'asc') ? this.order = 'desc' : this.order = 'asc';
    event.preventDefault();
    event.stopPropagation();
    let obj = {
      'sort_by': this.fieldname,
      'order_by': this.order
    };
    this.sortConfig.next(obj);
  }

}
