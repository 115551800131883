import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HelperService, CoreService } from '@common-services';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ROUTES_PATH } from '@constants';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LoginResolverService implements Resolve<any> {
  token: string;
  constructor(
    private helperService: HelperService,
    private router: Router,
    private ToastrService: ToastrService,
    private CoreService: CoreService
  ) {
  }

  /**
   * @method: resolve
   * @desc: redirect on dashboard if already logged in
   */
  resolve(route: ActivatedRouteSnapshot) {
    if (route.queryParams['token']) {
      this.token = route.queryParams['token'];
      if (this.token) {
        localStorage.clear();
        localStorage.setItem('token',JSON.stringify(this.token));
        this.helperService.showSpinner(true);
        this.getUserDetailsThenLogin();
        return true;
      }
    } else {
      const token = this.helperService.getToken();
      const roleId = this.helperService.getFieldValueFromStorage('userinfo', 'role_id');
      if (token && roleId) {
        if (roleId === 1) {
          this.router.navigate([ROUTES_PATH.SA_DASHBOARD]);
        } else if (roleId === 2) {
          this.router.navigate([ROUTES_PATH.DASHBOARD]);
        }
        return true;
      } else {
        localStorage.clear();
        return false;
      }
    }
  }

  /**
  * @method: getUserDetailsThenLogin
  * @desc: Get user details using entity Id
  */
  getUserDetailsThenLogin() {
    this.CoreService.put('user/token',{}).subscribe(res => {
      const token = res.body.data.token;
      const org = res.body.data.organization;
      const user = res.body.data.user;
      localStorage.clear();
      localStorage.setItem("ORG", JSON.stringify(org));
      localStorage.setItem("userinfo", JSON.stringify(user));
      localStorage.setItem("token", JSON.stringify(token));
      if(!org.is_ya_admin){
        this.router.navigate([ROUTES_PATH.DASHBOARD]);
      }
      else if (org.is_ya_admin){
        this.router.navigate([ROUTES_PATH.YOUR_APP]);
      }
    }, (err) => {
      this.ToastrService.error(err);
    });
  }
}
