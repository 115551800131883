import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './shared/modules/core/core.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PolicyComponent } from './modules/policies/policy/policy.component';
import { TermsConditionsComponent } from './modules/terms-conditions/terms-conditions.component';
import { SafeHtmlPipe } from './shared/filters/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PolicyComponent,
    TermsConditionsComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
