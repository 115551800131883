export const ENDPOINTS = {
    CMS_LOGIN: 'user/login?platform=web&is_verified=false', // Authentication Module
    CMS_FORGOT_PASSWORD: 'user/forgot-password', // Authentication Module
    CMS_SET_PASSWORD: 'user/set-password', // Authentication and Profile Module
    USER: 'user', // SA Dashboard, Profile, Header, User Modules
    MEDIA_LIBRARY: 'stock_library/images', // Stock Library Module
    SEND_FM_REQUEST: 'content/fm_request/', // Admin Dashboard Module
    PARTNER_GROUPS : 'partner/group', // This is for Save Lists in UI, Content Module, Save List Modal, Create Force Multiplier Component
    PARTNER : 'partner', // Partner Request Modal, Save List Modal, and My Networks Module, Header, Create FM Component, Notification Module 
    UPLOAD_MEDIA: 'media/upload', // Content Module
    Notification_UPLOAD_MEDIA: 'notification/media/upload', // push-notification-modal.component
    IMAGE_UPLOAD: 'image/upload', // Organization Module
    CMS_MASTER_LIST: 'master', // Organization, Policy, Terms and Conditions Module
    CONTENT: 'content/', // Content Module, Admin Dashboard
    YOURAPPORG: 'organization',
    CONTENT_COPY: 'content/copy', // Content Module
    ORGANIZATION: 'organization', // AUthentication, SA Dashboard, Admin Dashboard, My Networks, Organization, User Modules
    AFFILIATIONS: 'affiliation', // Affiliation, Organization Module
    ORGANIZATION_LOGIN: 'organization/login', // SA Dashboard
    USER_LOGOUT: 'user/logout', // SA Dashboard, Header
    FOLLOWER_TAG : 'organization/tag', // My People Module
    BLOCK_UNBLOCK_FOLLOWER: 'organization/follower/status', // My People Module
    DELETE_PARTNER_CONTENT : 'partner/share/content?content_id=', // Content Module
    TAGS: 'organization/follower/tag', // Super Push Modal
    FOLLOWER_GROUP : 'organization/follower/group', // This is for My Lists in UI, My People Module and Super Push Modal
    DOWNLOAD_FOLLOWER: 'organization/follower/download', // My People Module
    PARTNER_REQUEST_NOTIFICATIONS: 'partner/request', // Header and My Networks Module
    NOTIFICATION: 'notification', // Content and Push Notification Module, Push Notifications Modal
    FM_PARTNER_LIST : 'organization?request_status=accept', // Content, Save List Modal
    SHARE_PARTNER_CONTENT: 'partner/share/content', // Content, Admin Dashboard, Force Multiplier Confirmation
    ORGANIZATION_FOLLOWERS: 'organization/follower/', // My People, Super Push Modal
    TAG_APPROVAL_LIST : 'user/tag', // Tag Approval Modal,
    GET_ACTIVE_CONTENT_LIST: "content/", // API using for get active content list
    FEED: "v1/feed", // API using for create new feeds.
    YOURAPP_PARTNER: "organization", // API using for create new feeds.
    SUBSCRIPTION: "v1/subscription", // In MyNetworks, subscriptions module
    OTP: 'user/otp', // User profile module
    OTP_FOR_LOGIN: 'user/login/otp', // Login module
    HERENOW: 'herenow/', // HereNow module in Admin panel
    HERENOW_ASSOCIATE: 'herenow/associate', // HereNow module in Admin panel
    HERENOW_STATUS: 'herenow/status/', // HereNow module in Admin panel
    YOURAPP_DASHBOARD_HISTORY: 'yourapp/organization/history',
    ACTIVE_PROFILE_GRAPH: 'yourapp/organization/activeprofile',
    PUSH_GRAPH: 'yourapp/organization/pushes/graph',
    ACTIVE_CONTENT_GRAPH: 'yourapp/organization/activecontents',
    ACTIVE_FOLLOWERS_GRAPH: 'yourapp/organization/activefollowers/graph',
    YOURAPP_CONNECTION_PATNER: 'yourapp/organization',
    ORGANIZATION_FOLLOWER_CHECK: 'organization/follower/tag/check',
}