import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HelperService, CoreService } from '@common-services';
import { ENDPOINTS } from '@constants';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.css']
})
export class MediaLibraryComponent implements OnInit {

  private onSelect = new Subject<string>();
  libraryList: any;

  constructor(
    public modalRefe: BsModalRef,
    private HelperService: HelperService,
    private CoreService: CoreService,
    private ToastrService: ToastrService
  ) { }

  ngOnInit() {
    this.getStockLibrary();
  }

  /**
   * @method: getStockLibrary
   * @desc: Get the stock library images
   */
  getStockLibrary() {
    this.HelperService.showSpinner(true);
    this.CoreService.get(ENDPOINTS.MEDIA_LIBRARY).subscribe((res) => {
      this.handleStockLibraryResponse(res.body);
    }, (err) => {
      this.HelperService.showSpinner(false);
    });
  }

  /**
  * @method: handleStockLibraryResponse
  * @desc: Handle user lst response
  * @param data 
  */
  handleStockLibraryResponse(data: any) {
    this.HelperService.showSpinner(false);
    this.libraryList = data.data;
  }

  /**
   * @method: onConfirm
   * @desc: Method called on selecting image
   * @param url 
   */
  onConfirm(url): void { 
    this.HelperService.showSpinner(true);
    this.HelperService.convertUrlToBase64(url, (res) => {
      this.HelperService.showSpinner(false);
      this.modalRefe.hide();
      this.onSelect.next(res);
    });
  }

  hide (){
    this.modalRefe.hide();
    this.onSelect.next(null); 
  }

}
