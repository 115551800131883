import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.css']
})
export class CropperComponent implements OnInit {
  base64Type: boolean = false;
  @Input() imgSource;
  @Input() minWidth;
  @Input() minHeight;
  @Input() ratio;
  @Input() hideControls = false;
  @Output() cropDone = new EventEmitter();
  @Output() hide = new EventEmitter();
  cropper = {
    x1: 100,
    y1: 100,
    x2: 360,
    y2: 200
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};

  constructor() { }

  ngOnInit() {
    this.imageChangedEvent = this.imgSource;
    this.base64Type = this.isBase64(this.imageChangedEvent);    
  }

  /**
   * @method: imageCropped
   * @desc: Method called on image cropped.
   * @param event 
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  /**
   * @method: imageLoaded
   * @desc: Method called on image load
   */
  imageLoaded() {
    this.showCropper = true;
    setTimeout(() => {
      this.cropper = {
        x1: 100,
        y1: 100,
        x2: 260,
        y2: 260
      }
    }, 100);
  }
  cropperReady(event: ImageCroppedEvent) {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  /**
   * @method: crop
   * @desc: Method called on crop button click
   */
  crop() {
    this.cropDone.emit(this.croppedImage);
  }

  /**
   * @method: close
   * @desc: Method called on close button click
   */
  close() {
    this.hide.emit(true);
  }

  /**
   * @method: isBase64
   * @desc: check if url is base64 or not
   * @param str 
   */
  isBase64(str) {
    let regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    return regex.test(str);
  }

  /**
   * @method: rotateLeft
   * @desc: rotate image left
   */
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  /**
   * @method: rotateRight
   * @desc: rotate image right
   */
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  /**
   * @method: flipAfterRotate
   * @desc: Image position after rotation
   */
  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  /**
   * @method: flipHorizontal
   * @desc: Flip the image in horizontal direction
   */
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  /**
   * @method: flipVertical
   * @desc: Flip the image in vertical direction.
   */
  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  /**
   * @method: zoomOut
   * @desc: Zoom out the image
   */
  zoomOut() {
    this.scale -= .1;
    if (this.scale > 0.1) {
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }else {
      this.scale = 0.1;
    }
  } 

  /**
   * @method: zoomIn
   * @desc: Zoom In the image
   */
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
