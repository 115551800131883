import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService, CoreService } from '@common-services';
import { MESSAGES, ENDPOINTS } from '@constants';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { hereNowData, Address, IHereNowOutput, IHereNow } from '../../../modules/here-now/here-now.interface';
import { IHereNowConfiguration } from '../../../modules/here-now/here-now.interface';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-here-now-form',
  templateUrl: './here-now-form.component.html',
  styleUrls: ['./here-now-form.component.css']
})
export class HereNowFormComponent implements OnInit {
  isParentFormSubmitted = new BehaviorSubject(false);
  _mutate = new BehaviorSubject(false);
  @ViewChild('hereNowStartDateElement', { static: false }) hereNowStartDateElement: ElementRef;
  @ViewChild('hereNowEndDateElement', { static: false }) hereNowEndDateElement: ElementRef;
  @ViewChild('addresstext', { static: false }) addresstext: any;
  @Input() hereNowConf: IHereNowConfiguration;
  @Input() set mutate(value) {
    this._mutate.next(value);
  }
  @Input() set parentFormSubmitted(value) {
    this.isParentFormSubmitted.next(value);
  }
  @Output() hereNowFormData = new EventEmitter();
  @Output() mutateResponse = new EventEmitter();
  HereNowOutputData: IHereNowOutput;
  hereNowObj: hereNowData;
  hereNowForm: FormGroup;
  submitted: boolean = false;
  addressObj: Address = {
    address: '',
    longitude: null,
    latitude: null,
    state: null,
    country: null
  }
  options: any;
  unitForTime = [{ name: 'Min', value: 'minute' }, { name: 'Hr', value: "hour" }, { name: 'Day', value: 'day' }, { name: 'Week', value: 'week' }];
  unitForRadius = [{ name: 'Ft', value: "feet" }, { name: 'Yd', value: "yards" }, { name: 'Mile', value: 'miles' }];
  startDateTemp: Date;
  startTimeTemp: Date;
  endDateTemp: Date;
  endTimeTemp: Date;
  integratedStartDateTime: Date;
  integratedEndDateTime: Date;
  today: any = new Date(moment().format('YYYY, MM, DD'));
  EndDateMinTime: any = new Date(moment().format('YYYY, MM, DD'));
  orgId: number;
  content: any = {
    type: '',
    hereNow: ''
  };
  hereNowData: IHereNow;
  makeStartDateTimeReadonly: boolean = false;
  makeendDateTimeReadonly: boolean = false;
  status: string = 'Active';
  allHereNow: Array<IHereNow>;
  useExistingHereNow: boolean = false;
  setExistingHereNow: boolean = false;
  selectedHereNow: any;
  disableEditatbleFields: boolean = false;
  Autocomplete: any;
  MapUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private coreService: CoreService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.orgId = this.helperService.getFieldValueFromStorage('ORG', 'id');
    this.getAllHereNow();
    this.content = this.modalService.config.initialState['content'];
    this.isParentFormSubmitted.subscribe(res => {
      if (res) this.onSubmit();
      if (res === false) this.submitted = false;
    });
    this._mutate.subscribe((res) => {
      if (res) {        
        if (this.hereNowConf.hereNow) {
          this.setExistingHereNow = true;
          return false;
        } {
          this.setExistingHereNow = false;
        }
        (this.HereNowOutputData && this.HereNowOutputData.herenow_form_settings && this.HereNowOutputData.herenow_form_settings.useExistingHereNow) ? this.associateHereNow(this.hereNowConf.parentId, this.f.existingHereNowId.value) : this.createOrUpdate(this.HereNowOutputData.herenow_form_fields);
      }
    });
    this.initHereNowObject();
    this.initForm();
    if (this.content && this.content.type === 'edit') this.getHereNowDetails();
  }

  /**
   * @method: 
   * @desc: 
   */
  getSelectedHereNow() {
    const hereNow = this.allHereNow.find((item) => {
      return item.id === Number(this.hereNowConf.hereNow)
    });
    // this.f.herenowType.setValue('useExisting');
    this.f.existingHereNowId.setValue(this.hereNowConf.hereNow);
    this.changeHereNowType();
    hereNow && this.setHereNowDataToFormToEdit(hereNow, true);
    this.resetRadiusValidation();

    this.selectedHereNow = hereNow;
    this.useExistingHereNow = true;
    this.enableDisableStartDateTime(true);
    if (hereNow && hereNow.here_now_status === 'Active' && this.hereNowConf.parentId) {
      this.disableEditatbleFields = true;
      this.f.existingHereNowId.disable();
    }
    if (this.status === 'Draft') {
      this.enableDisableStartDateTime(false);
      (hereNow.duration_type === 'limited') ? this.enableDisableEndDateTime(false) : this.enableDisableEndDateTime(true);
      this.useExistingHereNow = false;
    }

    if(this.hereNowConf.parentStatus === 'Draft') {
      this.disableEditatbleFields = false;
      this.f.existingHereNowId.enable();

    }
    this.f.existingHereNowId.disable();
  }

  /**
   * @method: getAllHereNow
   * @desc: method to call the get api to fetch all the herenow to show in the dropdown options for use existing
   */
  getAllHereNow() {
    this.helperService.showSpinner(true);
    this.coreService.get(ENDPOINTS.HERENOW, {
      organization_id: this.orgId,
      active_here_now: true,
      selected_herenow_id: (this.hereNowConf && this.hereNowConf.hereNow) ? this.hereNowConf.hereNow : ''
    }).subscribe((res) => {
      this.allHereNow = res.body.data;
      if(this.allHereNow.length == 0){
        this.f.herenowType.setValue('createNew');
        this.setValidationOverField('existingHereNowId', null);
      }
      if (this.setExistingHereNow) {
        this.getSelectedHereNow();
      } else {
        this.f.herenowType.setValue('createNew');
        this.setValidationOverField('existingHereNowId', null);
      }
      this.helperService.showSpinner(false);
    }, (err) => {
      this.helperService.showSpinner(false);
    });
  }

  /**
   * @method: getHereNowDetails
   * @desc: get the details of HereNow
   */
  getHereNowDetails() {
    this.helperService.showSpinner(true);
    const URL = `${ENDPOINTS.HERENOW}${this.content.hereNow}/`;
    this.coreService.get(URL, {
      organization_id: this.orgId
    }).subscribe((res) => {
      this.setHereNowDataToFormToEdit(res.body.data);
      this.helperService.showSpinner(false);
    }, (err) => {
      this.helperService.showSpinner(false);
    })
  }

  /**
   * @method: setHereNowDataToFormToEdit
   * @desc: set the value to the form while editing
   */
  setHereNowDataToFormToEdit(data: IHereNow, useExisting: boolean = false) {
    this.hereNowForm.patchValue(data);
    this.status = data.status;
    this.startDateTemp = this.startTimeTemp = new Date(data.start_date);
    this.setFieldValue('startDate', data.start_date);
    this.setFieldValue('startTime', data.start_date);
    if (data.end_date) {
      this.endTimeTemp = this.endDateTemp = new Date(data.end_date);
      this.setFieldValue('endDate', data.end_date);
      this.setFieldValue('endTime', data.end_date);
    }
    this.addressObj = {
      address: data.address,
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
      state: data.state,
      country: data.country,

    };
    if (!useExisting) {
      this.EndDateMinTime = new Date(moment(this.startDateTemp).format('YYYY, MM, DD'));
      this.setDataForHereNowObj(data);
      this.setDefaultValuesForNewHereNow('setDataToEdit');
      this.makeReadOnlyFields();
    }
  }

  /**
   * @method: makeReadOnlyFields
   * @desc: make fields read only for edit mode
   */
  makeReadOnlyFields() {
    if (this.status === 'Draft') return;
    const now = new Date();
    const isPastDate = moment(this.startDateTemp).isSameOrBefore(now, 'minute');
    let endIsFutureDate = moment(this.endDateTemp).isSameOrAfter(now, 'minute');
    this.makeStartDateTimeReadonly = isPastDate;
    (this.hereNowData && this.hereNowData.duration_type === 'limited' && endIsFutureDate) ? endIsFutureDate = false : endIsFutureDate = true;
    this.enableDisableEndDateTime(endIsFutureDate);
    this.enableDisableStartDateTime(this.makeStartDateTimeReadonly);
    if (this.makeStartDateTimeReadonly) {
      this.hereNowObj.formValid = true;
      this.hereNowObj.endDateError = this.hereNowObj.endTimeError = this.hereNowObj.startDateError = this.hereNowObj.startTimeError = "";
    }
  }

  /**
   * @method: ngAfterViewInit
   * @desc: Google maps autocomplete for address field
   */
  ngAfterViewInit() {
    const mapAddressinput = document.getElementById("mapAddress") as HTMLElement
    const input:any = document.getElementById("autocompleteAddress") as HTMLElement
    const map = new google.maps.Map( mapAddressinput,{
      center: { lat: -33.8688, lng: 151.2195 },
      zoom: 13,
      mapTypeId: "roadmap",
    });
    // Create the search box and link it to the UI element.
    const searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      this.setAddressObj(places[0]);

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        );
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }

  /**
   * @method: 
   * @desc: set the address object from the map api
   */
  setAddressObj(place: any) {
    if (place && place.geometry && place.formatted_address) {
      this.addressObj.address = place.formatted_address;
      this.addressObj.longitude = place.geometry.location.lng();
      this.addressObj.latitude = place.geometry.location.lat();
      let setState: boolean = false;
      this.addressObj.state = ""
      this.MapUrl = place.url;

      for (const address of place.address_components) {
        if (address.types[0] === 'country' && (address.short_name == 'US' || address.short_name == 'CA')) {
          setState = true;
        }
        if(address.types[0] === 'country') {
          this.addressObj.country = address.long_name;
        }
      }

      for (const component of place.address_components) {
        const addressType = component.types[0];
        if (addressType == 'administrative_area_level_1' && setState) {
          this.addressObj.state = component['short_name'] || "";
        }
      }
    }
  }

  /**
   * @method: initHereNowObject
   * @desc: Initialize HereNow object for field validations
   */
  initHereNowObject() {
    this.hereNowObj = {
      endDateError: '',
      endTimeError: '',
      startDateError: '',
      startTimeError: '',
      formValid: true
    }
  }

  /**
  * @method: initForm
  * @desc: Initialize the HereNow form using Form builder
  */
  initForm() {
    this.hereNowForm = this.formBuilder.group({
      herenowType: ['createNew', [Validators.required]],
      name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      existingHereNowId: [''],
      startDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      duration_type: ['indefinite', [Validators.required]],
      endDate: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      address: ['', [Validators.required]],
      radius: ['', [Validators.required, Validators.pattern('^[1-9][0-9]*$'), Validators.min(200)]],
      radius_unit: ['feet', [Validators.required]],
      notify_type: ['once', [Validators.required]],
      interval: ['', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]],
      interval_unit: ['minute', [Validators.required]],
    });
    this.setValidationOverField('existingHereNowId', null);
    if ((this.content && this.content.type === 'add') || ['push', 'content'].includes(this.hereNowConf.type)) this.setDefaultValuesForNewHereNow('default');
    this.setValidationForOptionalField();
    if (['content', 'push'].includes(this.hereNowConf.type)) {
      this.hereNowForm.patchValue({
        herenowType: 'useExisting',
      })
    }

  }

  /**
   * @method: setValidationForOptionalField
   * @desc: set new validations for optional field
   */
  setValidationForOptionalField() {
    if (this.f.notify_type.value === 'once') {
      this.f.interval.reset();
      this.f.interval.setValidators(null);
    } else {
      this.f.interval.setValidators([Validators.required, Validators.pattern('^[1-9][0-9]*$')]);
    }
    this.f.interval.updateValueAndValidity();
  }

  /**
   * @method: setDefaultValuesForNewHereNow
   * @desc: set default values for new HereNow
   */
  setDefaultValuesForNewHereNow(type: string) {
    if (type === 'default') this.startDateTemp = this.startTimeTemp = moment(new Date()).add(2, 'm').toDate();
    if (this.f.duration_type.value === 'indefinite') {
      if (this.content && this.content.type === 'edit' && this.endDateTemp) {
        this.endDateTemp = this.endTimeTemp = this.endDateTemp;
      } else {
        this.endDateTemp = this.endTimeTemp = null;
      }
      this.enableDisableEndDateTime(true);
    } else {
      this.enableDisableEndDateTime(false);
    }
    this.setFieldValue('startDate', this.startDateTemp);
    this.setFieldValue('startTime', this.startTimeTemp);
    if ((type !== 'setDataToEdit') && (this.content && this.content.type !== 'edit')) this.calculateAndSetEndDate(); // do not calculate for edit on modal open 
    this.setFieldValue('endDate', this.endDateTemp);
    this.setFieldValue('endTime', this.endTimeTemp);
    this.commonErrorHandlerForDateTime();
  }

  /**
   * @method: calculateAndSetEndDate
   * @desc: calculate and set the end date and time, should be 10 min more than start date and time
   */
  calculateAndSetEndDate() {
    if (this.f.duration_type.value === 'indefinite') return;
    this.endDateTemp = this.endTimeTemp = moment(this.startDateTemp).add(10, 'm').toDate();
  }

  /**
   * @method: enableDisableEndDateTime
   * @desc: enable or disable the date and time
   * @param disable 
   */
  enableDisableEndDateTime(disable) {
    if (disable) {
      this.f.endDate.disable();
      this.f.endDate.setValidators(null);
      this.f.endTime.setValidators(null);
    } else {
      this.f.endDate.enable();
      this.f.endDate.setValidators([Validators.required]);
      this.f.endTime.setValidators([Validators.required]);
    }
    this.f.endDate.updateValueAndValidity();
    this.f.endTime.updateValueAndValidity();


  }

  /**
   * @method: enableDisableEndDateTime
   * @desc: enable or disable the date and time
   * @param disable 
   */
  enableDisableStartDateTime(disable) {
    if (disable) {
      this.f.startDate.disable();
      this.f.startDate.setValidators(null);
      this.f.startTime.setValidators(null);
    } else {
      this.f.startDate.enable();
      this.f.startDate.setValidators([Validators.required]);
      this.f.startTime.setValidators([Validators.required]);
    }
    this.f.startDate.updateValueAndValidity();
    this.f.startTime.updateValueAndValidity();
  }

  /**
   * @method: setFieldValue
   * @desc: set the value of field in the form
   */
  setFieldValue(field: string, fieldValue: any) {
    this.hereNowForm.patchValue({
      [field]: fieldValue,
    });
    (field === 'startDate' || field === 'startTime') ? this.updateIntegratedStartDateTime() : this.updateIntegratedEndDateTime();
  }

  /**
   * @method: updateIntegratedStartDateTime
   * @desc: update the date and time in integrated date
   */
  updateIntegratedStartDateTime() {
    const time = moment(this.startTimeTemp).format('HH.mm');
    const date = moment(this.startDateTemp).format('MM/DD/YYYY');
    const dateTime = moment(date + ' ' + time, 'MM/DD/YYYY HH:mm');
    this.integratedStartDateTime = moment(dateTime).toDate();
    this.integratedStartDateTime.setSeconds(0);
    this.integratedStartDateTime.setMilliseconds(0);
    this.startDateTemp = new Date(this.integratedStartDateTime);
    this.startTimeTemp = new Date(this.integratedStartDateTime);
  }

  /**
   * @method: updateIntegratedEndDateTime
   * @desc: update the date and time in integrated date
   */
  updateIntegratedEndDateTime() {
    if (!this.endTimeTemp) return;
    const time = moment(this.endTimeTemp).format('HH.mm');
    const date = moment(this.endDateTemp).format('MM/DD/YYYY');
    const dateTime = moment(date + ' ' + time, 'MM/DD/YYYY HH:mm');
    this.integratedEndDateTime = moment(dateTime).toDate();
    this.integratedEndDateTime.setSeconds(0);
    this.integratedEndDateTime.setMilliseconds(0);
    this.endDateTemp = new Date(this.integratedEndDateTime);
    this.endTimeTemp = new Date(this.integratedEndDateTime);
  }

  /*
  * @method: f
  * @desc: Return form controls field value.
  */
  get f() { return this.hereNowForm.controls; }

  /*
  * @method: onSubmit
  * @desc: Method using for submit HereNow form and update/add HereNow.
  */
  onSubmit() {
    this.submitted = true;
    this.setDataForHereNowObj(this.hereNowForm.value);
    if (this.addressObj && !this.addressObj.address && !this.addressObj.latitude && (this.content && this.content.type === 'add' || ['push', 'content'].includes(this.hereNowConf.type))) {
      this.f.address.setValue('');
    }
    if (this.hereNowForm.invalid) {
      this.hereNowObj.formValid = false;
      return;
    }
    if (!this.hereNowObj.startDateError && !this.hereNowObj.startTimeError && !this.hereNowObj.endDateError && !this.hereNowObj.endTimeError) {
      this.hereNowObj.formValid = true;
    } else {
      this.hereNowObj.formValid = false;
    }
    this.prepareHereNowData();
    if (this.hereNowConf && !this.hereNowConf.softCreateData) this.createOrUpdateHereNow();
    if (this.hereNowConf && ['push', 'content'].includes(this.hereNowConf.type)) this.sendDataToParent();
  }

  /**
   * @method: sendDataToParent
   * @desc: send data to parent for verification
   */
  sendDataToParent() {
    this.hereNowFormData.next(this.HereNowOutputData);
  }

  /**
   * @method: setDataForHereNowObj
   * @desc: set data of herenow to herenow object
   */
  setDataForHereNowObj(data) {
    this.hereNowData = {
      address: this.addressObj.address,
      duration_type: data.duration_type,
      end_date: (this.integratedEndDateTime) ? this.integratedEndDateTime.toString() : null,
      id: null,
      interval: data.inteval,
      interval_unit: data.interval_unit,
      latitude: String(this.addressObj.latitude),
      longitude: String(this.addressObj.longitude),
      name: data.name,
      notify_type: data.notify_type,
      radius: data.radius,
      radius_unit: data.radius_unit,
      start_date: (this.integratedStartDateTime) ? this.integratedStartDateTime.toString() : null,
      state: this.addressObj.state,
      status: data.status || 'Active',
      endDate: this.integratedEndDateTime,
      endTime: this.integratedEndDateTime,
      herenowType: data.herenowType,
      startDate: this.integratedStartDateTime,
      startTime: this.integratedStartDateTime
    }
  }

  /**
   * @method: prepareHereNowData
   * @desc: prepate data for HereNow to create/edit the HereNow or send to the parent
   */
  prepareHereNowData() {
    let startDate: Date, endDate: Date, fields, publishTime = new Date(), hereNowId;
    const diff = this.helperService.getTimeDifferenceInMinutes(this.integratedStartDateTime, publishTime);
    if (diff < 2 && !this.makeStartDateTimeReadonly) {
      if (this.integratedEndDateTime) {
        let newDiff = this.helperService.getTimeDifferenceInMinutes(publishTime, this.integratedStartDateTime);
        newDiff = newDiff + 2;
        endDate = moment(this.integratedEndDateTime).add(newDiff, 'm').toDate();
      }
      startDate = moment(publishTime).add(2, 'm').toDate();
    } else {
      startDate = this.integratedStartDateTime;
      endDate = this.integratedEndDateTime;
    }
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    if (this.hereNowData.duration_type === 'indefinite') endDate = null;
    if (endDate) {
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
    }
    fields = { ...this.hereNowData };
    fields = this.deleteFieldsFromObj(['startTime', 'startDate', 'herenowType', 'endTime', 'endDate', 'address', 'name', 'interval', 'radius', 'status'], fields);
    if (!endDate) endDate = null;
    let interval = (this.f.interval.value) ? Number(this.f.interval.value) : null;
    if (this.hereNowData.notify_type === 'once' && this.content && this.content.type === 'edit') interval = null;
    (this.f.herenowType.value === 'useExisting' && this.f.existingHereNowId.value) ? hereNowId = Number(this.f.existingHereNowId.value) : hereNowId = null;
    this.HereNowOutputData = {
      "herenow_form_fields": { hereNowId: hereNowId, organization: this.orgId, interval: interval, radius: Number(this.f.radius.value), name: this.f.name.value.trim(), status: this.status, ...fields, ...this.addressObj, start_date: startDate, end_date: endDate },
      "herenow_form_settings": {
        status: 'new',
        useExistingHereNow: (this.f.herenowType.value === 'useExisting') ? true : false,
        formUpdates: this.hereNowForm.dirty,
        formValid: this.hereNowObj.formValid,
        softCreateData: this.hereNowConf.softCreateData
      }
    };
  }

  /**
   * @method: deleteFieldsFromObj
   * @desc: delete fields from object
   * @param fields 
   */
  deleteFieldsFromObj(fields: Array<string>, obj: any) {
    fields.forEach((field) => {
      delete obj[field]
    });
    return obj;
  }

  /**
   * @method: createOrUpdateHereNow
   * @desc: method to call api to create the new HereNow or update the existing one
   */
  createOrUpdateHereNow() {
    if (!this.HereNowOutputData.herenow_form_settings.formValid) return;
    if (this.HereNowOutputData.herenow_form_settings.status === 'new' && !this.HereNowOutputData.herenow_form_settings.softCreateData) {
      this.createOrUpdate(this.HereNowOutputData.herenow_form_fields);
    }
  }

  /**
   * @method: createOrUpdate
   * @desc: method to call api to create data
   */
  createOrUpdate(data: any) {
    let method, url = ENDPOINTS.HERENOW;
    this.helperService.showSpinner(true);
    if ( (this.content && this.content.type === 'add') || ['push', 'content'].includes(this.hereNowConf.type) && this.f.herenowType.value === 'createNew') {
      method = 'post';
    } else {
      method = 'put';
      if (this.selectedHereNow && this.selectedHereNow.id) {
        url = `${url}${this.selectedHereNow.id}/`;
      } else {
        url = `${url}${this.content.hereNow}/`;
      }
    }
    (this.hereNowConf.parentStatus) ? data.status = this.hereNowConf.parentStatus : data.status = "Active";
    
    this.MapUrl = '';
    this.selectedHereNow = null;
    this.coreService[method](url, data).subscribe((res) => {
      this.helperService.showSpinner(false);
      if (this.hereNowConf.parentId) {
        if (res.body.data && res.body.data.id) {
          this.associateHereNow(this.hereNowConf.parentId, res.body.data.id);
        } else {
          this.mutateResponse.next(res.body);
        }
      } else {
        if (res.body && res.body.message) this.toastrService.success(res.body.message);
        this.hereNowFormData.next(true);
      }
    }, (err) => {
      this.helperService.showSpinner(false);
    })
  }

   /**
   * @method: redirectToGoogleMap
   * @desc: Method using for to open Google map with input address .
   * @param event
   */
    redirectToGoogleMap() {
      if (this.MapUrl) {
        window.open(this.MapUrl, '_blank');
      }
    }

  /**
   * @method: onStartDateChange
   * @desc: method to set the date on change event of Start Date
   * @param event
   */
  onStartDateChange(event) {
    if (!this.f.startDate.value) return;
    const today = new Date();
    this.startDateTemp = this.startTimeTemp = this.f.startDate.value;
    if (moment(this.startDateTemp).isSame(today, 'day')) {
      this.startDateTemp = this.startTimeTemp = moment(today).add(2, 'm').toDate();
    }
    this.setFieldValue('startTime', this.startTimeTemp);
    this.setFieldValue('startDate', this.startDateTemp);
    this.EndDateMinTime = new Date(moment(this.startDateTemp).format('YYYY, MM, DD'));
    if (this.getTimeDifferenceInMinutes() < 10) {
      this.calculateAndSetEndDate();
      this.setFieldValue('endDate', this.endDateTemp);
      this.setFieldValue('endTime', this.endTimeTemp);
    }
    this.commonErrorHandlerForDateTime();
  }

  /**
   * @method: onEndDateChange
   * @desc: method to set the date on change event of End Date
   * @param event
   */
  onEndDateChange(event) {
    if (!this.f.endDate.value) return;
    const today = new Date();
    this.endDateTemp = this.endTimeTemp = this.f.endDate.value;
    if ((moment(this.endDateTemp).isSame(today, 'day') && moment(this.integratedStartDateTime).isSame(today, 'day')) || (moment(this.endDateTemp).isSame(this.integratedStartDateTime, 'day'))) {
      this.endDateTemp = this.endTimeTemp = moment(this.integratedStartDateTime).add(10, 'm').toDate();
    }
    this.setFieldValue('endTime', this.endTimeTemp);
    this.setFieldValue('endDate', this.endDateTemp);
    this.commonErrorHandlerForDateTime();
  }

  /**
  * @method: onStartTimeChange
  * @desc: method to set the date on change event of Start Time
  * @param event
  */
  onStartTimeChange(event) {
    this.startTimeTemp = this.f.startTime.value;
    this.setFieldValue('startTime', this.startTimeTemp);
    if (this.getTimeDifferenceInMinutes() < 10) {
      this.calculateAndSetEndDate();
      this.setFieldValue('endDate', this.endDateTemp);
      this.setFieldValue('endTime', this.endTimeTemp);
    }
    this.commonErrorHandlerForDateTime();
  }

  /**
  * @method: onEndTimeChange
  * @desc: method to set the date on change event of End Time
  * @param event
  */
  onEndTimeChange(event) {
    this.endTimeTemp = this.f.endTime.value;
    this.setFieldValue('endTime', this.endTimeTemp);
    this.commonErrorHandlerForDateTime();
  }

  /**
   * @method: commonErrorHandlerForDateTime
   * @desc: common method to handle all the errors for Date and Time
   */
  commonErrorHandlerForDateTime() {
    let now = new Date();
    now.setSeconds(0);
    const diff = this.helperService.getTimeDifferenceInMinutes(this.integratedStartDateTime, now);
    if ((diff < 0) && !this.makeStartDateTimeReadonly) {
      if (moment(this.integratedStartDateTime).isBefore(now, 'day')) {
        this.hereNowObj.startDateError = MESSAGES.HERENOW_PAST_START_DATE;
        this.hereNowObj.startTimeError = '';
      } else {
        this.hereNowObj.startTimeError = MESSAGES.HERENOW_PAST_START_TIME;
        this.hereNowObj.startDateError = '';
      }
      this.hereNowObj.formValid = false;
    } else {
      this.hereNowObj.startDateError = "";
      this.hereNowObj.startTimeError = "";
      this.hereNowObj.formValid = true;
    }
    if (this.integratedEndDateTime) {
      const mins = this.getTimeDifferenceInMinutes();
      if (moment(this.integratedEndDateTime).isBefore(now, 'day') && !moment(this.integratedEndDateTime).isBefore(this.integratedStartDateTime, 'day')) {
        this.hereNowObj.endDateError = MESSAGES.HERENOW_PAST_END_DATE;
        this.hereNowObj.endTimeError = '';
        this.hereNowObj.formValid = false;
      } else if (moment(this.integratedEndDateTime).isBefore(this.integratedStartDateTime, 'day')) {
        this.hereNowObj.endDateError = MESSAGES.HERENOW_END_START_DATE_ERROR;
        this.hereNowObj.endTimeError = '';
        this.hereNowObj.formValid = false;
      } else {
        if (mins < 10) {
          this.hereNowObj.endTimeError = MESSAGES.HERENOW_TIME_DIFF_ERROR;
          this.hereNowObj.endDateError = '';
          this.hereNowObj.formValid = false;
        } else {
          this.hereNowObj.endDateError = "";
          this.hereNowObj.endTimeError = "";
          this.hereNowObj.formValid = true;
        }
      }
    }
  }

  /**
   * @method: getTimeDifferenceInMinutes
   * @desc: method to get the time difference in minutes
   */
  getTimeDifferenceInMinutes() {
    if (!this.integratedEndDateTime) return;
    return this.helperService.getTimeDifferenceInMinutes(this.integratedEndDateTime, this.integratedStartDateTime);
  }

  /**
  * @method: validateDate
  * @desc: Method using for Validate the date format
  * @param: event, type
  */
  validateDate(event, type) {
    this[type].nativeElement.value = this.helperService.dateFormate(event.target.value);
  }

  /**
 * @method: checkDate
 * @desc: Method using for validation user entered date
 * @param: event, type
 */
  checkDate(event, type) {
    const date: any = new Date(event.target.value);
    const errorType = type + 'Error';
    if (event.target.value !== '') {
      if (date == '' || date == undefined || date == null || date == 'Invalid date' || date == 'Invalid Date') {
        this.hereNowObj[errorType] = MESSAGES.HERENOW_INVALID_DATE;
        this.hereNowObj.formValid = false;
        return;
      }
    } else {
      this.setDefaultValuesForNewHereNow(null);
      this.hereNowObj.formValid = true;
      this.hereNowObj[errorType] = '';
    }
    this.commonErrorHandlerForDateTime();
  }

  /**
   * @method: clearAddress
   * @desc: Method using for clear search objects when user clear the address input fields.
   * @param event
   */
  clearAddress(event) {
    let pc = document.getElementsByClassName('pac-container');
    if (pc && pc.length > 1) {
      pc[0].remove();
    }
    if (event.target.value === '') {
      this.addressObj.address = '';
      this.addressObj.longitude = this.addressObj.latitude = this.addressObj.state = null;
      this.MapUrl = '';
    }
  }

  /**
   * @method:  resetRadius
   * @desc: reset the value for radius field when unit changes
   */
  resetRadius() {
    this.f.radius.setValue('');
    this.resetRadiusValidation();
  }

  /**
   * Reset radius validation
   */
  resetRadiusValidation() {
    this.f.radius.clearValidators();
    this.f.radius.setErrors(null);
    this.hereNowForm.updateValueAndValidity();

    if(this.f.radius_unit.value == 'feet'){
      this.f.radius.setValidators([Validators.required, Validators.pattern('^[1-9][0-9]*$'), Validators.min(200)]);
    }
    else if(this.f.radius_unit.value == 'yards'){
      this.f.radius.setValidators([Validators.required, Validators.pattern('^[1-9][0-9]*$'), Validators.min(65)]);
    }
    else{
      this.f.radius.setValidators([Validators.required, Validators.pattern('^[1-9][0-9]*$')]);
    }
  }

  /**
   * @method: associateHereNow
   * @desc: associate HereNow with pushes or content
   */
  associateHereNow(parentId: number, hereNowId: number) {
    if (this.selectedHereNow && this.selectedHereNow.id && this.hereNowConf && (this.hereNowConf.parentStatus === "Draft" || this.hereNowConf.parentStatus === "Active") && this.selectedHereNow.status !== 'Active') {
      this.createOrUpdate(this.HereNowOutputData.herenow_form_fields);
      return;
    }
    this.helperService.showSpinner(true);
    this.coreService.post(ENDPOINTS.HERENOW_ASSOCIATE, {
      here_now_type: (this.hereNowConf.type === 'push') ? "notification" : "content",
      content: (this.hereNowConf.type === 'push') ? null : parentId,
      notification: (this.hereNowConf.type === 'push') ? parentId : null,
      herenow: hereNowId,
      content_creation: this.hereNowConf.contentCreation,
      parent_status: this.hereNowConf.parentStatus
    }).subscribe((res) => {
      if (res.body && res.body.message) this.toastrService.success(res.body.message);
      this.helperService.showSpinner(false);
      this.mutateResponse.next(true);
    }, (err) => {
      this.helperService.showSpinner(false);
    });
  }

  /**
   * @method: bindDataToForm
   * @desc: bind the data of herenow to the form fields when Admin selects the HereNow from use existing option
   */
  bindDataToForm() {
    const data = this.allHereNow.find((herenow) => {
      return herenow.id == this.f.existingHereNowId.value;
    });
    this.resetForm();
    this.setHereNowDataToFormToEdit(data, true);
    this.selectedHereNow = data;
    this.useExistingHereNow = true;
    this.enableDisableStartDateTime(true);
    if (data && data.status === 'Draft') {
      this.enableDisableStartDateTime(false);
      (data.duration_type === 'limited') ? this.enableDisableEndDateTime(false) : this.enableDisableEndDateTime(true);
      this.useExistingHereNow = false;
    }
  }

  /**
   * @method: changeHereNowType
   * @desc: check the herenow type and set the variables to make form read only
   */
  changeHereNowType() {
    this.resetForm();
    if (this.f.herenowType.value === 'createNew') {
      this.useExistingHereNow = false;
      this.f.existingHereNowId.setValue('');
      this.setValidationOverField('existingHereNowId', null)
      this.enableDisableStartDateTime(false);
      this.enableDisableEndDateTime(false);
      this.setDefaultValuesForNewHereNow('default');
      this.setValidationForOptionalField();
    } else {
      this.setValidationOverField('existingHereNowId', Validators.required);
      this.useExistingHereNow = true;
      this.enableDisableStartDateTime(true);
      this.enableDisableEndDateTime(true);
    }
    this.sendDataToParent();
  }

  /**
   * @method: resetForm
   * @desc: resset the values in the form
   */
  resetForm() {
    this.endDateTemp = this.endTimeTemp = this.startDateTemp = this.startTimeTemp = null;
    this.integratedEndDateTime = this.integratedStartDateTime = null;
    this.hereNowForm.patchValue({
      name: '',
      startDate: '',
      startTime: '',
      duration_type: 'indefinite',
      endDate: '',
      endTime: '',
      address: '',
      radius: '',
      radius_unit: 'feet',
      notify_type: 'once',
      interval: '',
      interval_unit: 'minute',
    });
  }

  /**
   * @method: setValidationOverField
   * @desc: method to set validations over a particular field
   * @param fieldName 
   * @param validations 
   */
  setValidationOverField(fieldName: string, validations: any) {
    this.f[fieldName].setValidators(validations);
    this.f[fieldName].updateValueAndValidity();
  }
}
