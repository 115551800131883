import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-metrics-popup',
  templateUrl: './metrics-popup.component.html'
})

export class MetricsPopupComponent implements OnInit {
  public errorObj: any = {};
  @Input() public popUpDataArray:any =[];
  @ViewChild('dashboardPopUp', { static: false }) dashboardPopUp: ElementRef;

  constructor() {}

  ngOnInit() {}
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (event.target === this.dashboardPopUp.nativeElement) {
      this.closePopup();
    }
  }
  ngDoCheck() {
    if(this.popUpDataArray.showPopup) {
      jQuery(this.dashboardPopUp.nativeElement).modal('show');
    }
  }

  /**
   * @method: closePopup
   * @desc: Close pop up
   */
  closePopup() {
    this.popUpDataArray.showPopup = false;
    jQuery(this.dashboardPopUp.nativeElement).modal('hide');
  }
}