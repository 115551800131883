import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-people-view',
  templateUrl: './people-view.component.html',
  styleUrls: ['./people-view.component.css']
})
export class PeopleViewComponent implements OnInit {
  public modalRef: BsModalRef;
  public content: any;
  constructor(private modalService: BsModalService, private bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.content = this.modalService.config.initialState['content'];
  }

  /**
   * @method: closeNow
   * @desc: Method using for proceed for cancel info.
   */
  closeNow() {
    this.bsModalRef.hide();
  }
}
