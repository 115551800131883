import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

//rxJS
import { catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';

// Shared Services
import { HelperService } from '../shared/services/helper.service';

import { HttpParams } from '@angular/common/http';
import { MESSAGES } from '../shared/constants/app.messages.constants';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private helperService: HelperService,
        private toastrService: ToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let tokenDetails, newParams, skipErrorToaster;
        tokenDetails = this.helperService.getToken();
        newParams = new HttpParams({ fromString: request.params.toString() });
        if (request.method.toLowerCase() === 'post') {
            skipErrorToaster = request.body['skip_error_toaster'];
            delete request.body['skip_error_toaster']
        } else {
            skipErrorToaster = newParams.get('skip_error_toaster') || false;
            newParams = newParams.delete('skip_error_toaster');
        }

        if (tokenDetails) {
            if (skipErrorToaster) {
                request = request.clone({
                    params: newParams,
                    setHeaders: {
                        Authorization: `Token ${tokenDetails}`
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Token ${tokenDetails}`
                    }
                });
            }
        }

        // send the newly created request
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                const errMsg = err.error.detail;
                localStorage.clear();
                this.helperService.showSpinner(false);
                this.helperService.hideAllModals();
                this.router.navigate(['login']);
                return throwError(errMsg);
            } else if(err.status === 0 && err.statusText === 'Unknown Error') {
                this.helperService.showSpinner(false);
                const errorObj = { body : {data : { error : MESSAGES.INTERNAL_SERVER_ERR } } }
                return throwError(errorObj);
            } else {
                const errMsg = err.error.message || err.error.detail || err.error.error;
                if (errMsg && skipErrorToaster !== undefined && !skipErrorToaster) this.toastrService.error(errMsg);
                this.helperService.showSpinner(false);
            }
            this.helperService.showSpinner(false);
            return throwError(err);
        }));
    }
}